import React, { useState } from "react";
import "../../components/footer/footer.css";
import TC from "../termsConditions/TermsConditions";

const Footer = () => {
  const [tcModal, setTCModal] = useState(false);

  const handleClose = () => {
    setTCModal(false);
  };

  return (
    <>
      <div className="Commonfooter">
        <div>
          <p>©Copyright 2024. All Rights Reserved</p>
        </div>
        <div className="Commonfooter_texts">
          <p onClick={() => setTCModal(true)}>Terms and Conditions</p>
          <p onClick={() => setTCModal(true)}>Privacy Policy</p>
        </div>
      </div>

      <TC tcModal={tcModal} setTCModal={setTCModal} handleClose={handleClose} />
    </>
  );
};

export default Footer;
