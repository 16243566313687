import React, { useState } from "react";
import "./footer.css";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage, formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.css";
import TermsConditions from "../termsConditions/TermsConditions";
import { useNavigate } from "react-router-dom";
import GreenLogo from "../../assets/footer/green.svg";
import YellowLogo from "../../assets/footer/yellow.svg";
import OrangeLogo from "../../assets/footer/orange.svg";
import TealLogo from "../../assets/footer/teal.svg";
import PinkLogo from "../../assets/footer/pink.svg";
import RedLogo from "../../assets/footer/red.svg";
import { dEV_API_URL } from "../../API";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";

function Footer() {
  const [postStatus, setPostStatus] = useState(false);
  const [tcModal, setTCModal] = useState(false);
  const [ppModal, setPPModal] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, "Name must be less than 100 characters")
      .required("Required"),
    message: Yup.string()
      .required("Required")
      .max(1000, "Message must be less than 1000 characters"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Contact Number must be a 10-digit number")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid email"
      )
      .max(50, "Email exceeds 50 characters")
      .required("Required"),
  });

  const handleClose = () => {
    setTCModal(false);
  };

  const handleClose2 = () => {
    setPPModal(false);
  };

  return (
    <>
      <div className="mainFooterContainer">
        <div className="footerContainer">
          <div>
            <div className="footerText">Contact Us</div>
            <div className="footerSubText">
              Reach Out to Us: Questions, Feedback, and Partnership
              Opportunities Await{" "}
            </div>
            <div className="footerSubText">– Let's Stay Connected!</div>
          </div>
        </div>

        <div className="formSection">
          <div className="formSection_left">
            <p>Elevate Your Photo</p>
            <p>Recognition Experience</p>
            <p style={{ display: "flex" }}>
              {" "}
              with
              <section class="animation">
                <div>
                  <div>
                    {" "}
                    <img src={GreenLogo} alt="logo" />
                  </div>{" "}
                </div>
                <div>
                  <div>
                    {" "}
                    <img src={YellowLogo} alt="logo" />
                  </div>{" "}
                </div>
                <div>
                  <div>
                    {" "}
                    <img src={OrangeLogo} alt="logo" />
                  </div>{" "}
                </div>
                <div>
                  <div>
                    {" "}
                    <img src={TealLogo} alt="logo" />
                  </div>{" "}
                </div>
                <div>
                  <div>
                    {" "}
                    <img src={PinkLogo} alt="logo" />
                  </div>{" "}
                </div>
                <div>
                  <div>
                    {" "}
                    <img src={RedLogo} alt="logo" />
                  </div>{" "}
                </div>
                <div>
                  <div>
                    {" "}
                    <img src={GreenLogo} alt="logo" />
                  </div>{" "}
                </div>
              </section>
            </p>
          </div>
          <div className="form_wrapper">
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                setPostStatus(true);
                axios({
                  method: "post",
                  url: `${dEV_API_URL}/homepage/sales-query/`,
                  headers: {
                    "content-type": "application/json",
                  },
                  data: {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    message: values.message,
                  },
                })
                  .then((res) => {
                    setPostStatus(false);
                    resetForm();
                    toast.success("We will contact you soon", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    });
                  })
                  .catch((err) => {
                    setPostStatus(false);
                    toast.warn("Something went wrong!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    });
                    return;
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm,
                setErrors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form">
                    <div>
                      <label>Full Name *</label>
                      <Field
                        autocomplete="off"
                        type="text"
                        name="name"
                        value={values.name}
                        maxLength={100}
                        className={`${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div>
                      <label>Email address *</label>
                      <Field
                        autocomplete="off"
                        maxLength={50}
                        type="email"
                        name="email"
                        value={values.email}
                        className={`${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div>
                      <label>Phone Number *</label>
                      <Field
                        autocomplete="off"
                        type="text"
                        name="phone"
                        value={values.phone}
                        maxLength={100}
                        className={`${
                          touched.phone && errors.phone ? "is-invalid" : ""
                        }`}
                        onInput={(e) => {
                          const inputValue = e.target.value;
                          const maxLength = 10;

                          // Remove non-numeric characters
                          const numericValue = inputValue.replace(/\D/g, "");

                          // Ensure the length is not more than 10 digits
                          if (numericValue.length > maxLength) {
                            e.target.value = numericValue.slice(0, maxLength);
                          } else {
                            e.target.value = numericValue;
                          }
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone"
                        className="invalid-feedback"
                      />
                    </div>

                    <div>
                      <label>Message *</label>
                      <Field
                        autocomplete="off"
                        component="textarea"
                        rows="5"
                        name="message"
                        value={values.message}
                        maxLength={1000}
                        className={`${
                          touched.message && errors.message ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="message"
                        className="invalid-feedback"
                      />
                    </div>
                    {postStatus ? (
                      <div className="sendDiv">
                        <button>Sending</button>
                      </div>
                    ) : (
                      <div className="sendDiv">
                        <button style={{ cursor: "pointer" }} type="submit">
                          Send
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="footer">
          <div>
            <p>©Copyright 2024. All Rights Reserved</p>
          </div>
          <div className="footer_texts">
          <p onClick={() =>{
                      navigate(`/about-us`);

          }}
             >About Us</p>
            <p onClick={() => setTCModal(true)}>Terms and Conditions</p>
            <p onClick={() => setPPModal(true)}>Privacy Policy</p>
            {/* <p>Cancellation/Refund Policy</p> */}
          </div>
        </div>
      </div>

      <TermsConditions tcModal={tcModal} setTCModal={setTCModal} handleClose={handleClose} />
      <PrivacyPolicy ppModal={ppModal} setPPModal={setPPModal} handleClose2={handleClose2} />
    </>
  );
}
export default Footer;
