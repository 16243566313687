import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../SignUpComponents/userSingUp/signupContainer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import loginBackgorundImage from "../../../assets/loginScreen/loginscreen.jpeg";
import "../../loginScreen/frgtPasword/forgotPassword.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { sendNewPassword } from "../../loginScreen/loginSignupController";
import GreenLogo from "../../../assets/footer/green.svg";
import YellowLogo from "../../../assets/footer/yellow.svg";
import OrangeLogo from "../../../assets/footer/orange.svg";
import TealLogo from "../../../assets/footer/teal.svg";
import PinkLogo from "../../../assets/footer/pink.svg";
import RedLogo from "../../../assets/footer/red.svg";
import "animate.css/animate.css";

const NewPassword = (props) => {
  // Access the UUID from props.match.params
  let queryParams = new URLSearchParams(window.location.search);
  let customuuid = queryParams.get("uuid");
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for password visibility
  const navigate = useNavigate(); // Access navigate function from React Router

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const onSubmitNewPass = async (values) => {
    console.log("submit button is clicked");
    console.log("submit button is clicked values are=", values);
    await validationSchema.validate(values, { abortEarly: false });

    setIsSubmitting(true);
    const UpdateUserPassword = {
      uuid: customuuid,
      new_password: values.password,
      re_new_password: values.confirmPassword,
    };
    await sendNewPassword(UpdateUserPassword, { setIsSubmitting, navigate });
  };

  return (
    <div className="fploginContainer">
      <img
        className="fploginBackgroundImage"
        src={loginBackgorundImage}
        alt="Login Background Image"
      />

      <div className="fploginCenterContrainer">
        <div className="rightText">
          <p>Elevate Your Photo</p>
          <p>Recognition Experience</p>
          <p style={{ display: "flex" }}>
            {" "}
            with
            {/* <h1 className="text">SNAPD!!</h1> */}
            <section class="animation">
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={YellowLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={OrangeLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={TealLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={PinkLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={RedLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
            </section>
          </p>
        </div>
        <div className="forgotpasswordContainer">
          <p className="forgropassheading">Create New Password</p>
          <p className="subheading" style={{ fontSize: 16 }}>
            "Boost Security: Create a Strong and Easy New Password for Better
            Protection."
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitNewPass}
          >
            {({ touched, errors }) => (
              <Form className="signup-form">
                <div className="signUplabeledittextGroup">
                  <label className="signUPLabels">Enter Password</label>
                  <Field
                    className="signUptextFields"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    maxLength={15}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={() => setShowPassword(!showPassword)}
                    className="eyecontinaer"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="signUplabeledittextGroup">
                  <label className="signUPLabels">Confirm Password</label>
                  <Field
                    className="signUptextFields"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    maxLength={15}
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="eyecontinaer"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error-message"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="signupSubmitbutton"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {isSubmitting ? "Updating..." : "Update Password"}
                </button>
              </Form>
            )}
          </Formik>
        </div>{" "}
      </div>
    </div>
  );
};

export default NewPassword;
