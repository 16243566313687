import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: "event",
};

const sideBarReducer = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    ChangeSideBarBtn: (state, action) => {
      state.sidebar = action.payload;
    },
  },
});

export const { ChangeSideBarBtn } = sideBarReducer.actions;
export default sideBarReducer.reducer;
