import React from "react";
import "../Integration/integration.css";
import CopyLinkImg from "../../../assets/eventSetting/copyLink.svg";

const Integration = () => {

  function CopyText() {
    var copyText = window.location.href;
    navigator.clipboard.writeText(copyText);
    alert("copied")
  }

  return (
    <>
      <div className="integration_main_container">
        <div className="integration_sub_container">
          <div className="integration_header_div">
            <p>Integrate event access with your website</p>
            <p>
              Follow the instructions below to integrate event access with your
              website and drive traffic to your webpage.
            </p>
          </div>

          <div className="integration_iframe_div">
            <p className="integration_iframe_heading">iframe</p>
            <div className="integration_iframe_inner_div">
              <p className="integration_iframe_text">
                {`
                  <iframe
                    id="snapd"
                    src="https://snapd.ai/integrate/bEdmYnFmZ29HeXduQVI4a0JiaytyQT09"
                    style="border:none;width:100%;min-height:720px;overflow-y: hidden;overflow:hidden"
                    title="Get Your Photos"
                  ></iframe>`
                }
              </p>
            </div>
            <div className="integration_btn_div">
              <img src={CopyLinkImg} alt="copy_link" />
              <p onClick={CopyText}>Copy Code</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Integration;
