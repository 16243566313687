import React from "react";
import "./home.css";
import HomeHeader from "../../components/homepage/homeHeader"; 
import Text from "../../components/homepage/Text";
import CreateGallery from "../../components/homepage/createGallery";
// import ShowingFlow from "../../components/homepage/showingFlow";
import Exception from "../../components/homepage/exception";
import Moment from "../../components/homepage/Moment2";
// import Device from "../../components/homepage/device";
import Footer from "../../components/homepage/footer";
import Users from "../../components/homepage/users";
import ShowingCount from "../../components/homepage/showingCount";
import Gallery2 from "../../components/homepage/Gallery2";

const Home = () => {
  return (
    <div style={{overflow: "hidden"}}>
      <div className="topSection">
        <div className="bgStar"> 
          <HomeHeader />
          <Text />
        
        <Gallery2 />
        </div>
        {/* <ShowingFlow /> */}
        <CreateGallery />
        <ShowingCount />
        <Exception />
        <Moment />
        <Users />
        {/* <Device /> */}
        <Footer />
      </div>
    </div>
  );
};

export default Home;
