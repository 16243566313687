import React from "react";
import "./exception.css";
import Frame1 from "../../assets/homepage/frame/frame1.png";
import Frame2 from "../../assets/homepage/frame/frame2.png";
import Frame3 from "../../assets/homepage/frame/frame3.png";

function Exception() {
  return (
    <>
      <div className="exceptionContainer">
        <div>
          <div className="exceptionMain">Sharing Photos has never been easier than this!</div>
          <div className="exceptionSub">
          Use our offerings to elevate your event experience to unprecedented heights
          </div>
        </div>

        <div className="expection_cards">

          <div className="expection_card_wrapper">
            <div className="expection_card">
              <img src={Frame1} alt="branding" />
              <div className="expection_card_overlay"></div>
              <div className="expection_card_bottom_heading">
                Customize Branding
              </div>
              <div className="expection_card_info">
                <div className="expection_card_content">
                  <div className="expection_card_headingText">
                    Customize Branding
                  </div>  
                  <p className="expection_card_subtext">
                    Showcase your brand logo, tagline, prominently on every
                    photo, reinforcing your brand identity and increasing brand
                    recognition.
                  </p>
                </div>
              </div>
            </div>
            <div className="resp_expection_card_content">
              <div className="resp_expection_card_headingText">
                Customize Branding
              </div>
              <p className="resp_expection_card_subtext">
                Showcase your brand logo, tagline, prominently on every photo,
                reinforcing your brand identity and increasing brand
                recognition.
              </p>
            </div>
          </div>

          <div className="expection_card_wrapper">
            <div className="expection_card">
              <img src={Frame2} alt="branding" />
              <div className="expection_card_overlay"></div>
              <div className="expection_card_bottom_heading">
                Seamless Website Integration
              </div>
              <div className="expection_card_info">
                <div className="expection_card_content">
                  <div className="expection_card_headingText">
                    Seamless Website Integration
                  </div>
                  <p className="expection_card_subtext">
                    Streamline Your Website with One-Click SNAPD Integration.
                  </p>
                </div>
              </div>
            </div>
            <div className="resp_expection_card_content">
              <div className="resp_expection_card_headingText">
                Seamless Website Integration
              </div>
              <p className="resp_expection_card_subtext">
                Streamline Your Website with One-Click SNAPD Integration.
              </p>
            </div>
          </div>

          <div className="expection_card_wrapper">
            <div className="expection_card">
              <img src={Frame3} alt="branding" />
              <div className="expection_card_overlay"></div>
              <div className="expection_card_bottom_heading">
                99.3 % Accuracy
              </div>
              <div className="expection_card_info">
                <div className="expection_card_content">
                  <div className="expection_card_headingText">
                    99.3 % Accuracy
                  </div>
                  <p className="expection_card_subtext">
                    Discover Faces with 99.3% Precision: Unleash the Power of
                    Facial Search.
                  </p>
                </div>
              </div>
            </div>
            <div className="resp_expection_card_content">
              <div className="resp_expection_card_headingText">
                99.3 % Accuracy
              </div>
              <p className="resp_expection_card_subtext">
                Discover Faces with 99.3% Precision: Unleash the Power of Facial
                Search.
              </p>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default Exception;
