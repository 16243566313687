import { ToastContainer } from "react-toastify";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./forgotPassword.css";
import loginBackgorundImage from "../../../assets/loginScreen/loginscreen.jpeg";
import { sendForgtPasswordLink } from "../loginSignupController";
import { useNavigate } from "react-router-dom";
import GreenLogo from "../../../assets/footer/green.svg";
import YellowLogo from "../../../assets/footer/yellow.svg";
import OrangeLogo from "../../../assets/footer/orange.svg";
import TealLogo from "../../../assets/footer/teal.svg";
import PinkLogo from "../../../assets/footer/pink.svg";
import RedLogo from "../../../assets/footer/red.svg";
import "animate.css/animate.css";
const Forgotpassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate(); // Access navigate function from React Router
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });
  const resetPassowrd = async (values, { setSubmitting, setStatus }) => {
    console.log("submit button is clicked");
    console.log("submit button is clicked values are=", values);

    setIsSubmitting(true);
    const forgtpassUserData = {
      email: values.email,
    };
    await sendForgtPasswordLink(forgtpassUserData, { setIsSubmitting });
  };

  return (
    <div className="fploginContainer">
      <img
        className="fploginBackgroundImage"
        src={loginBackgorundImage}
        alt="Login Background Image"
      />
      <div className="fploginCenterContrainer">
        <div className="rightText">
          <p>Elevate Your Photo</p>
          <p>Recognition Experience</p>
          <p style={{ display: "flex" }}>
            {" "}
            with
            {/* <h1 className="text">SNAPD!!</h1> */}
            <section class="animation">
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={YellowLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={OrangeLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={TealLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={PinkLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={RedLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
            </section>
          </p>
        </div>
        <div className="forgotpasswordContainer">
          <div className="forgropassheading">
            <p>Reset Password</p>
          </div>
          <p className="subheading">
            Enter the email associated with your account and we’ll send you a
            link to reset your password
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={resetPassowrd}
          >
            {({ touched, errors }) => (
              <Form className="frgtPassword">
                <div className="firstLastName">
                  <div className="signUplabeledittextGroup">
                    <label className="signUPLabels">Enter Your Email ID</label>
                    <Field
                      className="signUptextFields"
                      type="text"
                      name="email"
                      maxLength={100}
                      placeholder="example.email@gmail.com"
                    />
                    {touched.email && (
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                        style={{ marginTop: "10px" }}
                        // Define inline styles as a JavaScript object
                      />
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="signupSubmitbutton"
                  onSubmit={resetPassowrd}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Send Link"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Forgotpassword;
