import React, { useEffect, useCallback, useState } from "react";
import "./eventMain.css";
import SideBar from "../../components/sidebar/SideBar";
import Header from "../../components/header/Header";
import EventLogo from "../../assets/events/events.svg";
import AlbumLogo from "../../assets/events/solidAlbum.svg";
import PhotosLogo from "../../assets/events/photos.svg";
import { Link } from "react-router-dom";
import Card from "./EventCard/Card";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventList, resetEventList } from "./EventListReducer";
import InfiniteScroll from "react-infinite-scroll-component";

import AddIcon from '@mui/icons-material/Add';
import SyncLoader from "react-spinners/SyncLoader";
import NoEvent from "../../assets/events/NoEvent.gif";
import { resetAlbumList } from "../Album/Albums/AlbumListReducer";

const Main = () => {
  const { respData, headerData, status, count, hasMore } = useSelector(
    (state) => state.eventList
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const resetData = async () => {
      if (isLoading === true) {
        await dispatch(resetEventList());
        await dispatch(resetAlbumList());
        setIsLoading(false);
      }
    };

    resetData();
  }, []);

  const override = {
    width: "100%",
    display: "flex",
    gap: "6px",
    alignItem: "center",
    justifyContent: "center",
    margin: "200px 0 0 0",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading === false) {
      fetchData();
    }
  }, [isLoading]);

  const fetchData = useCallback(() => {
    dispatch(fetchEventList({ pageNumber: respData.length / 10 + 1 }));
    setIsLoading(true);
  }, [dispatch, respData]);

  return (
    <>
      <Header />
      <div className="event_main_container">
        <SideBar />
        <div className="event_main_div">
          <div className="event_header">
            <div className="event_header_left">
              <p>My Events</p>
            </div>
            <div className="event_header_right">
              <Link
                style={{ textDecoration: "none" }}
                className="event_header_right_btn_div"
                to="/create-event"
              >
                <button>Create Event</button>
              </Link>
            </div>
          </div>
          <div className="event_subheader">

            <div className="event_subheader_card">
              <div className="event_subheader_card_left">
                <div className="event_subheader_card_left_img_div">
                  <img src={EventLogo} alt="event" />
                </div>
                <p>Events</p>
              </div>
              <div className="event_subheader_card_right">
                <p> {headerData.count} </p>
              </div>
            </div>

            <div className="event_subheader_card">
              <div className="event_subheader_card_left">
                <div className="event_subheader_card_left_img_div">
                  <img src={AlbumLogo} alt="albums" />
                </div>
                <p>Album</p>
              </div>
              <div className="event_subheader_card_right">
                <p>{headerData.count_of_albums_across_events}</p>
              </div>
            </div>
            <div className="event_subheader_card">
              <div className="event_subheader_card_left">
                <div className="event_subheader_card_left_img_div">
                  <img src={PhotosLogo} alt="photos" />
                </div>
                <p>Photos</p>
              </div>
              <div className="event_subheader_card_right">
                <p>{headerData.count_of_images_across_events}</p>
              </div>
            </div>
          </div>

          {respData.length === 0 && status === "loading" ? (
            <SyncLoader
              color="#E5DAF2"
              loading="...loading"
              cssOverride={override}
              size={6}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              {respData.length > 0 ? (
                <InfiniteScroll
                  dataLength={count}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={<p>Loading......</p>}
                  endMessage={
                    <p className="event_list_no_more">No more events.</p>
                  }
                >
                  <Card respData={respData} />
                </InfiniteScroll>
              ) : (
                <div className="no_event_found_div">
                  <img src={NoEvent} alt="img" />
                  <p>No Event Available</p>
                </div>
              )}
            </>
          )}

        </div>

        <Link
                style={{ textDecoration: "none" }}
                to="/create-event"
              >
        <div className="event_main_create_btn"><AddIcon className="event_main_create_icon" /> Create Event</div>
         </Link>
      </div>
    </>
  );
};

export default Main;
