import React, { useEffect, useState, useCallback } from "react";
import "../Albums/albums.css";
import SideBar from "../../../components/sidebar/SideBar";
import Header from "../../../components/header/Header";
import AlbumLogo from "../../../assets/events/solidAlbum.svg";
import PhotosLogo from "../../../assets/events/photos.svg";
import { Link } from "react-router-dom";
import AlbumCard from "./AlbumCard/AlbumCard";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
import NoEvent from "../../../assets/events/NoEvent.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { dEV_API_URL, TOKEN } from "../../../API";

const Albums = () => {
  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");
  let eventuuid = queryParams.get("uuid");

  const override = {
    width: "100%",
    display: "flex",
    gap: "6px",
    alignItem: "center",
    justifyContent: "center",
    margin: "200px 0 0 0",
  };

  const [respData, setRespData] = useState([]);
  const [headerData, setHeaderData] = useState({});
  const [status, setStatus] = useState("idle");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [newEventID, setNewEventID] = useState(null);

  const fetchData = useCallback(async () => {
    setStatus("loading");
    try {
      const response = await axios({
        method: "GET",
        url: `${dEV_API_URL}/events/albums-list/?event_uuid=${id}&page=${page}`,
        headers: {
          "content-type": "application/json",
          Authorization: TOKEN,
        },
      });

      const data = response.data;
      setRespData((prevData) => [...prevData, ...data.results]);
      setHeaderData(data);
      setNewEventID(data.event_id);
      setHasMore(data.next !== null);
      setPage((prevPage) => prevPage + 1);
      setStatus("succeeded");
    } catch (error) {
      setStatus("failed");
      console.error("Error fetching data:", error);
    }
  }, [id, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Header />
      <div className="album_main_container">
        <SideBar />
        <div className="album_main_div">
          <div className="album_header">
            <div className="album_header_left">
              <p>My Albums</p>
            </div>
            <div className="album_header_right">
              <Link
                style={{ textDecoration: "none" }}
                className="album_header_right_btn_div"
                to={`/create-album?eventId=${newEventID}&uuid=${id}`}
              >
                <button>Create Album</button>
              </Link>
            </div>
          </div>
          <div className="album_subheader">
            <div className="album_subheader_card">
              <div className="album_subheader_card_left">
                <div className="album_subheader_card_left_img_div">
                  <img src={AlbumLogo} alt="albums" />
                </div>
                <p>Album</p>
              </div>
              <div className="album_subheader_card_right">
                <p> {headerData.count} </p>
              </div>
            </div>
            <div className="album_subheader_card">
              <div className="album_subheader_card_left">
                <div className="album_subheader_card_left_img_div">
                  <img src={PhotosLogo} alt="photos" />
                </div>
                <p>Photos</p>
              </div>
              <div className="album_subheader_card_right">
                <p>{headerData.number_of_images_across_albums}</p>
              </div>
            </div>
          </div>
          {status === "loading" && respData.length === 0 ? (
            <SyncLoader
              color="#E5DAF2"
              loading="...loading"
              cssOverride={override}
              size={6}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              {respData.length > 0 ? (
                <InfiniteScroll
                  dataLength={respData.length}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={<p>Loading......</p>}
                  endMessage={<p className="album_list_no_more">No more albums.</p>}
                >
                  <AlbumCard respData={respData} eventId={id} eventuuid={eventuuid} />
                </InfiniteScroll>
              ) : (
                <div className="no_album_found_div">
                  <img src={NoEvent} alt="img" />
                  <p>No album Available</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Albums;
