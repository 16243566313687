// api.js

import axios from "axios";
import { dEV_API_URL } from "../API";
// Create an instance of Axios with custom configuration
const axiosAPIClient = axios.create({
  baseURL: dEV_API_URL,
  headers: {
    "content-type": "application/json",
    // Add any default headers here
  },
});

// Add a request interceptor to handle requests before they are sent
axiosAPIClient.interceptors.request.use(
  (config) => {
    // You can modify the request config here (e.g., add authentication headers)
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle responses
axiosAPIClient.interceptors.response.use(
  (response) => {
    // You can modify the response data here
    return response;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  }
);

export default axiosAPIClient;
