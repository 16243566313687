import someThingWentWrongimg from "../../assets/somethingwrong.png";
import "./404Page.css";

const SomeThingWentWrong = () => {
  return (
    <div className="swrgParent"> 
      <div className="swrgMaintContainer">
        <img
          className="swrgImage"
          src={someThingWentWrongimg}
          alt="Some thing went wrong"
        />
        <p className="swrgtitle">Error 404</p>
        <p className="swrgSubtitle">Oops! Page not found.</p>
      </div>
    </div>
  );
};

export default SomeThingWentWrong;
