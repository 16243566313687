import React, { useEffect, useState } from "react";
import './showingCount.css';
import axios from "axios";
import CountUp from "react-countup";
import { useInView } from 'react-intersection-observer';
import { dEV_API_URL } from "../../API";

function ShowingCount() {
    const [images, setImages] = useState(0);
    const [albums, setAlbums] = useState(0);
    const [scanned, setScanned] = useState(0);
    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            getCounts();
        }  
    }, [inView]);

    const getCounts = () => {
        axios({
            method: "GET",
            url: `${dEV_API_URL}/homepage/platform-statistics/`,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((resp) => {
                setImages(resp.data.total_images)
                setAlbums(resp.data.total_albums)
                setScanned(resp.data.faces_scanned);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div ref={ref} className="colorchangingMainWrapper">
            {inView && (
                <>
                <div className="colorchangingMaindiv" >
                    <div className="colorchangingdiv">
                        <h1><CountUp duration={10} end={images} /></h1>
                        <p>Photos</p>
                    </div>
                    <div className="colorchangingdiv">
                        <h1><CountUp duration={10} end={albums} /></h1>
                        <p>Gallery</p>
                    </div>
                    <div className="colorchangingdiv">
                        <h1><CountUp duration={10} end={scanned} /></h1>
                        <p>Faces scaned per day</p>
                    </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ShowingCount;
