
import React, { useEffect, useState } from "react";
import "./orgInfo.css";

import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import MobileImg from "../../../assets/eventSetting/mobile.png";
import { getToken, dEV_API_URL } from "../../../API";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import SyncLoader from "react-spinners/SyncLoader";

import NoImg from "../../../assets/NoImg.svg";
import ShareIcon from "@mui/icons-material/Share";

import { useDispatch, useSelector } from "react-redux";
import { ChangeEventSettingPage } from "../eventSettingReducer";

const TOKEN = await getToken();

const OrgInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [coverImg, setCoverImg] = useState(null);
  const [coverImgErr, setCoverImgErr] = useState(null);
  const [postStatus, setPoststatus] = useState(false);

  const [flag, setFlag] = useState(false);
  const [respData, setRespData] = useState([]);
  const [isCoverImgChange, setIsCoverImgChange] = useState(false);

  const [brandName, setBrandName] = useState("");
  const [subtitle, setSubtitle] = useState("");

  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");

  let validationSchema = Yup.object({
    brand_name: Yup.string()
      .min(3, "Brand name must be atleast 3 characters")
      .max(50, "Brand name must be at most 50 characters")
      .required("Brand name is required"),
    subtitle: Yup.string()
      .min(3, "Subtitle must be atleast 3 characters")
      .max(100, "Subtitle must be at most 100 characters")
      .required("Subtitle is required"),
  });

  useEffect(() => {
    // console.log(id);
    getOrgDetail(id);
  }, [id]);

  const getOrgDetail = (id) => {
    // setPoststatus(true);

    axios({
      method: "GET",
      url: `${dEV_API_URL}/branding/brand-landing-page/?event_id=${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
    })
      .then((resp) => {
        console.log(resp.data);
        setRespData(resp.data);
        setBrandName(resp.data.brand_name);
        setSubtitle(resp.data.brand_tagline);
        setCoverImg(resp.data.brand_logo);
        setFlag(true);
      })
      .catch((err) => {
        console.log(err);
        // setPoststatus(false);
        setFlag(true);
      })
      .finally(() => {
        // setPoststatus(false);
      });
  };

  const updateOrgDetail = (values) => {
    setPoststatus(true);

    var MethodType;

    if (respData.id) {
      MethodType = "PATCH";
    } else {
      MethodType = "POST";
    }

    const formData = new FormData();
    if (respData.id) {
      formData.append("landing_page_brand_id", respData.id);
    } else {
      formData.append("event_id", id);
    }
    if (isCoverImgChange && coverImg !== null) {
      formData.append("brand_logo", coverImg);
    }
    formData.append("brand_name", values.brand_name);
    formData.append("brand_tagline", values.subtitle);

    axios({
      method: MethodType,
      url: `${dEV_API_URL}/branding/brand-landing-page/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization: TOKEN,
      },
      data: formData,
    })
      .then((res) => {
        // console.log("res", res);

        toast.success("Organization Information Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // navigate("/events");
      })
      .catch((err) => {
        // console.log(err);
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setPoststatus(false);
      })
      .finally(async() => {
        setPoststatus(false);
        await dispatch(ChangeEventSettingPage(""));
        await dispatch(ChangeEventSettingPage("organizationInfo"));

      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      toast.warning("Invalid file type. Please select a PNG, JPG, or JPEG image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return false;
    }
    if (file.size > maxSize) {
      toast.warning("File size exceeds the 2MB limit. Please select a smaller file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return false;
    }
    return true;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      setCoverImg(file);
      setCoverImgErr(null);
    }
  };

  const removeImg = () => {
    setCoverImg(null);
  };


  return (
    <>
      <div className="org_info_main_container">
        <div className="org_info_header_div">
          <p>Configure URL Branding</p>
          <p>Please upload branding if you want to add branding on photos</p>
        </div>

        {flag ? (
          <Formik
            initialValues={{
              brand_name: flag ? respData.brand_name : "",
              subtitle: flag ? respData.brand_tagline : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setBrandName(values.brand_name);
              setSubtitle(values.subtitle);
              updateOrgDetail(values);
            }}
          >
            {({
              values,
              isValid,
              setFieldValue,
              errors,
              touched,
              resetForm,
              handleChange,
            }) => (
              <Form>
                <div className="org_info_basic_info_div">
                  <div className="org_info_basic_info_left">
                    <div className="org_info_basic_info_field_box_div">
                      <label htmlFor="brand_name">Brand Name*</label>
                      <Field
                        type="text"
                        id="brand_name"
                        name="brand_name"
                        className="org_info_basic_info_field_box"
                        placeholder="SNAPD"
                        onChange={(e) => {
                          handleChange(e);
                          setBrandName(e.target.value);
                        }}
                        maxLength={50}
                      />
                      <ErrorMessage
                        name="brand_name"
                        component="div"
                        className="org_info_basic_info_error"
                      />
                    </div>

                    <div className="org_info_basic_info_field_box_div">
                      <label htmlFor="subtitle">Subtitle / Tagline*</label>
                      <Field
                        type="text"
                        id="subtitle"
                        name="subtitle"
                        className="org_info_basic_info_field_box"  
                        placeholder="Enter your subtitle/tagline"
                        onChange={(e) => {
                          handleChange(e);
                          setSubtitle(e.target.value);
                        }}
                        maxLength={100}
                      />
                      <ErrorMessage
                        name="subtitle"
                        component="div"
                        className="org_info_basic_info_error"
                      />
                    </div>

                    <>
                      <div
                        className="org_info_logo_upload_box"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <p className="org_info_logo_custom_file_upload_label">
                          Upload Logo Image
                        </p>
                        {coverImg == null ? (
                          <>
                            <label
                              htmlFor="coverimg"
                              className="org_info_logo_custom_file_upload"
                            >
                              <Field
                                type="file"
                                id="coverimg"
                                name="coverimg"
                                className="org_info_logo_upload_input"
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: "none" }}
                                value={values.file}
                                onChange={(e) => {
                                  if (
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/jpg"
                                  ) {
                                    const file = e.target.files[0];
                                    handleImageUpload(file);
                                    setIsCoverImgChange(true);
                                  }
                                }}
                              />
                              <p className="org_info_logo_upload_box_p1">
                                <span>Choose</span> file to upload
                              </p>
                              <p className="org_info_logo_upload_box_p2">or</p>
                              <p className="org_info_logo_upload_box_p3">
                                Drag & drop image to upload
                              </p>
                              <p className="upload_box_p4">
                                Recommended: 269x186px | jpeg, jpg, png | max.
                                size: 2MB
                              </p>
                            </label>

                            {/* <p className="org_info_basic_info_error">{coverImgErr}</p> */}
                          </>
                        ) : (
                          <div className="org_info_photos_cover_img_div">
                            {/* <img
                            src={URL.createObjectURL(coverImg)}
                            alt="cover_image"
                          /> */}

                            {typeof coverImg === "string" ? (
                              <img src={coverImg} alt="coverImg" />
                            ) : (
                              <img
                                src={URL.createObjectURL(coverImg)}
                                alt="cover_image"
                              />
                            )}

                            <div className="org_info_photos_trash_div">
                              <div
                                onClick={removeImg}
                                className="org_info_photos_trash_inner_div"
                              >
                                <FaTrashAlt color="white" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  </div>

                  <div className="org_info_basic_info_right">
                    <img src={MobileImg} alt="mobile_pic" />

                    {(coverImg || brandName || subtitle) && (
                      <div className="org_info_basic_info_preview_div">
                        <div>
                          {coverImg &&
                            (typeof coverImg === "string" ? (
                              <div className="org_info_basic_info_preview_header">
                                <img src={coverImg} alt="logo" />
                              </div>
                            ) : (
                              <div className="org_info_basic_info_preview_header">
                                <img
                                  src={URL.createObjectURL(coverImg)}
                                  alt="logo"
                                />
                              </div>
                            ))}

                          {(brandName || subtitle) && (
                            <div className="org_info_basic_info_preview_brand_des_div">
                              <p>{brandName}</p>
                              <p> {subtitle} </p>
                            </div>
                          )}

                          {(coverImg || brandName || subtitle) && (
                            <div className="org_info_basic_info_preview_event_div">
                              <p className="org_info_basic_info_preview_event_name">
                                Event Name
                              </p>
                              <div className="org_info_basic_info_preview_event_img_div">
                                <img src={NoImg} alt="logo" />
                              </div>
                              <p className="org_info_basic_info_preview_event_no_img">
                                No Image Available
                              </p>

                              <div className="org_info_basic_info_preview_event_share_div">
                                <div>
                                  {" "}
                                  <span>
                                    <ShareIcon />
                                  </span>{" "}
                                  Share Event{" "}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="org_info_form_btns">
                  {postStatus ? (
                    <p className="org_info_form_submit_btn">
                      <span>
                        <ClipLoader
                          color="white"
                          size={24}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </span>
                    </p>
                  ) : (
                    <>
                      <p
                        className="org_info_form_cancel_btn"
                        onClick={() => {
                          resetForm();
                          navigate(-1);
                        }}
                      >
                        <span>Cancel</span>
                      </p>
                      <button
                        className="org_info_form_submit_btn"
                        disabled={coverImgErr}
                        type="submit"
                      >
                        <span>Save</span>
                      </button>
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) :
        <div className="sync_loader">
        <SyncLoader
          color="#E5DAF2"
          loading="...loading"
          size={6}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
        }
      </div>
    </>
  );
};

export default OrgInfo;
