import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import loginBackgorundImage from "../../../assets/loginScreen/loginscreen.jpeg";
import "../letsGetStart/checkEmailAdd.css";
import { sendOtpToUserEmail } from "../../loginScreen/loginSignupController";
import VerifyOTP from "../verifyOtpComp/verifyOtp";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import index from "../../../router";
import { loginScreenPath } from "../../../router/constantRoutes";
import GreenLogo from "../../../assets/footer/green.svg";
import YellowLogo from "../../../assets/footer/yellow.svg";
import OrangeLogo from "../../../assets/footer/orange.svg";
import TealLogo from "../../../assets/footer/teal.svg";
import PinkLogo from "../../../assets/footer/pink.svg";
import RedLogo from "../../../assets/footer/red.svg";
import "animate.css/animate.css";
const CheckEmailAddress = () => {
  const [isCheckingEmailAddress, setIsCheckingEmailAddress] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState("");

  useEffect(() => {
    // Code to execute when isCheckingEmailAddress changes
    console.log("isCheckingEmailAddress has changed:", isCheckingEmailAddress);

    // Example: Execute a function when isCheckingEmailAddress changes
    if (isCheckingEmailAddress) {
      // Execute some function
      console.log("isCheckingEmailAddress is now true");
    } else {
      // Execute some other function
      console.log("isCheckingEmailAddress is now false");
    }
  }, [isCheckingEmailAddress]);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const handleSubmit = async (values) => {
    console.log("checkEmailId ExistorNot");
    setUpdatedEmail(values.email);
    const enterdEmail = {
      email: values.email,
    };
    await sendOtpToUserEmail(enterdEmail, {
      setIsCheckingEmailAddress,
      setIsOtpSent,
    });
  };
  const navigate = useNavigate(); // Access navigate function from React Router

  const goingToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="loginContainer">
      <img
        className="loginBackgroundImage"
        src={loginBackgorundImage}
        alt="Login Background Image"
      />

      <div className="loginCenterContrainer">
        <div className="rightText">
          <p>Elevate Your Photo</p>
          <p>Recognition Experience</p>
          <p style={{ display: "flex" }}>
            {" "}
            with
            {/* <h1 className="text">SNAPD!!</h1> */}
            <section class="animation">
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={YellowLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={OrangeLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={TealLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={PinkLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={RedLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
            </section>
          </p>
        </div>
        {!isOtpSent ? (
          <div className="forgotpasswordContainer">
            <div className="forgropassheading">
              <p>Let's get Started</p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ touched, errors }) => (
                <Form className="frgtPassword">
                  <div className="firstLastName">
                    <div className="signUplabeledittextGroup">
                      <label
                        className="signUPLabels"
                        style={{ marginTop: "10px" }}
                      >
                        Enter Your Email ID
                      </label>
                      <Field
                        className="signUptextFields"
                        type="text"
                        name="email"
                        placeholder="example.email@gmail.com"
                        maxLength={150}
                      />

                      {touched.email && (
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                          style={{ marginTop: "10px" }}
                          // Define inline styles as a JavaScript object
                        />
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isCheckingEmailAddress}
                    className="signupSubmitbutton"
                    onSubmit={handleSubmit}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {isCheckingEmailAddress ? (
                      <div className="loader"></div> // Place your loader component or icon here
                    ) : (
                      "Continue"
                    )}
                  </button>
                  <div className="noAccoutnSignUp">
                    <span className="loginLables">
                      Already have an account?
                    </span>
                    <span
                      className="forgotpassword"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={goingToLogin}
                    >
                      {" "}
                      Login
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <VerifyOTP email={updatedEmail} />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default CheckEmailAddress;
