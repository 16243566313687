import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { dEV_API_URL } from '../../API';

export const fetchImages = createAsyncThunk(
    'photos/fetchImages',
    async ({ albumuuid, pageNumber }) => {
      console.log(albumuuid, pageNumber);
      const response = await axios.get(`${dEV_API_URL}/events/images-list/?album_uuid=${albumuuid}&page=${pageNumber}&page_size=20`);
      return response.data;
    }
);

const initialState = {
    imageUrls: [],
    status: 'idle',
    error: null,
    page: 1,
    count: 0,
    hasMore: true,
    albumName:null,
    eventName:null,
    albumId:null,
};

const photoReducer = createSlice({
    name: 'photos',
    initialState,
    reducers: {
        resetImages: (state) => {
            state.imageUrls = [];
            state.count= 0;
            state.page = 1;
            state.hasMore = true;
            state.albumName = null;
            state.eventName = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchImages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.imageUrls = [...state.imageUrls, ...action.payload.results];
                state.page += 1;
                state.count = action.payload.count;
                state.hasMore = action.payload.next !== null; 
                state.albumName=action.payload.album_name;
                state.eventName=action.payload.event_name;
                state.albumId=action.payload.album_id;
            })
            .addCase(fetchImages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { resetImages } = photoReducer.actions;

export default photoReducer.reducer;
