import React, { useState } from "react";
import axios from "axios";
import "./downloadall.css";
import cong from "../../assets/cong.gif";
import TC from "../../components/termsConditions/TermsConditions";
import { dEV_API_URL } from "../../API";

const DownloadAll = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [tcModal, setTCModal] = useState(false);

  let queryParams = new URLSearchParams(window.location.search);
  let searchedFaceId = queryParams.get("search_id");
  let eventName = queryParams.get("eventName");

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (!event.target.value.trim()) {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!event.target.value.trim()) {
      setEmailError("Email is required");
    } else if (!emailRegex.test(event.target.value.trim())) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name.trim()) {
      setNameError("Name is required");
    }
    if (!email.trim()) {
      setEmailError("Email is required");
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setEmailError("Invalid email format");
    }
    if (!termsChecked) {
      setSubmitError("Please read and accept the Terms & Conditions");
    }
    if (name.trim() && emailRegex.test(email.trim()) && termsChecked) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${dEV_API_URL}/recognition/trigger-download-all-image-request/`,
          {
            user_email: email,
            searched_face_id: searchedFaceId,
            full_name: name,
          }
        );
        console.log("Request sent successfully");
        if (response.status === 202 || response.status === 200) {
          setShowCongratulations(true);
        }
        setName("");
        setEmail("");
        setTermsChecked(false);
        setSubmitError("");
      } catch (error) {
        console.error("There was a problem with the Axios request:", error);
        setSubmitError(
          "There was a problem submitting your request. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleClose = () => {
    setTCModal(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="OuterDownloadContainer">
        <div className="innerDownloadContainer">
          <div className="MaindownloadHeader">
            <div className="subdownloadHeader">
            <div className="subHeadingDownload">
            {eventName}

            </div>
            </div>
          </div>
          <div className="inner_main_container">
            {showCongratulations ? (
              <div className="congratulations_container">
                <div className="congratulations_gif">
                  <img src={cong} alt="Congratulations GIF" />
                </div>
                <div className="congratulations">
                  The download link has been sent successfully! Please check
                  your inbox. It will remain valid for 7 days.
                </div>
              </div>
            ) : (
              <>
                <div className="download_heading">Download my photos</div>
                <div className="download_sub_heading">
                  Please provide your email address so we can send you the
                  download link.
                </div>
                <div className="download_field_box_div">
                  <label>Name</label>
                  <input
                    type="text"
                    value={name}
                    className="download_field_box"
                    onChange={handleNameChange}
                  />
                  {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                </div>
                <div className="download_field_box_div">
                  <label>Email</label>
                  <input
                    type="email"
                    className="download_field_box"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                </div>
                <div className="termsAndCodition">
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={termsChecked}
                    onChange={handleTermsChange}
                  />
                  <label  className="labelText">
                    <p htmlFor="termsCheckbox">I have read{" "}</p>
                    <p  onClick={() => setTCModal(true)} className="linkText termsConditionsText">
                      Terms & Conditions
                    </p>
                  </label>
                </div>
                {submitError && <p style={{ color: "red" }}>{submitError}</p>}
                <button
                  className="submit_download_button"
                  type="submit"
                  disabled={
                    nameError || emailError || isLoading || !termsChecked
                  }
                >
                  {isLoading ? "Submitting..." : "Send link"}
                </button>
                <TC tcModal={tcModal} setTCModal={setTCModal} handleClose={handleClose} />
              </>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default DownloadAll;
