import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  sendOTP,
  resendOtpToUserEmail,
} from "../../loginScreen/loginSignupController";
import { useLocation } from "react-router-dom";
import "../../loginScreen/frgtPasword/forgotPassword.css";
import { Button } from "@mui/base";
import GreenLogo from "../../../assets/footer/green.svg";
import YellowLogo from "../../../assets/footer/yellow.svg";
import OrangeLogo from "../../../assets/footer/orange.svg";
import TealLogo from "../../../assets/footer/teal.svg";
import PinkLogo from "../../../assets/footer/pink.svg";
import RedLogo from "../../../assets/footer/red.svg";
import "animate.css/animate.css";

const VerifyOTP = ({ email }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userEmail = email; // Access data from state
  const navigate = useNavigate(); // Access navigate function from React Router

  const initialValues = {
    otp: "",
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP can't be empty"),
  });

  const onOTPSubmit = async (values) => {
    console.log("email id is= ", { userEmail });
    console.log("submit otp is clicked");
    console.log("submit otp is clicked values are=", values);
    setIsSubmitting(true);
    const VerifyOtpData = {
      email: userEmail,
      otp: values.otp,
    };
    await sendOTP(VerifyOtpData, { setIsSubmitting, navigate });
  };

  const resendOTP = async () => {
    console.log("checkEmailId ExistorNot");
    const enterdEmail = {
      email: userEmail,
    };
    await resendOtpToUserEmail(enterdEmail);
  };
  return (
    <div className="forgotpasswordContainer">
      <div className="forgropassheading">
        <p>Verify</p>
      </div>
      <p className="subheading">
        The OTP has been successfully sent to your email id {userEmail} . Please
        check your inbox.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onOTPSubmit}
      >
        {({ touched, errors }) => (
          <Form className="frgtPassword">
            <div className="firstLastName">
              <div className="signUplabeledittextGroup">
                <label className="signUPLabels">OTP</label>
                <Field className="signUptextFields" type="text" name="otp" />
              </div>
            </div>
            <Button
              className="forgotpassword"
              onClick={resendOTP}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                border: "none",
                float: "right",
                cursor: "pointer",
              }}
            >
              Resend OTP
            </Button>

            <button
              type="submit"
              disabled={isSubmitting}
              className="signupSubmitbutton"
              style={{ cursor: "pointer" }}
            >
              {isSubmitting ? "Verifying..." : "Verify"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyOTP;
