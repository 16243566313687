import React from "react";
import Select from "react-select";

const BrandCustomSelect = ({
  PositionList,
  selectedPosition,
  handleChange,
  convertToTitleCase
}) => {
  const options = PositionList.map((item) => ({
    value: item.id,
    label: item.value,
  }));
  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: "white",
      borderRadius: "6px",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1c1526",
      color: "#fff",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#1c1526",
      color: "white",
      border: "none",
      "&:hover": {
        backgroundColor: "#1c1526",
        color: "white",
      },
    }),
  };

  return (
    <div className="event_select_box_div">
      <Select
        value={options.find((option) => option.value === selectedPosition)}
        onChange={handleChange}
        options={options}
        classNamePrefix="brand_logo_select_box"
        placeholder="select"
        isSearchable={false}
        styles={customStyles}
      />
    </div>
  );
};

export default BrandCustomSelect;
