
import React, { useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import "../SearchPhoto/selfie.css";
import { IoClose } from "react-icons/io5";
import ShareIcon from "@mui/icons-material/Share";
import MobileHeader from "../../components/mobileHeader/MobileHeader";
import static_image1 from "../../assets/static_image1.png";
import static_image2 from "../../assets/static_image2.png";
import { ResetSearchedFaceId, postData } from "./selfieReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { ChangeSelfieError, ChangeSelfieResp } from "./selfieReducer";
import NoImg from "../../assets/NoImg.svg";

import CopyLink from "../../assets/share/copy.svg";
import Whatsapp from "../../assets/share/whatsapp.svg";
import Facebook from "../../assets/share/facebook.svg";
import Telegram from "../../assets/share/telegram.svg";
import Twitter from "../../assets/share/twitter.svg";
import CancelImg from "../../assets/events/cross.svg";
import captureSound from "../../assets/camera/cameraSound.mp3";
import Sound from "react-sound";
import { Link } from "react-router-dom";
import { dEV_API_URL } from "../../API";

const Selfie = () => {
  let queryParams = new URLSearchParams(window.location.search);
  let eventId = queryParams.get("eventId");
  let albumuuid = queryParams.get("albumId");
  let uuid;
  if (eventId) {
    uuid = eventId;
  } else if (albumuuid) {
    uuid = albumuuid;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchFaceID = useSelector(
    (state) => state.uploadselfie.searched_face_id
  );

  const { resp, loading, error } = useSelector((state) => state.uploadselfie);

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [inputImage, setInputImage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [selfieData, setSelfieData] = useState([]);
  const [bottomDrawer, setBottomDrawer] = useState(false);
  const [shareUrl, setShareUrl] = useState("");

  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [screenSize, setScreenSize] = useState(0);
  const [loaderSize, setLoaderSize] = useState();
  const [playCaptureSound, setPlayCaptureSound] = useState(false);
  const [viewAlbumID, setViewAlbumID] = useState(null);
  const [albumListLength, setAlbumListLength] = useState(null);
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenSize(window.innerWidth);

      const actualSize = () => {
        setScreenSize(window.innerWidth);
      };

      window.addEventListener("resize", actualSize);
      return () => {
        window.removeEventListener("resize", actualSize);
      };
    }
  }, [screenSize]);

  useEffect(() => {
    if (screenSize > 450) {
      setLoaderSize(60);
    } else {
      setLoaderSize(40);
    }
  }, [screenSize]);

  useEffect(() => {
    if (eventId) {
      var baseUrl = window.location.origin;
      setShareUrl(`${baseUrl}/search-photos/?eventId=${eventId}`);
    }
  }, [eventId]);

  useEffect(() => {
    if (albumuuid) {
      var baseUrl = window.location.origin;
      setShareUrl(`${baseUrl}/search-photos/?albumId=${albumuuid}`);
    }
  }, [albumuuid]);

  function CopyText() {
    navigator.clipboard.writeText(shareUrl);
    toast.success(" Link Copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  useEffect(() => {
    if (resp === "No matching faces found") {
      closeCamera();
      toast.warning("No matching faces found", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }, [resp]);

  useEffect(() => {
    if (error != null) {
      if (
        error.error ===
        "An error occurred (InvalidParameterException) when calling the SearchFacesByImage operation: There are no faces in the image. Should be at least 1."
      ) {
        closeCamera();
        dispatch(ChangeSelfieError());
        toast.warning("No face found in your image", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else  {
        closeCamera();
        dispatch(ChangeSelfieError());
        toast.warning(error.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }, [error]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const searchSelfie = () => {
    if (inputImage != null) {
      findPhotos(inputImage);
    }
  };

  useEffect(() => {
    if (selectedImage != null) {
      findPhotos(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (searchFaceID != null) {
      dispatch(ResetSearchedFaceId());
      navigate(`/your-photos?searched_face_id=${searchFaceID}`);
    }
  }, [searchFaceID]);

  function dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    setInputImage(new File([u8arr], "filename", { type: mime }));
    return new File([u8arr], "photo", { type: mime });
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dataURLtoFile(imageSrc);

    setPlayCaptureSound(true);

    setTimeout(() => {
      setImgSrc(imageSrc);
    }, 500);
  }, [webcamRef]);

  const closeCamera = () => {
    setImgSrc(null);
    setShowCamera(false);
  };

  const retake = () => {
    setImgSrc(null);
    setShowCamera(true);
  };

  const findPhotos = (img) => {
    dispatch(ChangeSelfieResp());
    dispatch(ChangeSelfieError());

    const formData = new FormData();
    formData.append("uuid_to_search", uuid);
    formData.append("image", img);
    dispatch(postData(formData));
  };

  useEffect(() => {
    // Make GET API call when the component mounts
    fetchSearchPageDetails();
  }, []);

  const fetchSearchPageDetails = async () => {
    try {
      const response = await fetch(
        `${dEV_API_URL}/events/search-page-details/?uuid=${uuid}`
      );
      if (response.ok) {
        const data = await response.json();
        setSelfieData(data);
        setAlbumListLength(data.album_set.length);
        setViewAlbumID(data.album_set[0].uuid);
      } else {
        throw new Error("Failed to fetch search page details");
      }
    } catch (error) {
      console.error("Error fetching search page details:", error);
    }
  };

  return (
    <>
      <div
        className="selife_wrapper"
        onClick={() => (bottomDrawer ? setBottomDrawer(false) : "")}
      >
        <div className="outerContainer">
          {!loading ? (
            <>
              {showCamera ? (
                <div className="innerContainer">
                  <div className="seflie_main_container">
                    <>
                      {showCamera && (
                        <div className="seflie_sub_container">
                          <div className="seflie_head_container">
                            <div className="close_camera">
                              <p onClick={closeCamera}>
                                <IoClose fontSize={35} />
                              </p>
                            </div>
                          </div>

                          {imgSrc ? (
                            <div className="selfie_captured_div">
                              <img
                                className="selfie_captured_img"
                                src={imgSrc}
                                alt="webcam"
                                style={{ transform: "scaleX(-1)" }}
                              />
                            </div>
                          ) : (
                            <div className="selfie_camera_div">
                              <Webcam
                                // style={{ width: "100%", height: "100%" }}
                                ref={webcamRef}
                                screenshotFormat="image/png"
                                className="selfieCamera"
                              />
                            </div>
                          )}

                          {!imgSrc ? (
                            <div className="capture_btn_container">
                              <div
                                className="capture_btn_div"
                                onClick={capture}
                              >
                                <div className="click_btn"></div>
                              </div>
                            </div>
                          ) : (
                            <div className="retake_select_btns_div">
                              <button onClick={retake}>Retake</button>
                              <button onClick={searchSelfie}>Select</button>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              ) : (
                <>
                  <div className="innerContainer">
                    <MobileHeader
                      brandImage={
                        selfieData.landing_page_data &&
                        selfieData.landing_page_data.brand_logo
                      }
                    />
                    <div className="innerTopContainer">
                      {selfieData && selfieData.landing_page_data && (
                        <div className="brandNameAndSubHeading">
                          <div className="brandNameHeading">
                            {selfieData.landing_page_data &&
                              selfieData.landing_page_data.brand_name}
                          </div>

                          <div className="brandNameSubHeading">
                            {selfieData.landing_page_data &&
                              selfieData.landing_page_data.brand_tagline}
                          </div>
                        </div>
                      )}

                      <div className="event_container">
                        <div className="eventName">{selfieData.title}</div>
                        <div style={{ color: "white" }}>{searchFaceID}</div>
                        <div className="eventImageContainer">
                          {selfieData.cover_image ? (
                            <img
                              className="eventImage"
                              src={selfieData.cover_image}
                              alt="no_image_found"
                            />
                          ) : (
                            <div className="NoEventImage_div">
                              <img
                                className="NoEventImage"
                                src={NoImg}
                                alt="dummy_image"
                              />
                              <p className="NoEventImage_p">
                                No Image Available
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="shareEventButton">
                          <div
                            class="shareButton"
                            onClick={() => setBottomDrawer(true)}
                          >
                            <ShareIcon className="shareIcon" />
                            <span class="textShare">
                              Share {eventId ? "Event" : "Album"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="HeadingSubHeadingContainer">
                        <div className="Heading">
                          Tips for Taking Great Selfies
                        </div>
                        <div className="subHeading">
                          For best results, please click a photo clearly showing
                          your facial features.
                        </div>
                      </div>
                      <div className="staticImageContainer">
                        <img
                          src={static_image1}
                          alt="Image_1"
                          className="staticImage"
                        />
                        <img
                          src={static_image2}
                          alt="Image_2"
                          className="staticImage"
                        />
                      </div>
                    </div>

                    <div className="uploadClickButton">
                      {selfieData.can_user_view_all_images_event_wide &&
                      <Link style={{ textDecoration: "none" }} to={albumListLength === 1 ? `/view-photos?id=${viewAlbumID}` : eventId ? `/album-list/?eventId=${eventId}` : `/view-photos?id=${albumuuid}`}>
                        <div className="view_all_images_btn">
                          View all {eventId ? "event" : "album"} images
                        </div>
                      </Link> }
                      <div className="selfie_btns">
                        <div
                          className="uploadSelfie2"
                          onClick={() => fileInputRef.current.click()}
                        >
                          <input
                            type="file"
                            id="file-input"
                            accept=".jpg, .jpeg, .png"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                          />
                          <div>Upload selfie</div>
                        </div>
                        <div
                          className="uploadSelfie"
                          onClick={() => setShowCamera(true)}
                        >
                          Click selfie
                        </div>
                      </div>
                    </div>
                  </div> 
                </>
              )}{" "}
            </>
          ) : (
            <>
              <div className="selfie_loading_container">
                <div className="selfie_loading_main_div">
                  <div className="selfie_loader">
                    <ClipLoader
                      color="white"
                      size={loaderSize}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                  <p className="findingImage">Finding Images...</p>
                </div>
              </div>
            </>
          )}
        </div>
        {bottomDrawer && (
          <div className="selfie_link_share_drawer">
            <div className="selfie_link_share_drawer_inner">
              <div className="selfie_link_share_drawer_header">
                <p>Share to</p>
                <img src={CancelImg} alt="close" />
              </div>

              <div className="selfie_share_main_div">
                <div className="selfie_share_icon_first_row">
                  <div
                    className="selfie_share_card"
                    onClick={() => CopyText(albumuuid)}
                  >
                    <img
                      className="selfie_share_card_copy_img"
                      src={CopyLink}
                      alt="copy link"
                    />
                    <p>Copy</p>
                  </div>

                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                      shareUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="selfie_share_card">
                      <img src={Whatsapp} alt="Whatsapp" />
                      <p>Whatsapp</p>
                    </div>
                  </a>

                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      shareUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="selfie_share_card">
                      <img src={Facebook} alt="Facebook" />
                      <p>Facebook</p>
                    </div>
                  </a>

                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://t.me/share/url?url=${encodeURIComponent(
                      shareUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="selfie_share_card">
                      <img src={Telegram} alt="Telegram" />
                      <p>Telegram</p>
                    </div>
                  </a>

                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                      shareUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="selfie_share_card">
                      <img src={Twitter} alt="Twitter" />
                      <p>X</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {playCaptureSound && (
        <Sound
          url={captureSound}
          playStatus={Sound.status.PLAYING}
          onFinishedPlaying={() => setPlayCaptureSound(false)}
        />
      )}
    </>
  );
};

export default Selfie;
