import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./signupContainer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { color } from "@mui/system";
import { red } from "@mui/material/colors";
import loginBackgorundImage from "../../../assets/loginScreen/loginscreen.jpeg";
import "../../loginScreen/frgtPasword/forgotPassword.css";
import { signup } from "../../loginScreen/loginSignupController";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { loginScreenPath } from "../../../router/constantRoutes";
import GreenLogo from "../../../assets/footer/green.svg";
import YellowLogo from "../../../assets/footer/yellow.svg";
import OrangeLogo from "../../../assets/footer/orange.svg";
import TealLogo from "../../../assets/footer/teal.svg";
import PinkLogo from "../../../assets/footer/pink.svg";
import RedLogo from "../../../assets/footer/red.svg";
import "animate.css/animate.css";
import { useDispatch, useSelector } from "react-redux";

const SingnUpForm = (updateLoginScreen) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for password visibility
  const navigate = useNavigate(); // Access navigate function from React Router
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const location = useLocation();
  const customemail = location.state?.email;

  const dispatch = useDispatch();

  useEffect(() => {
    if (customemail == null || customemail.length == 0) {
      goingToLogin();
    }
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
    isEmail: false,
  };

  const goingToLogin = () => {
    navigate("/login");
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),

    // termsAccepted: Yup.boolean().oneOf(
    //   [true],
    //   "Terms and conditions must be accepted"
    // ),
  });

  const onSubmitUserDataForSignUp = async (
    values,
    { setSubmitting, setStatus }
  ) => {
    if (customemail == null || customemail.length == 0) {
      goingToLogin();
    }
    console.log("submit button is clicked");
    console.log("submit button is clicked values are=", values);
    await validationSchema.validate(values, { abortEarly: false });

    setIsSubmitting(true);
    const SignupUserData = {
      email: customemail,
      password: values.password,
      full_name: values.firstName + values.lastName,
    };    
    await signup(SignupUserData, { setIsSubmitting, navigate, dispatch });
  }; 

  return (
    <div className="fploginContainer">
      <img
        className="fploginBackgroundImage"
        src={loginBackgorundImage}
        alt="Login Background Image"
      />

      <div className="fploginCenterContrainer">
        <div className="rightText">
          <p>Elevate Your Photo</p>
          <p>Recognition Experience</p>
          <p style={{ display: "flex" }}>
            {" "}
            with
            {/* <h1 className="text">SNAPD!!</h1> */}
            <section class="animation">
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={YellowLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={OrangeLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={TealLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={PinkLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={RedLogo} alt="logo" />
                </div>{" "}
              </div>
              <div>
                <div>
                  {" "}
                  <img src={GreenLogo} alt="logo" />
                </div>{" "}
              </div>
            </section>
          </p>
        </div>
        <div className="singUpContianer">
          <p>Signup</p>
          <p style={{ fontSize: 16 }}>
            We're thrilled to have you on board. By joining Snapd !!, you've
            taken the first step toward a fantastic experience.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitUserDataForSignUp}
          >
            {({ touched, errors }) => (
              <Form className="signup-form">
                <div className="firstLastName">
                  <div className="signUplabeledittextGroup">
                    <label className="signUPLabels">First Name</label>
                    <Field
                      className="signUptextFields"
                      type="text"
                      id="firstName"
                      name="firstName"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="signUplabeledittextGroup">
                    <label className="signUPLabels">Last Name</label>
                    <Field
                      className="signUptextFields"
                      type="text"
                      id="lastName"
                      name="lastName"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="signUplabeledittextGroup">
                  <label className="signUPLabels">Email</label>
                  <Field
                    className="signUptextFields"
                    type="email"
                    id="email"
                    name="email"
                    placeholder={customemail}
                    readOnly
                    value={customemail}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="signUplabeledittextGroup">
                  <label className="signUPLabels">Password</label>
                  <Field
                    className="signUptextFields"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={() => setShowPassword(!showPassword)}
                    className="eyecontinaer"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="signUplabeledittextGroup">
                  <label className="signUPLabels">Confirm Password</label>
                  <Field
                    className="signUptextFields"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="eyecontinaer"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error-message"
                  />
                </div>
                {/* <div className="tcCheckbox">
                  <Field
                    className="checkboxfield"
                    type="checkbox"
                    id="termsAccepted"
                    name="termsAccepted"
                  />
                  <label htmlFor="termsAccepted">
                    <span className="text_wrap">I have read</span>{" "}
                    <span className="span">Terms &amp; Conditions</span>
                  </label>
                  <ErrorMessage
                    name="termsAccepted"
                    component="div"
                    className="error-message"
                  />
                </div> */}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="signupSubmitbutton"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Sign Up"}
                </button>
                <div className="alreadyAccountLogin">
                  <span className="signUPLabels">
                    Already have an account?{" "}
                  </span>
                  <span
                    className="login"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={goingToLogin}
                  >
                    {" "}
                    Login
                  </span>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer />
        </div>{" "}
      </div>
    </div>
  );
};

export default SingnUpForm;
