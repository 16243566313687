import React, { useState, useEffect } from "react";
import "../EventSetting/index.css";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import ArrowImg from "../../assets/eventSetting/arrow.svg";
import Basic from "./Basic/Basic";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEventSettingPage } from "./eventSettingReducer";
import OrgInfo from "./OrgInfo/OrgInfo";
import Integration from "./Integration/Integration";
import { Link } from "react-router-dom";
import axios from "axios";
import BrandLogo from "./brandLogo/BrandLogo";
import BrandWatermark from "./brandWatermark/BrandWatermark";
import Select from "react-select";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Index = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.eventSetting.eventSettingPage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const options = [
    { value: "basic", label: "Basic Details" },
    { value: "organizationInfo", label: "Organization Info" },
    // { value: "brandLogo", label: "Brand Logo" },
    // { value: "brandWatermark", label: "Brand Watermark" },
    { value: "integration", label: "Website Integration" },
  ];

  const handleSelectChange = (selectedOption) => {
    dispatch(ChangeEventSettingPage(selectedOption.value));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: "white",
      borderRadius: "6px",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1c1526",
      color: "#fff",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#1c1526",
      color: "white",
      border: "none",
      "&:hover": {
        backgroundColor: "#1c1526",
        color: "white",
      },
    }),
  };

  return (
    <>
      <Header />
      <div className="event_setting_main_container">
        <SideBar />
        <div className="event_setting_main_div">
          <div className="event_setting_header_div">
            <Link style={{ textDecoration: "none" }} to="/events">
              <div style={{ cursor: "pointer", paddingTop: "5px" }}>
                <img src={ArrowImg} alt="arrow" />
              </div>
            </Link>
            <p>Maruti Suzuki Arena Devils Circuit | Legends Cup 2023-24</p>
          </div>

          <div className="event_setting_subheader_div">
            {isMobile ? (
              <div className="event_setting_select_box_div">
                <Select
                  options={options}
                  onChange={handleSelectChange}
                  classNamePrefix="event_setting_select_box"
                  value={options.find((option) => option.value === state)}
                  styles={customStyles}
                  isSearchable={false}
                />
              </div>
            ) : (
              <div className="event_setting_subheader_inner_div">
                {options.map((option) => (
                  <p
                    key={option.value}
                    onClick={() =>
                      dispatch(ChangeEventSettingPage(option.value))
                    }
                    className={
                      state === option.value
                        ? "event_setting_subheader_active_btn"
                        : "event_setting_subheader_inactive_btn"
                    }
                  >
                    {option.label}
                  </p>
                ))}
              </div>
            )}
            <div className="event_setting_subheader_line"></div>
          </div>

          {state === "basic" && <Basic />}
          {state === "brandLogo" && <BrandLogo />}
          {state === "brandWatermark" && <BrandWatermark />}
          {state === "organizationInfo" && <OrgInfo />}
          {state === "integration" && <Integration />}
        </div>
      </div>
    </>
  );
};

export default Index;
