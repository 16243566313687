import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../header/Header.css";
import Logo from "../../assets/dashboardlogo.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";

import Drawer from "@mui/material/Drawer";

import Event from "../../assets/sidebar/Event.svg";
import { ChangeSideBarBtn } from "../sidebar/SidebarReducer";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [leftDrawer, setLeftDrawer] = useState(false);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.sidebar.sidebar);

  const toggleDrawer = (open) => (event) => {
    setLeftDrawer(open);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    const allCookies = Cookies.get();
    // Loop through all cookies and remove each one
    for (const cookie in allCookies) {
      Cookies.remove(cookie);
    }
    navigate("/");
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className="header_main_container">
        <div className="header_main_left">
          <MenuIcon
            className="header_menu_icon"
            onClick={(e) => {
              e.stopPropagation();
              toggleDrawer(true)(e);
            }}
          />
          <img src={Logo} alt="logo" onClick={handleLogoClick} />
        </div>
        <div className="header_main_right" onClick={toggleDropdown}>
          <AccountCircleIcon fontSize="180px" className="user_icon" />
          {isDropdownOpen && (
            <div className="dropdown_menu">
              {/* Your dropdown menu content */}
              <p onClick={handleLogout}>Logout</p>
            </div>
          )}
        </div>

        <Drawer anchor="left" open={leftDrawer} onClose={toggleDrawer(false)}>
          <div className="header_left_drawer_div">
            <div className="header_left_drawer_header_div">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
              <CloseIcon
                className="header_left_drawer_header_close_icon"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDrawer(false)(e);
                }}
              />
            </div>
            <ul>
              <li
                onClick={() => {
                  dispatch(ChangeSideBarBtn("event"));
                  navigate("/events");
                }}
                // className={
                // state === "event" ? "active_sidebar_btn" : "sidebar_btn"
                // }
              >
                <img src={Event} alt="Event" />
                <p>My Events</p>
              </li>
            </ul>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default Header;
