import suffle2 from '../../assets/homepage/suffle2.png';
import shuffle1 from '../../assets/homepage/shuffle1.png';
import shuffle3 from '../../assets/homepage/shuffle3.png';
import shuffle4 from '../../assets/homepage/shuffle4.png';
import shuffle5 from '../../assets/homepage/shuffle5.png';
import shuffle6 from '../../assets/homepage/contactUs.jpg';
export default [
    { css: shuffle6, height: 600 },
    { css: suffle2, height: 600 },
    { css: shuffle1, height: 600 },
    { css: shuffle3, height: 600 },
    { css: shuffle4, height: 600 },
    { css: shuffle5, height: 600 },
]