import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosAPIClient from "../../networkLayer/network.js";
import axios from "axios";
import { changePassword, dEV_API_URL } from "../../API/index.js";
import { authSignup } from "../../API/index.js";
import { verifyotp } from "../../API/index.js";
import { userlogin } from "../../API/index.js";
import { checkUserExistence } from "../../API/index.js";
import { verifyEmail } from "../../API/index.js";
import { forgotPassword } from "../../API/index.js";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import data from "../../components/homepage/data.js";
import { loginScreenPath } from "../../router/constantRoutes";
import { ChangeUserAuth } from "../../components/common/AuthReducer.js";
export const signup = async (userData, { setIsSubmitting, navigate, dispatch }) => {
  try {
    console.log("submit button is userdate =", userData);
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${authSignup}`,
      userData
    );
    console.log("signup response=", response);
    if (
      response.status == "201" &&
      response.data.access != null &&
      response.data.access != ""
    ) {
      const allCookies = Cookies.get();
      // Loop through all cookies and remove each one
      for (const cookie in allCookies) {
        Cookies.remove(cookie);
      }
      // Assuming you have received the token from your authentication API
      const token = await response.data.access;
      // Set the token in a cookie with an expiration date (e.g., 1 day)
      await Cookies.set("token", "Bearer " + token);
      await Cookies.set("isauthenticated");
      await dispatch(ChangeUserAuth(true));
      navigate("/events", { replace: true });
    } else {
      toast.error("Something went wrong");
    }
  } catch (error) {
    console.log("signup error:", error);
    console.log("signup error:", error.response.data);
    // Show error toast notification
    if (
      error.response.data != null &&
      error.response.data.password.length != 0
    ) {
      toast.error(error.response.data.password[0]);
      if (error.response.data.password.length > 1) {
        toast.error(error.response.data.password[1]);
      }
    }
    if (error.response.data.error != null) {
      toast.error(error.response.data.error);
    }
  } finally {
    setIsSubmitting(false);
  }
};

export const sendForgtPasswordLink = async (userEmail, { setIsSubmitting }) => {
  try {
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${forgotPassword}`,
      userEmail
    );
    console.log("signup response=", response);
    if (response.status == "200") {
      toast.success("Forgot Password Link sent successfully to your email");
    }
  } catch (error) {
    console.log("signup error:", error);
    // Show error toast notification
    if (error.response.data.error != null) {
      toast.error(error.response.data.error);
    }
  } finally {
    setIsSubmitting(false);
  }
};

export const userLogin = async (
  userLoginData,
  { setIsSubmitting, navigate, dispatch }
) => {
  try {
    console.log("userLogin userdata =", userLoginData);
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${userlogin}`,
      userLoginData
    );
    console.log("userLogin response=", response);
    if (
      response.status == "200" &&
      response.data.access != null &&
      response.data.access != ""
    ) {
      const allCookies = Cookies.get();
      // Loop through all cookies and remove each one
      for (const cookie in allCookies) {
        Cookies.remove(cookie);
      }
      // Assuming you have received the token from your authentication API
      const token = await response.data.access;
      // Set the token in a cookie with an expiration date (e.g., 1 day)
      await Cookies.set("token", "Bearer " + token);
      await Cookies.set("isauthenticated", true);
      await dispatch(ChangeUserAuth(true));
      navigate("/events", { replace: true });
    } else {
      if (response.status == "401") {
        toast.error("You have entered an invalid username or password");
      }
    }
    return response.data;
  } catch (error) {
    console.log("signup error:", error);
    // Show error toast notification
    if (error.response.data.error != null) {
      toast.error(error.response.data.error);
    }
  } finally {
    setIsSubmitting(false);
  }
};

export const sendOtpToUserEmail = async (
  userEneterdEmail,
  { setIsCheckingEmailAddress, setIsOtpSent }
) => {
  try {
    setIsCheckingEmailAddress(true);
    console.log("userEneterdEmail =", userEneterdEmail);
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${verifyEmail}`,
      userEneterdEmail
    );

    console.log("userEneterdEmail response=", response);
    if (response.status == "200") {
      if (response.data != null) {
        toast.success(response.data.msg);
        setIsOtpSent(true);
      } else {
        toast.error("Somethig Went Error");
      }
    }
  } catch (error) {
    // Show error toast notification
    if (error.response.data.error != null) {
      console.log("signup error:", error.response.data.error);
      toast.error(error.response.data.error);
    }
  } finally {
    setIsCheckingEmailAddress(false);
  }
};

export const resendOtpToUserEmail = async (userEneterdEmail) => {
  try {
    console.log("userEneterdEmail =", userEneterdEmail);
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${verifyEmail}`,
      userEneterdEmail
    );

    console.log("userEneterdEmail response=", response);
    if (response.status == "200") {
      if (response.data != null) {
        toast.success(response.data.msg);
      } else {
        toast.error("Somethig Went Error");
      }
    }
  } catch (error) {
    console.log("signup error:", error);
    // Show error toast notification
    if (error.response.data.error != null) {
      toast.error(error.response.data.error);
    }
  } finally {
  }
};

export const sendNewPassword = async (
  updateNewPassword,
  { setIsSubmitting, navigate }
) => {
  try {
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${changePassword}`,
      updateNewPassword
    );

    if (response.status == "201") {
      if (response.data != null) {
        toast.success("Password Updated Successfully");
        navigate("/loginScreen");
      } else {
        toast.error("Somethig Went Error");
      }
    }
  } catch (error) {
    console.log("signup error:", error);
    // Show error toast notification
    if (error.response.data.error != null) {
      toast.error(error.response.data.error);
    }
  } finally {
    setIsSubmitting(false);
  }
};
export const verifyEmailForSendingOTP = async (
  userLoginData,
  { setIsSubmitting, navigate }
) => {
  try {
    console.log("userLogin userdata =", userLoginData);
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${verifyEmail}`,
      userLoginData
    );
    console.log("userLogin response=", response);
    if (response.status == "201") {
      navigate("/create-event");
    }
    return response.data;
  } catch (error) {
    console.log("signup error:", error);
    // Show error toast notification
    if (error.response.data.error != null) {
      toast.error(error.response.data.error);
    }
  } finally {
    setIsSubmitting(false);
  }
};
export const sendOTP = async (OTPdata, { setIsSubmitting, navigate }) => {
  try {
    console.log("otp button is OTPdata =", OTPdata);
    const response = await axiosAPIClient.post(
      `${dEV_API_URL}${verifyotp}`,
      OTPdata
    );

    console.log("signup response=", response);
    if (response.status == "200") {
      console.log("signup navigation=", response);
      navigate("/signUpForm", { state: { email: OTPdata.email } });
    }
    return response.data;
  } catch (error) {
    console.log("signup error:", error);
    // Show error toast notification
    if (error.response.data.error != null) {
      toast.error(error.response.data.error);
    }
  } finally {
    setIsSubmitting(false);
  }
};