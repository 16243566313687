import React, { useState, useEffect } from "react";
import Logo from "../../assets/dashboardlogo.svg";
import "./homeHeader.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

function HomeHeader() {
  const [color, setColor] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Get the cookie value and update state
    const authCookie = Cookies.get("isauthenticated") === "true";
    setIsAuthenticated(authCookie);
  }, []);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);
  console.log("isAuthenticated check" + isAuthenticated);

  return (
    <div className={color ? "colorContainer" : "container"}>
      <div className="logo" onClick={() => {}}>
        <img src={Logo} alt="logo" />
      </div>
      <div className="homeHeaderBtns">
        <div>
          <h1>Home</h1>
          <h1>Pricing</h1>
          <h1>FAQ</h1>
          <h1>About Us</h1>
        </div>
      </div>
      {isAuthenticated == true ? (
        <div className="button">
          <Link className="linkctf" to="events">
            <button
              className="btnn"
              style={{
                cursor: "pointer",
              }}
            >
              My Events
            </button>
          </Link>
        </div>
      ) : (
        <div className="loginbutton">
          <Link className="linkctf" to="login">
            <button
              className="btnn"
              style={{
                cursor: "pointer",
              }}
            >
              Log in
            </button>
          </Link>

          <Link className="linkctf" to="signUp">
            <button
              className="signupbtnn"
              style={{
                cursor: "pointer",
              }}
            >
              Sign Up
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}
export default HomeHeader;
