import React, { useEffect, useState } from "react";
import "../brandLogo/brandLogo.css";

import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Logo from "../../../assets/eventSetting/brandLogo.svg";
import HorizontalDummyImg from "../../../assets/eventSetting/hor_preview.png";
import VerticalDummyImg from "../../../assets/eventSetting/ver_preview.png";
import { getToken, dEV_API_URL } from "../../../API";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import BrandCustomSelect from "./BrandCustomSelect";
import SyncLoader from "react-spinners/SyncLoader";


const TOKEN = await getToken();

const BrandLogo = () => {
  const navigate = useNavigate(); 

  const [postStatus, setPoststatus] = useState(false);

  const [selectedPosition, setSelectedPosition] = useState("top_right");

  const [horizontalImg, setHorizontalImg] = useState(null);
  const [verticalImg, setVerticalImg] = useState(null);
  const [horizontalImgErr, setHorizontalImgErr] = useState("");
  const [verticalImgErr, setVerticalImgErr] = useState("");

  const [isHorizontalImgChange, setIsHorizontalImgChange] = useState(false);
  const [isVerticalImgChange, setIsVerticalImgChange] = useState(false);

  const [flag, setFlag] = useState(false);

  const [respData, setRespData] = useState([]);

  const [horizontalImgPosition, setHorizontalImgPosition] = useState({
    align: "flex-start",
    justify: "flex-end",
  });

  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");

  const PositionList = [
    { id: 1, value: "top_left" },
    // { id: 2, value: "top_center" },
    { id: 3, value: "top_right" },
    // { id: 4, value: "middle_left" },
    // { id: 5, value: "middle_center" },
    // { id: 6, value: "middle_right" },
    { id: 7, value: "bottom_left" },
    // { id: 8, value: "bottom_center" },
    { id: 9, value: "bottom_right" },
  ];

  let validationSchema = Yup.object({
    selectedOption: Yup.string(),
  });

  useEffect(() => {
    getBrandLogoDetail(id);
  }, [id]);

  useEffect(() => {
    if (respData.position) {
      setSelectedPosition(respData.position);
    }
  }, [respData.position]);

  const getBrandLogoDetail = (id) => {
    axios({
      method: "GET",
      url: `${dEV_API_URL}/branding/brand-image/?event_id=${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
    })
      .then((resp) => {
        setRespData(resp.data);
        setHorizontalImg(resp.data.horizontal_brand_image);
        setVerticalImg(resp.data.vertical_brand_image);
        setSelectedPosition(resp.data.position);
        setFlag(true);
      })
      .catch((err) => {
        setFlag(true);
        console.log(err);
      })
      .finally(() => {
        setFlag(true);
      });
  };

  const updateBrandLogoDetail = (values) => {
    setPoststatus(true);

    var MethodType;

    var Id;
    if (flag && respData.id) {
      Id = respData.id;
      MethodType = "PATCH";
    } else {
      MethodType = "POST";
    }

    const formData = new FormData();
    if (respData.id) {
      formData.append("brand_id", Id);
    } else {
      formData.append("event_id", id);
    }
    if (isHorizontalImgChange) {
      formData.append("horizontal_brand_image", horizontalImg);
    }
    if (isVerticalImgChange) {
      formData.append("vertical_brand_image", verticalImg);
    }
    formData.append("position", selectedPosition);

    axios({
      method: MethodType,
      url: `${dEV_API_URL}/branding/brand-image/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization: TOKEN,
      },
      data: formData,
    })
      .then((res) => {
        toast.success("Brand Logo Detail Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((err) => {
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      setHorizontalImgErr(
        "Invalid file type. Please select a PNG, JPG, or JPEG image."
      );
      return false;
    }
    if (file.size > maxSize) {
      toast.warning("File size exceeds the 2MB limit. Please select a smaller file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return false;
    }
    return true;
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setHorizontalImg(file);
          setHorizontalImgErr("");
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const isFileValid2 = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      setVerticalImgErr(
        "Invalid file type. Please select a PNG, JPG, or JPEG image."
      );
      return false;
    }
    if (file.size > maxSize) {
      toast.warning("File size exceeds the 2MB limit. Please select a smaller file.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return false;
    }
    return true;
  };

  const handleImageUpload2 = (file) => {
    if (file && isFileValid2(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setVerticalImg(file);
          setVerticalImgErr("");
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  
  const handleDragOver2 = (event) => {
    event.preventDefault();
  };

  const handleDrop2 = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload2(file);
  };

  function convertToTitleCase(str) {
    const words = str.split("_");

    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const titleCaseStr = titleCaseWords.join(" ");

    return titleCaseStr;
  }

  const handleChange = (selectedPosition) => {
    // let convertedStr = selectedPosition.label.toLowerCase().replace(/ /g, '_');

    setSelectedPosition(selectedPosition.label);
  };

  useEffect(() => {
    // let convertedStr = selectedPosition.toLowerCase().replace(/ /g, '_');
    const positionMappings = {
      top_left: { align: "flex-start", justify: "flex-start" },
      // top_center: { align: "flex-start", justify: "center" },
      top_right: { align: "flex-start", justify: "flex-end" },
      // middle_left: { align: "center", justify: "flex-start" },
      // middle_center: { align: "center", justify: "center" },
      // middle_right: { align: "center", justify: "flex-end" },
      bottom_left: { align: "flex-end", justify: "flex-start" },
      // bottom_center: { align: "flex-end", justify: "center" },
      bottom_right: { align: "flex-end", justify: "flex-end" },
    };

    setHorizontalImgPosition(
      positionMappings[selectedPosition] || {
        align: "flex-start",
        justify: "flex-end",
      }
    );
  }, [selectedPosition]);

  const showToastMsg = () => {
    // let msg;
    if (!horizontalImg && !verticalImg) {
      setHorizontalImgErr("Required");
      setVerticalImgErr("Required");
    } else if (!horizontalImg) {
      setHorizontalImgErr("Required");
    } else if (!verticalImg) {
      setVerticalImgErr("Required");
    }
    // else {
    //   msg = "Something went wrong";
    // }
    // toast.warning(msg, {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  };

  return (
    <>
      <div className="brand_logo_main_container">
        <div className="brand_logo_header_div">
          <p>Branding on photos</p>
          <p>Please upload branding if you want to add branding on photos</p>
        </div>
        {flag ? (
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              updateBrandLogoDetail(values);
            }}
          >
            {({
              values,
              isValid,
              setFieldValue,
              errors,
              touched,
              resetForm,
            }) => (
              <Form>
                <div className="brand_logo_photos_div">
                  <div className="brand_logo_photos_main_div">
                    <div className="brand_logo_photos_horizontal_div">
                      <>
                        <div
                          className="brand_logo_upload_box"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <p className="brand_logo_photos_custom_file_upload_label">
                            On Horizontal Photos *
                          </p>

                          <>
                            <label
                              htmlFor="horizontalImg"
                              className="brand_logo_photos_custom_file_upload"
                            >
                              <Field
                                type="file"
                                id="horizontalImg"
                                name="horizontalImg"
                                className="brand_logo_photos_upload_input"
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (
                                    file &&
                                    (file.type === "image/png" ||
                                      file.type === "image/jpeg" ||
                                      file.type === "image/jpg")
                                  ) {
                                    handleImageUpload(file);
                                    setIsHorizontalImgChange(true);
                                  } else {
                                    console.error(
                                      "Selected file is not an image of type PNG, JPEG, or JPG."
                                    );
                                  }
                                }}
                              />
                              <p className="brand_logo_photos_upload_box_p1">
                                <span>Choose</span> file to upload
                              </p>
                              <p className="brand_logo_photos_upload_box_p2">
                                or
                              </p>
                              <p className="brand_logo_photos_upload_box_p3">
                                Drag & drop image to upload
                              </p>
                              <p className="brand_logo_photos_upload_box_p4">
                                Recommended: 250x150px | jpeg, jpg, png | max.
                                size: 2MB
                              </p>
                            </label>

                            <p className="brand_logo_photos_error">
                              {horizontalImgErr}
                            </p>
                          </>
                        </div>

                        <div className="brand_logo_box_div">
                          <label htmlFor="selectedPosition">Position</label>
                          {/* <div className="brand_logo_select_box_div">
                            <Field
                              as="select"
                              id="selectedPosition"
                              name="selectedOption"
                              className="brand_logo_select_box"
                              onChange={handleChange}
                              value={selectedPosition}
                            >
                              {PositionList.map((item) => {
                                return (
                                  <option
                                    value={item.value}
                                    label={convertToTitleCase(item.value)}
                                  />
                                );
                              })}
                            </Field>
                          </div> */}
                             <BrandCustomSelect
                        PositionList={PositionList}
                        selectedPosition={selectedPosition}
                        handleChange={handleChange}
                        convertToTitleCase={convertToTitleCase}
                      />
                          <ErrorMessage
                            name="selectedOption"
                            component="div"
                            className="event_basic_info_error"
                          />
                        </div>
                      </>
                      <div>
                        <p className="brand_logo_photos_preview_heading">
                          Preview
                        </p>

                        <div className="brand_logo_photos_horizontal_preview_div">
                          <img
                            className="brand_logo_photos_horizontal_preview_dummy_img"
                            src={HorizontalDummyImg}
                            alt="horizontal_img"
                          />

                          <div
                            style={{
                              alignItems: `${horizontalImgPosition.align}`,
                              justifyContent: `${horizontalImgPosition.justify}`,
                            }}
                            className="brand_logo_photos_horizontal_preview_upload_img_div"
                          >
                            {!horizontalImg ? (
                              <img
                                className="brand_logo_photos_horizontal_preview_upload_img"
                                src={Logo}
                                alt="logo"
                              />
                            ) : typeof horizontalImg === "string" ? (
                              <img
                                className="brand_logo_photos_horizontal_preview_upload_img"
                                src={horizontalImg}
                                alt="logo"
                              />
                            ) : (
                              <img
                                className="brand_logo_photos_horizontal_preview_upload_img"
                                src={URL.createObjectURL(horizontalImg)}
                                alt="logo"
                              />
                            )}
                          </div>
                        </div>

                        {/* {horizontalImg && 
                             <div className="brand_logo_photos_remove_horizontal_div">
                             <p onClick={() => setHorizontalImg(null)}>Remove Logo</p>
                            </div>} */}
                      </div>
                    </div>

                    <div className="brand_logo_photos_vertical_div">
                      <>
                        <div
                          className="brand_logo_upload_box"
                          onDragOver={handleDragOver2}
                          onDrop={handleDrop2}
                        >
                          <p className="brand_logo_photos_custom_file_upload_label">
                            On Vertical Photos *
                          </p>
                          <>
                            <label
                              htmlFor="verticalImg"
                              className="brand_logo_photos_custom_file_upload"
                            >
                              <Field
                                type="file"
                                id="verticalImg"
                                name="verticalImg"
                                className="brand_logo_photos_upload_input"
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (
                                    file &&
                                    (file.type === "image/png" ||
                                      file.type === "image/jpeg" ||
                                      file.type === "image/jpg")
                                  ) {
                                    handleImageUpload2(file);
                                    setIsVerticalImgChange(true);
                                  } else {
                                    console.error(
                                      "Selected file is not an image of type PNG, JPEG, or JPG."
                                    );
                                  }
                                }}
                              />

                              <p className="brand_logo_photos_upload_box_p1">
                                <span>Choose</span> file to upload
                              </p>
                              <p className="brand_logo_photos_upload_box_p2">
                                or
                              </p>
                              <p className="brand_logo_photos_upload_box_p3">
                                Drag & drop image to upload
                              </p>
                              <p className="brand_logo_photos_upload_box_p4">
                                Recommended: 150x250px | jpeg, jpg, png | max.
                                size: 2MB
                              </p>
                            </label>
                            <p className="brand_logo_photos_error">
                              {verticalImgErr}
                            </p>
                          </>
                        </div>
                      </>
                      <div className="brand_logo_photos_vertical_preview_container">
                        <div className="brand_logo_photos_vertical_preview_div">
                          <img
                            className="brand_logo_photos_vertical_preview_dummy_img"
                            src={VerticalDummyImg}
                            alt="vertical_img"
                          />

                          <div
                            style={{
                              alignItems: `${horizontalImgPosition.align}`,
                              justifyContent: `${horizontalImgPosition.justify}`,
                            }}
                            className="brand_logo_photos_vertical_preview_upload_img_div"
                          >
                            {!verticalImg ? (
                              <img
                                className="brand_logo_photos_vertical_preview_upload_img"
                                src={Logo}
                                alt="logo"
                              />
                            ) : typeof verticalImg === "string" ? (
                              <img
                                className="brand_logo_photos_vertical_preview_upload_img"
                                src={verticalImg}
                                alt="logo"
                              />
                            ) : (
                              <img
                                className="brand_logo_photos_vertical_preview_upload_img"
                                src={URL.createObjectURL(verticalImg)}
                                alt="logo"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {/* {verticalImg && 
                             <div className="brand_logo_photos_remove_vertical_div">
                             <p onClick={() => setVerticalImg(null)}>Remove Logo</p>
                            </div>} */}
                    </div>
                  </div>
                </div>

                <div className="brand_logo_form_btns">
                  {postStatus ? (
                    <p className="brand_logo_form_submit_btn">
                      <span>
                        <ClipLoader
                          color="white"
                          size={24}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </span>
                    </p>
                  ) : (
                    <>
                      <p className="brand_logo_form_cancel_btn">
                        <span
                          onClick={() => {
                            resetForm();
                            navigate(-1);
                          }}
                        >
                          Cancel
                        </span>
                      </p>
                      {!horizontalImgErr &&
                      !verticalImgErr &&
                      horizontalImg &&
                      verticalImg ? (
                        <button
                          className="brand_logo_form_submit_btn"
                          disabled={horizontalImgErr || verticalImgErr}
                          type="submit"
                        >
                          <span>Save</span>
                        </button>
                      ) : (
                        <p
                          className="brand_logo_form_submit_btn"
                          onClick={() => {
                            showToastMsg();
                          }}
                        >
                          <span>Save</span>
                        </p>
                      )}
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) :
        <div className="sync_loader">
        <SyncLoader
          color="#E5DAF2"
          loading="...loading"
          size={6}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
        }
      </div>
    </>
  );
};

export default BrandLogo;