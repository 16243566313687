import React from "react";
import SyncLoader from "react-spinners/SyncLoader";

const LoadingScreen = () => {
  const override = {
    width: "100%",
    display: "flex",
    gap: "6px",
    alignItem: "center",
    justifyContent: "center",
    margin: "200px 0 0 0",
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          background: "#140f1c",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SyncLoader
          color="#E5DAF2"
          loading="...loading"
          cssOverride={override}
          size={6}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </>
  );
};

export default LoadingScreen;
