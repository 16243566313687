import "./mobileheader.css";
import mobile_logo from "../../assets/mobile_logo.svg";
import SnapedLogo from "../../assets/dashboardlogo.svg";
const MobileHeader = ({ brandImage }) => {
  return (
    <>
      <div className="mainContainer">
        <div className="logo">
          {brandImage ?
          <img src={brandImage} alt="logo" /> :
          <img src={SnapedLogo} alt="logo" /> }
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
