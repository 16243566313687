//API exports
import Cookies from "js-cookie";
//export const dEV_API_URL = "https://dev.api.snapd.bunkerfit.com/api/v1";
export const dEV_API_URL = "https://api.snapd.me/api/v1";
export const authSignup = "/auth/signup/";
export const verifyotp = "/auth/verify-otp/";
export const userlogin = "/auth/login/";
export const checkUserExistence = "/auth/check-user-existance/";
export const verifyEmail = "/auth/email-verification/";
export const forgotPassword = "/auth/forgot-password/";
export const changePassword = "/auth/change-password/";
export const authToken = "token";

 export async function getToken() {
  const token = Cookies.get("token");
  return token;
}

export const TOKEN = "";