import React, { useState } from "react";
import "../faq/faq.css";
import Header from "../header/Header";
import Footer from "../../components/footer/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Fag = () => {
  const [state, setState] = useState(0);

  const data = [
    {
      id: 1,
      question: "Is my data secure when using your photo recognition platform?",
      answer:
        "Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.",
    },
    {
      id: 2,
      question: "Is my data secure when using your photo recognition platform?",
      answer:
        "Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.",
    },
    {
      id: 3,
      question: "Is my data secure when using your photo recognition platform?",
      answer:
        "Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.",
    },
    {
      id: 4,
      question: "Is my data secure when using your photo recognition platform?",
      answer:
        "Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.",
    },
    {
      id: 5,
      question: "Is my data secure when using your photo recognition platform?",
      answer:
        "Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.",
    },
    {
      id: 6,
      question: "Is my data secure when using your photo recognition platform?",
      answer:
        "Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.",
    },
    {
      id: 7,
      question: "Is my data secure when using your photo recognition platform?",
      answer:
        "Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the  printing and typesetting industry. Lorem Ipsum has been.",
    },
  ];

  return (
    <>
      <div className="faq_wrapper">
        <div className="faq_inner">
          <div className="faq_main_div">
            <div>
              <div className="about_header_div">
                <Header />
              </div>
              <div className="faq_content_div">
                <p className="faq_main_heading">Frequently Asked Questions</p>
                <p className="faq_sub_heading">
                  Snapd FAQs: Your Guide to Frequently Asked Questions on our
                  Photo Sharing Platform
                </p>

                <div className="faq_cards">
                  {data.map((item) => {
                    return (
                      <div className="faq_card">
                        <div
                          className="faq_card_question_div"
                          onClick={() =>
                            setState(state === item.id ? 0 : item.id)
                          }
                        >
                          <p className="faq_card_question">{item.question}</p>
                          <div>
                            {state === item.id ? (
                              <KeyboardArrowUpIcon className="faq_card_arrow" />
                            ) : (
                              <KeyboardArrowDownIcon className="faq_card_arrow" />
                            )}
                          </div>
                        </div>
                        {state === item.id && (
                          <div className="faq_card_answer_div">
                            <p className="faq_card_answer">{item.answer}</p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="faq_footer_div">
              <Footer />
            </div>
          </div>
          <div className="faq_overlay"></div>
        </div>
      </div>
    </>
  );
};

export default Fag;
