// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topSection {
  background-color: black;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  width: 100%;
  /* background-position-x: -258px; */
  /* -83px; */
  background-position-y: -220px;
  /* -236px; */
}
`, "",{"version":3,"sources":["webpack://./src/features/Home/home.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yDAA4C;EAC5C,4BAA4B;EAC5B,WAAW;EACX,mCAAmC;EACnC,WAAW;EACX,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".topSection {\n  background-color: black;\n  background-image: url(\"../../assets/bg.svg\");\n  background-repeat: no-repeat;\n  width: 100%;\n  /* background-position-x: -258px; */\n  /* -83px; */\n  background-position-y: -220px;\n  /* -236px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
