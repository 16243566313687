import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, dEV_API_URL } from "../../API";

export const fetchEventList = createAsyncThunk("eventList", async ({ pageNumber }) => {
  // const data = {
  //   page_size: 1,
  // };
  const TOKEN = await getToken();
  const response = await axios({
    method: "GET",
    url: `${dEV_API_URL}/events/events-list/?&page=${pageNumber}`,
    headers: {
      "content-type": "application/json",
      Authorization: TOKEN,
    },
    // data,
  }); 
  return response.data;
});

const initialState = {
  status: 'idle',
  respData: [],
  headerData: [],
  error: "",
  page: 1, 
  count: 0,
  hasMore: true,
};

const eventListReducer = createSlice({
  name: "eventList",
  initialState,
  reducers: {
    resetEventList: (state, action) => {
      state.status = "idle";
      state.respData = [];
      state.headerData = [];
      state.error = "";
      state.page = 1;
      state.count = 0;
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.respData = [...state.respData, ...action.payload.results];

        state.headerData = action.payload;
        state.page += 1;
        state.count = action.payload.count; 
        state.hasMore = action.payload.next !== null; 
      })
      .addCase(fetchEventList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetEventList } = eventListReducer.actions;
export default eventListReducer.reducer;
