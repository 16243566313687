import React, { useEffect, useState } from "react";
import "./Card.css";
import NoImg from "../../../assets/NoImg.svg";
import axios from "axios";
import FolderImg from "../../../assets/events/folder.svg";
import PhotosCount from "../../../assets/events/photoCount.svg";
import CopyLinkImg from "../../../assets/events/copyLink.svg";
import ShareImg from "../../../assets/events/share.svg";
import SettingImg from "../../../assets/events/setting.svg";
import ManageAlbumImg from "../../../assets/events/manageAlbum.svg";
import DeleteEventImg from "../../../assets/events/deleteEvent.svg";
import { Link } from "react-router-dom";
import DeleteEvent from "../DeleteEvent/DeleteEvent";
import ShareEvent from "../ShareEvent/ShareEvent";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { getToken, dEV_API_URL } from "../../../API";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Assuming the getToken function is asynchronous, you should call it inside useEffect
const Card = ({ respData }) => {
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [shareEventModal, setShareEventModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedEventData, setSelectedEventData] = useState({
    eventId: null,
    albumCount: null,
    photosCount: null,
  });

  const [switchStates, setSwitchStates] = useState({});
  const [screenSize, setScreenSize] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [TOKEN, setTOKEN] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getToken();
      setTOKEN(token);
    };

    fetchToken();
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenSize(window.innerWidth);

      const actualSize = () => {
        setScreenSize(window.innerWidth);
      };

      window.addEventListener("resize", actualSize);
      return () => {
        window.removeEventListener("resize", actualSize);
      };
    }
  }, [screenSize]);

  useEffect(() => {
    if (screenSize < 1024 && screenSize > 600) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [screenSize]);

  useEffect(() => {
    // Step 2: Update switch states based on respData
    const switchStatesInit = {};
    respData.forEach((item) => {
      switchStatesInit[item.id] = item.published || false;
    });
    setSwitchStates(switchStatesInit);
  }, [respData]);

  const handleSwitchChange = async (item) => {
    const newSwitchValue = !switchStates[item.id];

    // Step 5: Call the updateEventApi function when the switch changes
    await updateEvent(item, newSwitchValue);

    // Step 6: Update switch state after successful API request
    setSwitchStates((prevState) => ({
      ...prevState,
      [item.id]: newSwitchValue,
    }));
  };

  const updateEvent = (item, publishVal) => {
    const data = {
      event_id: item.id,
      title: item.title,
      description: item.description,
      is_facial_search_active: item.is_facial_search_active,
      can_user_view_all_images_event_wide: item.can_user_view_all_images_event_wide,
      category: item.category,
      venue: item.venue,
      published: publishVal,
    };

    axios({
      method: "PATCH",
      url: `${dEV_API_URL}/events/events/`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
      data,
    })
      .then((res) => {
        toast.success(
          `Event ${publishVal === true ? "Published" : "Unpublished"}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .catch((err) => {
        console.log(err);
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        alert("not changed");
      });
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(17px)",
        color: "#381E72",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#D0BCFF" : "#D0BCFF",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 16,
      height: 16,
      borderRadius: 50,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 40 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#534B61",
      boxSizing: "border-box",
    },
  }));

  const handleOpen = (eventId, albumCount, photoCount) => {
    setSelectedEventData({
      eventId,
      albumCount,
      photoCount,
    });
    setDeleteEventModal(true);
  };

  const handleClose = () => {
    setSelectedEventData({
      eventId: null,
      albumCount: null,
      photoCount: null,
    });
    setDeleteEventModal(false);
  };

  const openShareModal = (eventId, id) => {
    setSelectedEventId(eventId);
    setShareEventModal(true);
    setSelectedId(id);
  };

  const closeShareModal = () => {
    setSelectedEventId(null);
    setShareEventModal(false);
  };

  const CopyText = (eventId, id) => {
    var baseUrl = window.location.origin;
    const link = `${baseUrl}/search-photos/?eventId=${eventId}&id=${id}`;
  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link)
        .then(() => {
          toast.success("Link Copied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
          toast.error("Failed to copy link", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    } else {
      console.error('Clipboard API not available');
      toast.error("Clipboard API not available", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const navigate = useNavigate();
  const navigateToAlbums = (eventId) => {
    navigate(`/albums?eventId=${eventId}`);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <List className="event_card_bottom_drawer_list">
      <ListItem
        className="event_card_bottom_drawer_list_inner"
        button
        onClick={() => {
          CopyText(selectedEventId, selectedId);
          setDrawerOpen(false);
        }}
      >
        <ListItemIcon>
          <img src={CopyLinkImg} alt="Copy Link" />
        </ListItemIcon>
        <ListItemText className="event_card_bottom_drawer_list_text" primary="Copy Link" />
      </ListItem>
      <ListItem
        className="event_card_bottom_drawer_list_inner"
        button
        onClick={() => {
          openShareModal(selectedEventId, selectedId);
          setDrawerOpen(false);
        }}
      >
        <ListItemIcon>
          <img src={ShareImg} alt="Share" />
        </ListItemIcon>
        <ListItemText className="event_card_bottom_drawer_list_text" primary="Share" />
      </ListItem>
      <ListItem
        className="event_card_bottom_drawer_list_inner"
        button
        onClick={() => {
          navigate(`/event-setting?eventId=${selectedId}`);
          setDrawerOpen(false);
        }}
      >
        <ListItemIcon>
          <img src={SettingImg} alt="Event Settings" />
        </ListItemIcon>
        <ListItemText className="event_card_bottom_drawer_list_text" primary="Event Settings" />
      </ListItem>
      <ListItem
        className="event_card_bottom_drawer_list_inner"
        button
        onClick={() => {
          navigate(`/albums?eventId=${selectedEventId}`);
          setDrawerOpen(false);
        }}
      >
        <ListItemIcon>
          <img src={ManageAlbumImg} alt="View Albums" />
        </ListItemIcon>
        <ListItemText className="event_card_bottom_drawer_list_text" primary="View Albums" />
      </ListItem>
      <ListItem
        className="event_card_bottom_drawer_list_inner"
        button
        onClick={() => {
          handleOpen(
            selectedId,
            selectedEventData.albumCount,
            selectedEventData.photosCount
          );
          setDrawerOpen(false);
        }}
      >
        <ListItemIcon>
          <img src={DeleteEventImg} alt="Delete Event" />
        </ListItemIcon>
        <ListItemText className="event_card_bottom_drawer_list_text" primary="Delete Event" />
      </ListItem>
    </List>
  );

  return (
    <>
      {respData.map((item) => (
        <div className="event_card_content" key={item.id} onClick={() => navigateToAlbums(item.uuid)}>
          <div className="event_card">
            <div className="event_img_card">
              {item.cover_image ? (
                <div className="event_img_div">
                  <img src={item.cover_image} alt="event_image" />
                </div>
              ) : (
                <div className="event_no_img_div">
                  <img src={NoImg} alt="event_image" />
                  <p>No Image Available</p>
                </div>
              )}
            </div>
            <div className="event_info_div">
              <div className="event_info_title_toogle_div">
                <p className="event_name">{item.title}</p>
                <div
                  className="event_info_toogle_div"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <AntSwitch
                      checked={switchStates[item.id] || false}
                      onChange={(e) => {
                        handleSwitchChange(item);
                      }}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                  </Stack>
                  <p className="event_info_publish_p">
                    {switchStates[item.id] ? "Published" : "Unpublish"}
                  </p>
                </div>
                <MoreVertIcon
                  className="event_info_more_btn_icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEventId(item.uuid);
                    setSelectedId(item.id);
                    toggleDrawer(true)(e);
                  }}
                />
              </div>
              <p className="event_description">{item.description}</p>
              <div className="photo_count_div">
                <div className="photo_count_card">
                  <img src={PhotosCount} alt="photos" />
                  <p>{item.number_of_images_across_albums}</p>
                </div>
                <div className="photo_count_card">
                  <img src={FolderImg} alt="photos" />
                  <p>{item.album_count_in_event}</p>
                </div>
              </div>

              <div className="card_btns">
                <Tooltip title={showTooltip ? "Copy Link" : ""} placement="top">
                  <div
                    className="card_btn_div"
                    onClick={(e) => {
                      e.stopPropagation(); // Stop propagation here
                      CopyText(item.uuid, item.id);
                    }}
                  >
                    <img src={CopyLinkImg} alt="copy_link" />
                    <p>Copy Link</p>
                  </div>
                </Tooltip>

                <Tooltip title={showTooltip ? "Share" : ""} placement="top">
                  <div
                    className="card_btn_div"
                    onClick={(e) => {
                      e.stopPropagation();
                      openShareModal(item.uuid, item.id);
                    }}
                  >
                    <img src={ShareImg} alt="copy_link" />
                    <p>Share</p>
                  </div>
                </Tooltip>

                <Link
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: "none" }}
                  to={`/event-setting?eventId=${item.id}`}
                >
                  <Tooltip title={showTooltip ? "Event Settings" : ""} placement="top">
                    <div className="card_btn_div">
                      <img src={SettingImg} alt="copy_link" />
                      <p>Event Settings</p>
                    </div>
                  </Tooltip>
                </Link>

                <Link
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: "none" }}
                  to={`/albums?eventId=${item.uuid}`}
                >
                  <Tooltip title={showTooltip ? "View Albums" : ""} placement="top">
                    <div className="card_btn_div">
                      <img src={ManageAlbumImg} alt="copy_link" />
                      <p>View Albums</p>
                    </div>
                  </Tooltip>
                </Link>

                <Tooltip title={showTooltip ? "Delete Event" : ""} placement="top">
                  <div
                    className="card_btn_div"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpen(
                        item.id,
                        item.album_count_in_event,
                        item.number_of_images_across_albums
                      );
                    }}
                  >
                    <img src={DeleteEventImg} alt="copy_link" />
                    <p>Delete Event</p>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerList}
      </Drawer>

      <DeleteEvent
        open={deleteEventModal}
        onClose={handleClose}
        selectedEventData={selectedEventData}
      />
      <ShareEvent
        open={shareEventModal}
        onClose={closeShareModal}
        eventId={selectedEventId}
        selectedId={selectedId}
      />
    </>
  );
};

export default Card;