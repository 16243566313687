import React, { useState, useEffect } from "react";
import "../sidebar/SideBar.css";
import Event from "../../assets/sidebar/Event.svg";
import { ChangeSideBarBtn } from "./SidebarReducer";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

const SideBar = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.sidebar.sidebar);

  const [screenSize, setScreenSize] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenSize(window.innerWidth);

      const actualSize = () => {
        setScreenSize(window.innerWidth);
      };

      window.addEventListener("resize", actualSize);
      return () => {
        window.removeEventListener("resize", actualSize);
      };
    }
  }, [screenSize]);

  useEffect(() => {
    if (screenSize < 1250 && screenSize > 600) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [screenSize]);

  return (
    <>
      <div className="sidebar_main_container">
        <div className="sidebar_btns">
          <ul>
            <Tooltip title={showTooltip ? "My Events" : ""} placement="left">
              <li
                onClick={() => dispatch(ChangeSideBarBtn("event"))}
                className={
                  state === "event" ? "active_sidebar_btn" : "sidebar_btn"
                }
              >
                <img src={Event} alt="Event" />
                <p>My Events</p>
              </li>
            </Tooltip>
            {/* <li onClick={() => dispatch(ChangeSideBarBtn("share"))} className={state === "share" ? "active_sidebar_btn" : "sidebar_btn"}>
              <img src={Share} alt="Event" />
              <p>Shared withme</p>
            </li>
            <li
              onClick={() => dispatch(ChangeSideBarBtn("archieve"))}
              className={
                state === "archieve" ? "active_sidebar_btn" : "sidebar_btn"
              }
            >
              <img src={Download} alt="Event" />
              <p>Archieve</p>
            </li>
            <li
              onClick={() => dispatch(ChangeSideBarBtn("collaboraters"))}
              className={
                state === "collaboraters" ? "active_sidebar_btn" : "sidebar_btn"
              }
            >
              <img src={People} alt="Event" />
              <p>My Collaboraters</p>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
