import { configureStore } from "@reduxjs/toolkit";
// import modalSlice from "../features/demo/DemoReducer";
import photoReducer from "../features/viewPhotos/ViewPhotoReducer";
import SidebarReducer from "../components/sidebar/SidebarReducer";
import EventSettingPage from "../features/EventSetting/eventSettingReducer";
import selfieReducer from "../features/SearchPhoto/selfieReducer";
import EventListReducer from "../features/Events/EventListReducer";
import AlbumListReducer from "../features/Album/Albums/AlbumListReducer";
import EventPhotoReducer from "../features/ViewEventImages/ViewEventImagesReducer";
import AuthReducer from "../components/common/AuthReducer";

export const store = configureStore({
  reducer: {
    photos: photoReducer,
    eventPhotos: EventPhotoReducer,
    sidebar: SidebarReducer,
    eventSetting: EventSettingPage,
    uploadselfie: selfieReducer,

    eventList: EventListReducer,
    albumList: AlbumListReducer,
    userAuth: AuthReducer,
  },
});
