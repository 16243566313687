import React from "react";
import Select from "react-select";

const CustomSelect = ({ categoriesList, selectedOption, handleChange, setFieldTouched, setFieldValue  }) => {
  const options = categoriesList.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: "white",
      borderRadius: "6px",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1c1526",
      color: "#fff",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#1c1526",
      color: "white",
      border: "none",
      "&:hover": {
        backgroundColor: "#1c1526",
        color: "white",
      },
    }),
  };

  const handleSelectChange = (option) => {
    handleChange(option);
    setFieldTouched("selectedOption", true);
    setFieldValue("selectedOption", option ? option.value : "");
  };

  return (
    <div className="event_select_box_div">
      <Select
        value={options.find((option) => option.value === selectedOption)}
        onChange={handleSelectChange}
        options={options}
        classNamePrefix="event_select_box"
        placeholder="select"
        isSearchable={false}
        styles={customStyles}
      />
    </div>
  );
};

export default CustomSelect;
