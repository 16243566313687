import React, { useEffect, useState } from 'react';
import './users.css';
import axios from 'axios';
import { dEV_API_URL } from '../../API';
import Slider from "react-slick";

function Users() {
    const [data, setData] = useState([]);
    const [state, setState] = useState(window.screen.width);

    const actualSize = () => {
        setState(window.innerWidth);
     }
    
      useEffect(() => {
        window.addEventListener("resize", actualSize);
      });
     
      let cardCount;
      if(state <= 600) {
          cardCount = 1;
      } else if (state >= 600 && state <= 1000) {
        cardCount = 2;
      } else if (state >= 1000) {
        cardCount = 3;
      }

      var settings = {
        dots: true,
        infinite: true,
        slidesToShow: cardCount,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: true,
        // pauseOnHover: true,
        arrows: false,
        draggable: false, 
      };

    function getTestimoanial() {
        axios({
            method: "GET",
            url: `${dEV_API_URL}/homepage/testimonials/`,
            headers: {
                "content-type": "application/json",
            }, 
        })
            .then((resp) => {
                // console.log(resp);
                setData(resp.data.testimonials.slice(0, 3));  
            })
            .catch((err) => {
                console.log(err);
            });

    }
    useEffect(() => {
        getTestimoanial();
    }, [])
    return (
        <>

            <div className="usersContainer" >
                <div>
                    <div className="usersMain">Trusted by Users</div>
                    <div className="usersSub">Immerse yourself in a world where every interaction sparks. With captivating design,
                   our platform transforms moments into unforgettable experiences.</div>
                </div>
                
                <div className='cards'>
                <Slider {...settings}>
          {data.map((item) => {
            return (
                <div className='card'>
                <p>{item.message}</p>
                <div>
                    <div>
                        <img src={item.photo} alt={item.name} className="image" />
                    </div>
                    <div>
                        <h4>{item.name}</h4>
                        <p>{item.organization}</p>
                    </div>
                </div>
            </div>
            );
          })}
        </Slider>
                </div>

            </div>
        </>
    )
}
export default Users;