import React, { useEffect, useCallback, useState, useContext } from "react";
import "../albumList/albumList.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/dashboardlogo.svg";
import PhotoCount from "../../assets/events/photoCount.svg";
import NoImg from "../../assets/NoImg.svg";
import { dEV_API_URL } from "../../API";
import SyncLoader from "react-spinners/SyncLoader";
import NoEvent from "../../assets/events/NoEvent.gif";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAlbumList,
  resetAlbumList,
} from "../../features/Album/Albums/AlbumListReducer";
import InfiniteScroll from "react-infinite-scroll-component";

const AlbumList = () => {
  let queryParams = new URLSearchParams(window.location.search);
  let eventId = queryParams.get("eventId");


  const dispatch = useDispatch();
  const { respData, headerData, status, hasMore } = useSelector(
    (state) => state.albumList
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const resetData = async () => {
      if (isLoading === true) {
        await dispatch(resetAlbumList());
        setIsLoading(false);
      }
    };
    resetData();
  }, []);

  const override = {
    width: "100%",
    display: "flex",
    gap: "6px",
    alignItem: "center",
    justifyContent: "center",
    margin: "200px 0 0 0",
  };

  useEffect(() => {
    if (isLoading === false) {
      fetchData();
    }
  }, [isLoading]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    dispatch(
      fetchAlbumList({ eventId: eventId, pageNumber: respData.length / 10 + 1 })
    );
  }, [dispatch, eventId, respData]);

  return (
    <>
      <div className="album_list_wrapper">
        <div className="album_list_header">
          <div className="album_list_logo_div">
            <img src={Logo} alt="logo" />
          </div>
        </div>

        <div className="album_list_main_container">
          {headerData.event_name &&
        <div className="album_list_subheader">
           <p className="album_list_subheader_p1"> {headerData.event_name} </p>
           {headerData.brand_tagline &&
           <p className="album_list_subheader_p2"> {headerData.brand_tagline} </p> }
        </div> }

          {respData.length === 0 && status === "loading" ? (
            <SyncLoader
              color="#E5DAF2"
              loading="...loading"
              cssOverride={override}
              size={6}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              {respData.length > 0 ? (
                 <InfiniteScroll 
                 dataLength={respData.length}
                 next={fetchData}
                 hasMore={hasMore}
                 loader={<p>Loading......</p>}
                 endMessage={<p className="your_image_no_more">No more data to load.</p>}
               >
                <div className="album_list_cards">
                 {respData.map((item) => {
            return (
              <Link style={{textDecoration: "none"}} to={`/view-photos?id=${item.uuid}`}>
                <div className="album_list_card"> 
                  <div className="album_list_card_img_div">
                    {
                      item.album_cover_image === null ? 
                      <div className="album_list_card_no_img_div">
                         <img src={NoImg} alt="album_image" />
                         <p className="album_list_card_no_img_p">No Image Available</p>
                       </div> :
                    <img className="album_list_cover_img" src={item.album_cover_image} alt="img" /> 
                    }
                    </div>
                    <div className="album_list_card_content">
                      <p className="album_list_card_content_title"> {item.title} </p>
                      <div className="album_list_card_count_div">
                        <img src={PhotoCount} alt="album_logo" />
                        <p className="album_list_card_count_p"> {item.image_count_in_album} </p>
                      </div>
                      
                    </div>
                </div>
              </Link>
            );
          })}
          </div>
                </InfiniteScroll>
              ) : (
                <div className="no_album_found_div">
                  <img src={NoEvent} alt="img" />
                  <p>No album Available</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AlbumList;