import React, {useState, useEffect} from "react";
import "../ShareEvent/shareEvent.css";

import axios from "axios";
import Modal from "@mui/material/Modal";
import CrossImg from "../../../assets/events/cross.svg";

import CopyLink from "../../../assets/share/copy.svg";
import Whatsapp from "../../../assets/share/whatsapp.svg";
import Facebook from "../../../assets/share/facebook.svg";
import Telegram from "../../../assets/share/telegram.svg";
import Twitter from "../../../assets/share/twitter.svg";

import { toast } from "react-toastify";

const ShareEvent = ({ open, onClose, eventId, selectedId }) => {
  const [shareUrl, setShareUrl] = useState("");


  useEffect(() => {
    if(eventId) {
    var baseUrl = window.location.origin;
    setShareUrl(`${baseUrl}/search-photos/?eventId=${eventId}&id=${selectedId}`);
    }
  }, [eventId])

  const CopyText = (eventId, id) => {
    var baseUrl = window.location.origin;
    const link = `${baseUrl}/search-photos/?eventId=${eventId}&id=${id}`;
  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link)
        .then(() => {
          toast.success("Link Copied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
          toast.error("Failed to copy link", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    } else {
      console.error('Clipboard API not available');
      toast.error("Clipboard API not available", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <Modal
        className="event_share_modal_container"
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="event_share_modal_card">
          <div className="event_share_modal_card_header">
            <p>Share this Event</p>
            <img onClick={onClose} src={CrossImg} alt="icon" />
          </div>
          <div className="event_share_main_div">
            <div className="event_share_icon_first_row">
              <div className="event_share_card" onClick={() => CopyText(eventId, selectedId)}>
                <img
                  className="event_share_card_copy_img"
                  src={CopyLink}
                  alt="copy link"
                />
                <p>Copy</p>
              </div>
              <a style={{textDecoration: "none"}}
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                shareUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="event_share_card">
                <img src={Whatsapp} alt="copy link" />
                <p>Whatsapp</p>
              </div>
              </a>

              <a style={{textDecoration: "none"}}
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                shareUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="event_share_card">
                <img src={Facebook} alt="copy link" />
                <p>Facebook</p>
              </div>
              </a>

              <a style={{textDecoration: "none"}}
              href={`https://t.me/share/url?url=${encodeURIComponent(
                shareUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="event_share_card">
                <img src={Telegram} alt="copy link" />
                <p>Telegram</p>
              </div>
              </a>

              <a style={{textDecoration: "none"}}
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                shareUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="event_share_card">
                <img src={Twitter} alt="copy link" />
                <p>X</p>
              </div>
              </a>
              
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareEvent;
