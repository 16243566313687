import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { dEV_API_URL, TOKEN } from "../../../API";

export const fetchAlbumList = createAsyncThunk(
  "albumList",
  async ({ eventId, pageNumber }) => {
    const response = await axios({
      method: "GET",
      url: `${dEV_API_URL}/events/albums-list/?event_uuid=${eventId}&page=${pageNumber}`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
    });
    return response.data;
  }
);

const initialState = {
  status: "idle",
  respData: [],
  headerData: [],
  error: "",
  page: 1,
  count: 0,
  hasMore: true,
  newEventID:null
};

const albumListReducer = createSlice({
  name: "albumList",
  initialState,
  reducers: {
    resetAlbumList: (state, action) => {
      state.status = "idle";
      state.respData = [];
      state.headerData = [];
      state.error = "";
      state.page = 1;
      state.count = 0;
      state.newEventID=null;
    }, 
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAlbumList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAlbumList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.headerData = action.payload;
        state.respData = [...state.respData, ...action.payload.results];
        state.page += 1;
        state.newEventID = action.payload.event_id;
        state.count = action.payload.count;
        state.hasMore = action.payload.next !== null;
      })
      .addCase(fetchAlbumList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetAlbumList } = albumListReducer.actions;
export default albumListReducer.reducer;
