import React, { useState } from "react";
import "../CreateAlbum/createAlbum.css";
import Header from "../../../components/header/Header";
import SideBar from "../../../components/sidebar/SideBar";

import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form, replace } from "formik";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken, dEV_API_URL } from "../../../API";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from "react-redux";
import { resetAlbumList } from "../Albums/AlbumListReducer";

const TOKEN = await getToken();

const CreateAlbum = () => {
  const [postStatus, setPoststatus] = useState(false);
  const [coverImg, setCoverImg] = useState(null);
  const [coverImgErr, setCoverImgErr] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");
  let eventuuid = queryParams.get("uuid");

  let validationSchema = Yup.object({
    album_name: Yup.string()
      .min(3, "Album Name must be at least 3 characters")
      .max(150, "Album Name must be at most 150 characters")
      .required("Album Name is required"),
    description: Yup.string()
      .min(5, "Description must be at least 5 characters")
      .max(500, "Must have less than 500 characters"),
  });

  const createAlbum = (values) => {
    setPoststatus(true);
    const formData = new FormData();
    formData.append("event_id", id);
    formData.append("title", values.album_name);
    formData.append("description", values.description);
    if (coverImg !== null) {
      formData.append("album_cover_image", coverImg);
    }
    formData.append("facial_search_active", true);
    formData.append("can_user_view_all_images_album_wide", true);

    axios({
      method: "POST",
      url: `${dEV_API_URL}/events/albums/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization: TOKEN,
      },
      data: formData,
    })
      .then((res) => {
        toast.success("Album Created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        dispatch(resetAlbumList());
        navigate(`/albums?eventId=${eventuuid}&uuid=${eventuuid}`, {
          replace: true,
        });
      })
      .catch((err) => {
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      toast.error(
        "Invalid file type. Please select a PNG, JPG, or JPEG image.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      return false;
    }
    if (file.size > maxSize) {
      toast.error(
        "File size exceeds the 5MB limit. Please select a smaller file.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      return false;
    }
    return true;
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Aspect ratio validation removed
          setCoverImg(file);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }; 

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const removeImg = () => {
    setCoverImg(null);
    setCoverImgErr("");
  };

  return (
    <>
      <Header />
      <div className="create_album_main_container">
        <SideBar />
        <div className="create_album_main_div">
          <p className="create_album_heading">Create Album</p>
          <div className="create_album_form">
            <Formik
              initialValues={{
                album_name: "",
                description: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                createAlbum(values);
              }}
            >
              {({
                values,
                isValid,
                setFieldValue,
                errors,
                touched,
                resetForm,
              }) => (
                <Form>
                  <div className="create_album_fields">
                    <div className="create_album_field_box_div">
                      <label htmlFor="album_name">Album Name *</label>
                      <Field
                        type="text"
                        id="album_name"
                        name="album_name"
                        className="create_album_field_box"
                        placeholder="John"
                        maxLength={150}
                      />
                      <ErrorMessage
                        name="album_name"
                        component="div"
                        className="create_album_error"
                      />
                    </div>

                    <div className="create_album_des_and_img_div">
                      <div className="create_album_description_box_div">
                        <label htmlFor="description">Description</label>
                        <Field
                          type="text"
                          as="textarea"
                          id="description"
                          name="description"
                          className="create_album_description_box"
                          placeholder="Enter a short description"
                          style={{ resize: "none" }}
                          maxLength={500}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="create_album_error"
                        />
                      </div>

                      <>
                        <div
                          className="upload_box"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <p className="custom_file_upload_label">
                            Upload Cover Image
                          </p>
                          {coverImg == null ? (
                            <>
                              <label
                                htmlFor="coverimg"
                                className="custom_file_upload"
                              >
                                <input
                                  type="file"
                                  id="coverimg"
                                  name="coverimg"
                                  className="upload_input"
                                  accept="image/png, image/jpeg, image/jpg"
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    if (
                                      e.target.files[0].type === "image/png" ||
                                      e.target.files[0].type === "image/jpeg" ||
                                      e.target.files[0].type === "image/jpg"
                                    ) {
                                      const file = e.target.files[0];
                                      handleImageUpload(file);
                                    }
                                  }}
                                />
                                <p className="upload_box_p1">
                                  <span>Choose</span> file to upload
                                </p>
                                <p className="upload_box_p2">or</p>
                                <p className="upload_box_p3">
                                  Drag & drop image to upload
                                </p>
                                <p className="upload_box_p4">
                                  Recommended: 269x186 | jpeg, jpg, png | max.
                                  size: 5MB
                                </p>
                              </label>
                              <p className="create_event_error">
                                {" "}
                                {coverImgErr}
                              </p>
                            </>
                          ) : (
                            <div className="cover_img_div">
                              <img
                                src={URL.createObjectURL(coverImg)}
                                alt="cover_image"
                              />
                              <div className="trash_div">
                                <div
                                  onClick={removeImg}
                                  className="trash_inner_div"
                                >
                                  <FaTrashAlt color="white" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                  </div>

                  <div className="create_album_form_btns">
                    {postStatus ? (
                      <p className="create_album_form_submit_btn">
                        <span>
                          <ClipLoader
                            color="white"
                            size={24}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </span>
                      </p>
                    ) : (
                      <>
                        <p
                          className="create_album_form_cancel_btn"
                          onClick={() => {
                            resetForm();
                            navigate(-1);
                          }}
                        >
                          <span>Cancel</span>
                        </p>
                        <button
                          className="create_album_form_submit_btn"
                          disabled={coverImgErr !== ""}
                          type="submit"
                        >
                          <span>Create</span>
                        </button>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAlbum;
