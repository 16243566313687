import React, { useState, useEffect, useMemo } from 'react'
import useMeasure from 'react-use-measure'
import { useTransition, a } from '@react-spring/web'
import { shuffle } from 'lodash'

import useMedia from './useMedia'
import data from './data'
import './masnory.css'; 

const Masonry = () => {
    const [screensize, setScreensize] = useState(window.screen.width);

    const actualSize = () => {
      setScreensize(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener("resize", actualSize);
    });
  
    let imgGap;
    if (screensize >= 1600) {
        imgGap = 10;
    } else if (screensize >= 1400) {
        imgGap = -60;
    } else if (screensize >= 1200) {
        imgGap = -60;
    } else if (screensize >= 1000) {
        imgGap = -80;
    } else if (screensize >= 800) {
        imgGap = -90;
    } else if (screensize >= 600) {
        imgGap = -90;
    } else {
        imgGap = -90;
    }

    // Hook1: Tie media queries to the number of columns
    const columns = useMedia(['(min-width: 500px)', '(min-width: 500px)', '(min-width: 600px)'], [3, 3, 1], 3)
    // Hook2: Measure the width of the container element
    const [ref, { width }] = useMeasure()
    // Hook3: Hold items
    const [items, set] = useState(data)
    // Hook4: shuffle data every 2 seconds
    useEffect(() => {
        const t = setInterval(() => set(shuffle), 2000)
        return () => clearInterval(t)
    }, [])
    // Hook5: Form a grid of stacked items using width & columns we got from hooks 1 & 2
    const [heights, gridItems] = useMemo(() => {
        let numColumns = columns;
        if (width < 600) {
            numColumns = 2; // Set 2 columns for screens less than 600px width
        }
        let heights = new Array(numColumns).fill(0);
        let gridItems = items.map((child, i) => {
            const column = heights.indexOf(Math.min(...heights));
            const spacing = imgGap;
            const x = (width / numColumns) * column;
            const y = ((heights[column] += child.height / 2) - child.height / 2) - 50 + spacing * Math.floor(i / numColumns); // Include spacing between rows
            return { ...child, x, y, width: width / numColumns, height: child.height / 2 };
        });
        return [heights, gridItems];
    }, [columns, items, width]);

    // Hook6: Turn the static grid values into animated transitions, any addition, removal or change will be animated
    const transitions = useTransition(gridItems, {
        key: item => item.css,
        from: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 0 }),
        enter: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 1 }),
        update: ({ x, y, width, height }) => ({ x, y, width, height }),
        leave: { height: 0, opacity: 0 },
        config: { mass: 5, tension: 500, friction: 100 },
        trail: 25,
    })
    // Render the grid
    return (
        <div ref={ref} className="list">
            {transitions((style, item) => (
                <a.div style={style}>
                    <img src={item.css} alt="hello" className="imagesCss" />
                    {/* <div style={{ backgroundImage: `url(${item.css}?auto=compress&dpr=2&h=500&w=500)` }} /> */}
                </a.div>
            ))}
        </div>
    )
}

export default Masonry;