// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer {
  display: flex;
  align-items: center;
  height: 54px;
  background-color: #140F1C;
  margin-bottom: 16px;
}

.logo img {
  width: 75px;
  height: 20px;
}

.logo {
  margin-left: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/mobileHeader/mobileheader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".mainContainer {\n  display: flex;\n  align-items: center;\n  height: 54px;\n  background-color: #140F1C;\n  margin-bottom: 16px;\n}\n\n.logo img {\n  width: 75px;\n  height: 20px;\n}\n\n.logo {\n  margin-left: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
