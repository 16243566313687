import React from "react";
import "./createGallery.css";
import Masonry from "./masnory";

function CreateGallery() {
  return (
    <>
      <div className="createGalleryContainer">
        <div>
          <div className="galleryText">
            You an event organizer? Or simply hosting a lovely party?
          </div>  
          <div className="subText">
            <p>
              Use Snapd to Create an Online Gallery and bring instant happiness
              to your guests and attendees!
            </p>
            <p>Create Your Story: Build Your Online Gallery Today</p>
          </div>
        </div>
      </div>
      <div className="responsiveness">
        <Masonry />
      </div>
    </>
  );
}
export default CreateGallery;
