import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventSettingPage: "basic",
};

const eventSettingPage = createSlice({
  name: "eventSetting",
  initialState,
  reducers: {
    ChangeEventSettingPage: (state, action) => {
      state.eventSettingPage = action.payload;
    },
  },
});

export const { ChangeEventSettingPage } = eventSettingPage.actions;
export default eventSettingPage.reducer;
