import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ChangeUserAuth } from "../components/common/AuthReducer.js";

import ViewPhoto from "../features/viewPhotos/ViewPhoto";
import Home from "../features/Home/Home";
import UploadPhotos from "../features/Upload/Main";
import Selfie from "../features/SearchPhoto/Selfie";
import CreateEvent from "../features/CreateEvent/CreateEvent";
import Events from "../features/Events/EventMain";
import EventSetting from "../features/EventSetting/Index";
import CreateAlbum from "../features/Album/CreateAlbum/CreateAlbum";
import Albums from "../features/Album/Albums/Albums";
import AlbumSetting from "../features/Album/AlbumSetting/AlbumSetting";
import EventGallery from "../features/eventGallery/eventGallery";
import YourPhotos from "../features/yourPhotos/YourPhotos";
import SomeThingWentWrong from "../features/SomethingWentWrong/SWWrongPage";
import LoginScreen from "../features/loginScreen/loginComponents/loginScreen.js";
import VerifyOTP from "../features/SignUpComponents/verifyOtpComp/verifyOtp";
import SingnUpForm from "../features/SignUpComponents/userSingUp/signUpContainer";
import CheckEmailAddress from "../features/SignUpComponents/letsGetStart/checkEmailAddress";
import Forgotpassword from "../features/loginScreen/frgtPasword/forgotPassword";
import ViewEventImages from "../features/ViewEventImages/ViewEventImages";
import AlbumList from "../features/albumList/AlbumList";
import DownloadAll from "../features/downloadAll/downloadall.js";
import NewPassword from "../features/loginScreen/frgtPasword/newPassword";
import * as RoutesName from "./constantRoutes";
import AboutUs from "../components/aboutus/AboutUs.js";
import Faq from "../components/faq/Fag.js";

import LoadingScreen from "../components/common/LoadingScreen.js";

const Index = () => {
  const { isAuth } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const authCookie = Cookies.get("isauthenticated") === "true";
      dispatch(ChangeUserAuth(authCookie));
      setLoading(false);
    };

    checkAuth();
  }, [dispatch]);

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <LoadingScreen />;
    }
    if (!isAuth) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  const PublicRoute = ({ children }) => {
    if (loading) {
      return <LoadingScreen />;
    }
    if (isAuth) {
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/photos" element={<ViewPhoto />} />
        <Route path="/view-photos" element={<ViewEventImages />} />
        <Route path="/search-photos" element={<Selfie />} />
        <Route path="/event-gallery" element={<EventGallery />} />
        <Route path="/your-photos" element={<YourPhotos />} />
        <Route path="*" element={<SomeThingWentWrong />} />
        <Route
          path={RoutesName.loginScreenPath}
          element={
            <PublicRoute>
              <LoginScreen />
            </PublicRoute>
          }
        />
        <Route
          path="/signUpForm"
          element={
            <PublicRoute>
              <SingnUpForm />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <Forgotpassword />
            </PublicRoute>
          }
        />
        <Route
          path={RoutesName.downloadPath}
          element={<DownloadAll />}
        />
        <Route
          path="/new-password"
          element={
            <PublicRoute>
              <NewPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/signUp"
          element={
            <PublicRoute>
              <CheckEmailAddress />
            </PublicRoute>
          }
        />
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/faq" element={<Faq />} />
        <Route path="/pricing" element={<Price />} /> */}

        <Route
          path="/create-event"
          element={
            <ProtectedRoute>
              <CreateEvent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/events"
          element={
            <ProtectedRoute>
              <Events />
            </ProtectedRoute>
          }
        />
        <Route
          path="/event-setting"
          element={
            <ProtectedRoute>
              <EventSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/albums"
          element={
            <ProtectedRoute>
              <Albums />
            </ProtectedRoute>
          }
        />
        <Route
          path="/album-list"
          element={
            <ProtectedRoute>
              <AlbumList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-album"
          element={
            <ProtectedRoute>
              <CreateAlbum />
            </ProtectedRoute>
          }
        />
        <Route
          path="/album-setting"
          element={
            <ProtectedRoute>
              <AlbumSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-photos"
          element={
            <ProtectedRoute>
              <UploadPhotos />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default Index;