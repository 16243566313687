import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { dEV_API_URL } from "../../API";

const initialState = {
  searched_face_id: null,
  loading: false,
  error: null,
  resp: null,
};

export const postData = createAsyncThunk(
  "index/postData",
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${dEV_API_URL}/recognition/search-face/`,
        formData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const selfieReducer = createSlice({
  name: "uploadselfie",
  initialState,
  reducers: {
    ChangeSelfieError: (state, action) => {
      state.error = null;
    },
    ChangeSelfieResp: (state, action) => {
      state.resp = null;
    },
    ResetSearchedFaceId: (state, action) => {
      state.searched_face_id=null;
  }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postData.fulfilled, (state, action) => {
        state.loading = false;
        state.resp = action.payload;
        state.searched_face_id = action.payload.searched_face_id;
      })
      .addCase(postData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { ChangeSelfieError, ChangeSelfieResp,ResetSearchedFaceId } = selfieReducer.actions;
export default selfieReducer.reducer;
