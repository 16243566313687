import React from "react";
import "../../components/homepage/gallery2.css";
import Rectangle1 from "../../assets/homepage/rectangle1.png";
import Rectangle2 from "../../assets/homepage/rectangle2.png";
import Rectangle3 from "../../assets/homepage/rectangle3.png";
import Rectangle4 from "../../assets/homepage/rectangle4.png";
import Rectangle5 from "../../assets/homepage/rectangle5.png";
import Rectangle6 from "../../assets/homepage/rectangle6.png";
import Rectangle7 from "../../assets/homepage/rectangle7.png";
// import Rectangle8 from "../../assets/homepage/rectangle8.png";
import Rectangle9 from "../../assets/homepage/rectangle9.png";
import Slider from "react-slick";

const Gallery2 = () => {
  const ImgData = [
    Rectangle1,
    Rectangle2,
    Rectangle7,

    Rectangle4,
    Rectangle9,
    Rectangle5,
    Rectangle6,

    Rectangle1,
    Rectangle2,
    Rectangle3,
    Rectangle4,
    Rectangle5,
  ];

  var settings = {
    initialSlide: 0,
    slidesToShow: 5,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    draggable: false, 

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <div className="gallery_main_wrapper">
        <div className="gallery_main_overlay"></div>
        <div className="gallery_main_upper_curve"> </div>

        <div className="gallery_container">
          <Slider {...settings}>
            {ImgData.map((item) => {
              return (
                <div className="gallery_container_img_div">
                  <img src={item} alt="img" />
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="gallery_main_bottom_curve"> </div>
      </div>
    </>
  );
};

export default Gallery2;
