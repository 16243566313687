import React from "react";
import "../termsConditions/termsConditions.css";
import Modal from "@mui/material/Modal";
import CrossImg from "../../assets/events/cross.svg";

const TermsConditions = ({ tcModal, setTCModal, handleClose }) => {
  return (
    <>
      <Modal
        className="pp_container"
        open={tcModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pp_modal_card">
          <div className="pp_modal_card_header">
            <img onClick={handleClose} src={CrossImg} alt="icon" />
          </div>

          <div className="pp_content">
            <div className="pp_content_header">
              <p className="pp_heading">TERMS OF USE</p>
            </div>
            <p className="pp_content_p1">
              These Terms of Use are executed between you and Specatcom Global
              Private Limited (hereinafter referred to as{" "}
              <strong>"Spectacom"</strong>, <strong>"we"</strong>,{" "}
              <strong>"our"</strong> or <strong>"us"</strong>) and govern the
              usage or access of our Services and Platform (both, as defined
              hereinafter) and any other features we offer.
            </p>
            <p className="pp_content_p1">
              <strong>"Users"</strong> constitute distinct, single individuals
              and/or entities accessing or using the Platform for any reason,
              whom may hereinafter also be referred to as <strong>"you"</strong>
              , <strong>"your"</strong>, <strong>"yourself"</strong> or
              "yourselves". You and Spectacom may be referred to individually as
              a <strong>"Party"</strong> and jointly as the{" "}
              <strong>"Parties"</strong>.
            </p>
            <p className="pp_content_p1">
              By downloading, accessing and using the Platform, through any
              device, you agree to our Terms of Use (
              <strong>"Terms of Use"</strong>). These Terms of Use constitute a
              binding agreement between you and Spectacom and its affiliates and
              subsidiaries. By accessing, downloading, using and/or registering
              for the Platform and/or Services, you understand and agree to be
              bound by these Terms of Use, including any modifications or
              amendments made thereto by Spectacom from time to time, as its
              sole discretion. The Terms of Use and Privacy Policy shall be
              collectively referred to as
              <strong>"Spectacom Policies"</strong>. If you do not agree to
              these Spectacom Policies, you are not authorized to access or use
              the Platform and/or Services.
            </p>
            <p className="pp_content_p1">
              By accepting these Terms of Use, you represent that you have the
              capacity to enter into or, if you are acting on behalf of an
              entity, that you have the authority to bind such entity to a
              legally binding contract, and you agree that these Terms of Use
              legally bind you or the entity on behalf of which you purport to
              act, in the same manner as a signed, written, paper contract.
              These Terms of Use constitute an electronic record within the
              meaning of the Applicable Law (as defined hereinafter). This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures. Accessing, browsing
              and/or using the Services and Platform or using any of the
              information provided therein shall be deemed to signify the User's
              (as defined hereinafter) unequivocal acceptance of these Terms of
              Use. The User expressly acknowledges and agrees to be bound by the
              Terms of Use, regardless of however the User or anyone on the
              User's behalf has accessed, installed, downloaded, or used the
              Services and whether or not the User has registered on the
              Platform.
            </p>
            <p className="pp_content_p1">
              <strong>
                BY DOWNLOADING/INSTALLING, ACCESSING OR USING THE PLATFORMAND/OR
                SERVICES, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD
                THESE TERMS OF USE; (B) REPRESENT THAT YOU ARE AT LEAST 18
                (EIGHTEEN) YEARS OF AGE OR THE REQUIRED LEGAL AGE IN YOUR
                JURISDICTION; (C) ACCEPT THESE TERMS AND AGREE THAT YOU ARE
                LEGALLY BOUND BY THEM; AND (D) AGREE THAT ANY AND ALL DISPUTES
                THAT MAY ARISE BETWEEN THE PARTIES FROM YOUR USE OF THE PLATFORM
                AND/OR SERVICES SHALL BE RESOLVED THROUGH INDIVIDUAL ARBITRATION
                AND NOT IN COURT AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN
                THE EVENT OF A DISPUTE. IF YOU DO NOT AGREE TO THESE TERMS OF
                USE, DO NOT DOWNLOAD, INSTALL OR USE PLATFORM AND/OR SERVICES
                AND DELETE ANY AND EVERY
              </strong>
            </p>
            <p className="pp_content_p1">
              We reserve the right to update or modify these Terms of Use and
              notify you of any such modifications. Your use of the Platform
              following any such change constitutes your unconditional agreement
              to follow and be bound by these Terms as changed. For this reason,
              we encourage you to review these Terms whenever you use the
              Platform.
            </p>{" "}
            <br />
            <div className="pp_ol_card">
              <p>1</p>
              <p className="pp_ol_li">
                <strong>DEFINITIONS</strong> <br /> <br />
                For the purposes of these Terms, the following definitions shall
                have the meaning set forth below:
              </p>
            </div>
            <p className="pp_content_p1">
              <strong>"Applicable Laws"</strong> shall mean any and all: (a)
              laws, statutes, constitutions, treaties, rules, 2 regulations,
              ordinances, codes, guidance, and common law; and (b) all judicial,
              executive, legislative, administrative or military orders,
              directives, decrees, injunctions, judgments, permits, agreements,
              and other legal requirements, in each case, of, with, or adopted
              or imposed by any Governmental Authority (as defined hereinafter),
              now or hereafter in effect and, in each case, as amended from time
              to time.
            </p>
            <p className="pp_content_p1">
              <strong>"Content"</strong> shall mean all information that is
              created and stored on the Platform, including photos and other
              materials and information, including the Services and includes any
              other information made available by us on or through the Platform,
              including proprietary content and any content licensed or
              authorized for use by or through Spectacom from a Third Party (as
              defined hereinafter).{" "}
            </p>
            <p className="pp_content_p1">
              <strong>"Data Protection Law"</strong> shall mean any data
              protection, data security or privacy law, including, without
              limitation, the Information Technology Act, 2000 including the
              rules thereunder (<strong>"IT Act and Rules"</strong>), the
              Digital Personal Data Protection Act, 2023 (when in force), the EU
              General Data Protection Regulation 2016/679 (the{" "}
              <strong>"GDPR"</strong>) and any laws governing Personal Data,
              Sensitive Personal Data or information from outbound telephone
              calls, transmission of electronic mail, transmission of facsimile
              messages and any other communication-related data protection, data
              security or privacy laws. <br /> <br />
              <strong>"Governmental Authority"</strong> shall mean any
              government authority, statutory authority, regulatory authority,
              government department, agency, commission, board, tribunal or
              court or other law, rule or regulation making entity/ authority
              having or purporting to have jurisdiction on behalf of the
              Republic of India or any state or other subdivision thereof or any
              municipality, district or other subdivision thereof.
              <p className="pp_content_p1"></p>
              <strong>"Intellectual Property"</strong> or <strong>"IP"</strong>{" "}
              includes ideas, concepts, creations, discoveries, inventions,
              improvements, know how, trade or business secrets; trademarks,
              service marks, designs, utility models, tools, devices, models,
              methods, procedures, processes, systems, principles, synthesis
              protocol, algorithms, works of authorship, flowcharts, drawings,
              books, papers, models, sketches, formulas, proprietary techniques,
              research projects, copyright, designs, and other confidential and
              proprietary information, databases, data, documents, instruction
              manuals, records, memoranda, notes, user guides, in either printed
              or machine-readable form, whether or not copyrightable or
              patentable or protectable under any other intellectual property
              law, or any written or verbal instructions or comments.{" "}
            </p>
            <p className="pp_content_p1">
              <strong>"Intellectual Property Rights"</strong> or{" "}
              <strong>"IP Rights"</strong> include: (a) all rights, title, and
              interest under any statute or under Applicable Law including
              patent rights; copyrights including moral rights; and any similar
              rights in respect of the Intellectual Property, anywhere in the
              world, whether negotiable or not; (b) any licenses, permissions
              and grants in connection therewith; (c) applications for any of
              the foregoing and the right to apply for them in any part of the
              world; (d) right to obtain and hold appropriate registrations in
              Intellectual Property anywhere in the world; (e) all extensions
              and renewals thereof; and (f) causes of action in the past,
              present or future, related thereto including the rights to damages
              and profits, due or accrued, arising out of past, present or
              future infringements or violations thereof and the right to sue
              for and recover the same. <br /> <br />
              <strong>"Loss"</strong> shall mean all direct and actual losses,
              damages, liabilities, costs (including legal fees), expenses,
              charges, interest, penalty, claims, arbitration, proceedings,
              suits and all sums paid in relation to any compromise or
              settlement of any claim, arbitration, suit or proceeding.
            </p>
            <p className="pp_content_p1">
              <strong>"Platform"</strong> shall mean use of the
              https://www.snapd.me/ website as well as any other media form,
              media channel, mobile website or mobile application related,
              linked, or otherwise connected thereto but does not include any
              website or application owned or operated by a Third Party that may
              be accessed from any page powered by Spectacom.{" "}
            </p>
            <p className="pp_content_p1">
              <strong>"Personal Data"</strong> shall mean any personally
              identifiable information relating to an identified or identifiable
              individual, including data that identifies an individual or that
              could be used to identify, locate, track, or contact an
              individual. Personal Data includes both directly identifiable
              information, such as name, identification number or unique job
              title, and indirectly identifiable information such as date of
              birth, unique mobile or wearable device identifier, information
              that could be used to identify a household, telephone number,
              key-coded data or online identifiers, such as IP addresses, and
              includes any data that constitutes "personal data" or "sensitive
              personal data or information" under the GDPR, IT Act and Rules or
              similar terms under other Data Protection Law.{" "}
            </p>
            <p className="pp_content_p1">
              <strong>"Privacy Policy"</strong> shall mean Spectacom's privacy
              policy available at [.]. <br /> <br />
              <strong>"Sensitive Personal Data"</strong> or{" "}
              <strong>"Sensitive Personal Information"</strong> with respect to
              a person shall mean such personal information which consists of
              information relating to:
            </p>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">biometric information;</p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">health data;</p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">sex life;</p>
            </div>
            <div className="pp_ol_card">
              <p>(d)</p>
              <p className="pp_ol_li">religious or political beliefs;</p>
            </div>
            <div className="pp_ol_card">
              <p>(d)</p>
              <p className="pp_ol_li">official identifiers;</p>
            </div>
            <div className="pp_ol_card">
              <p>(e)</p>
              <p className="pp_ol_li">biometric data;</p>
            </div>
            <div className="pp_ol_card">
              <p>(f)</p>
              <p className="pp_ol_li">
                physical, physiological and mental health condition;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(g)</p>
              <p className="pp_ol_li">sexual orientation;</p>
            </div>
            <div className="pp_ol_card">
              <p>(h)</p>
              <p className="pp_ol_li">
                any detail relating to the above clauses as provided to us for
                providing the Services; and
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(i)</p>
              <p className="pp_ol_li">
                any of the information received under above clauses by us for
                processing, stored or processed under lawful contract or
                otherwise;
              </p>
            </div>
            <p className="pp_content_p1">
              <strong>"Services"</strong>
              shall mean any services made available by Spectacom to the Users
              on the Platform;
            </p>
            <p className="pp_content_p1">
              <strong>"Snapd Account"</strong>
              shall mean the account created and registered on the Platform by
              the User.
            </p>
            <p className="pp_content_p1">
              <strong>"Third Party"</strong>
              shall mean a party which is not a signatory to these Terms of Use.
            </p>
            <div className="pp_ol_card">
              <p>2.</p>
              <p className="pp_ol_li">
                <strong>ELIGIBILITY</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>2.1</p>
              <p className="pp_ol_li">
                By checking any acceptance boxes, clicking any acceptance
                buttons, submitting any text or Content, or simply by browsing,
                accessing or using the Platform, you: (i) accept the Terms of
                Use and agree to be bound by each of its terms; and (ii)
                represent and warrant to Spectacom that: (a) these Terms of Use
                are binding and enforceable against you; (b) you are 18
                (eighteen) years of age or older than the age prescribed in your
                country of residence for a child; and (c) you have read and
                understand our Spectacom Policies, the terms of which are
                incorporated herein by reference, and agree to abide by the
                Spectacom Policies. These Terms of Use are made between you and
                us and shall come into effect on the date which you first access
                the Platform and/or create your Snapd Account, whichever is
                earlier, and shall continue until terminated by us or you.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>2.2</p>
              <p className="pp_ol_li">
                oIn the event that the User or anyone acting on the User's
                behalf does not wish to be bound by the Terms of Use, the User
                (or the legal person/entity acting on the User's behalf)
                unequivocally agrees to refrain from accessing, using or
                retaining the Services and/or Platform on any device in any
                manner whatsoever. The User agrees that anything done or caused
                to be done by the User or anyone acting on the User’s behalf,
                whether expressly or impliedly that is in contravention of the
                Terms of Use will render the User liable for legal and punitive
                action.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>2.3</p>
              <p className="pp_ol_li">
                Spectacom reserves the right to terminate your access and refuse
                to provide you with access to the Platform if Spectacom
                discovers that you are under the age of 18 (eighteen) years or
                the required legal age in your jurisdiction. You further
                represent and warrant that you are not under any legal or other
                deficiency which prevents/may prevent you from: (i) entering
                into a valid contract under the Applicable Laws; and (ii) making
                valid payment to Spectacom for Services availed by you.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>2.4</p>
              <p className="pp_ol_li">
                You are responsible for, and agree to comply with, all
                Applicable Laws, rules, and regulations applicable to your use
                of the Services and Platform, and any transaction you enter into
                on the Platform or in connection with your use of the Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>3.</p>
              <p className="pp_ol_li">
                <strong>ACCEPTANCE OF TERMS</strong> <br /> <br />
                These Terms of Use form an electronic contract that establishes
                legally binding terms that the User must accept to use the
                Services provided by Spectacon through the Platform. These Terms
                of Use include by reference other terms disclosed and agreed to
                by the User in the event the User registers for, purchases, or
                accepts additional features, products or services in addition to
                the Services, including but not limited to terms governing
                features, billing, discounts, promotions, etc.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.</p>
              <p className="pp_ol_li">
                <strong>ACCOUNT REGISTRATION</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.1</p>
              <p className="pp_ol_li">
                You may access the Platform and view certain Content and/or
                Services without registering but as a pre-condition to utilize
                our Services, you must create a Snapd Account on the Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.2</p>
              <p className="pp_ol_li">
                At the time of registration, you shall ensure that the Snapd
                Account information (including, but not limited to your
                username, e-mail address and password) provided by you in the
                registration form is complete, accurate and up to date. Users
                may also be requested to provide supporting documents as
                specified by Spectacom in accordance with Applicable Laws. It
                shall be the responsibility of the Users to ensure the
                authenticity and validity of these supporting documents.
                Spectacom may use a Third Party service provider to verify and
                authenticate the Snapd Account information and documents
                provided (if any) by you.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.3</p>
              <p className="pp_ol_li">
                You will, at all times, be responsible for maintaining the
                confidentiality of your Snapd Account information and are fully
                responsible for all activities that occur under your Snapd
                Account. You agree to: (i) immediately notify Spectacom of any
                unauthorized use of your Snapd Account information or any other
                breach of security; and (ii) ensure that you exit/log out from
                your Snapd Account at the end of each session. Spectacom cannot
                and shall not be liable for any Loss or damage arising from your
                failure to comply with this Clause. You may be held liable for
                Losses incurred by Spectacom or any other User of the Platform
                due to authorized or unauthorized use of your Snapd Account as a
                result of your failure to keep your Snapd Account information
                secure and confidential. The use of another User's Snapd Account
                is expressly prohibited.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.4</p>
              <p className="pp_ol_li">
                By creating a Snapd Account, using our Services, and/or
                accessing the Platform, you grant us a license to collect, use,
                copy, transmit, store and back-up your Personal Data for
                purposes of providing the Services and/or for any other
                purpose(s) as contemplated by the Terms and/or Privacy Policy.
                All Personal Data and Sensitive Personal Data provided to us by
                you shall be governed by the Privacy Policy.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.5</p>
              <p className="pp_ol_li">
                We shall not charge any fees for creating and registering a
                Snapd Account on the Platform. However, please note that this is
                subject to change and Spectacom shall, at its sole discretion,
                have the right to charge Users any fee that it may deem
                appropriate by amending these Terms of Use.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>5.</p>
              <p className="pp_ol_li">
                <strong>TERM & TERMINATION</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>5.1</p>
              <p style={{ textDecoration: "underline" }} className="pp_ol_li">
                Term.
              </p>
            </div>
            <p className="pp_content_p1">
              These Terms of Use will come into effect from the date that you
              access or browse the Platform and shall remain in effect until
              terminated in accordance with Clause 5.2 below.
            </p>
            <div className="pp_ol_card">
              <p>5.1</p>
              <p style={{ textDecoration: "underline" }} className="pp_ol_li">
                Termination and/or Suspension.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(i)</p>
              <p className="pp_ol_li">
                We reserve the right, at our sole discretion, to suspend,
                deactivate or cancel your Snapd Account and/or access to our
                Platform and/or Services, without providing prior notice to you,
                if you: (a) have breached the terms and conditions of the Terms;
                (b) have provided any information during the registration
                process or thereafter which is proved to be inaccurate, not
                current or incomplete; (c) have carried out unauthorized use of
                the Services and/or Platform; or (d) have used the Services
                and/or Platform in a manner that violates any Applicable Laws or
                which threatens the security or otherwise harms Spectacom or its
                affiliates, personnel or other Users of the Platform and/or
                Services, and Third Parties. If you wish to enquire about the
                suspension, deactivation or cancellation of your Snapd Account
                or believe that a mistake has been made, you may email us with
                your query on info@snapd.me.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(ii)</p>
              <p className="pp_ol_li">
                The above rights of Spectacom are without prejudice to
                Spectacom's right to pursue any other legal action/remedies
                available to us.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(iii)</p>
              <p className="pp_ol_li">
                You may terminate your Snapd Account at any time by sending an
                email to info@snapd.me.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(iv)</p>
              <p className="pp_ol_li">
                Notwithstanding any other provisions of the Terms of Use, we
                reserve the right to suspend or discontinue the Services and
                Platform, either temporarily or permanently and either wholly or
                in part, for the purposes of carrying out maintenance or
                otherwise, without prior notice. In such a case, we will attempt
                to inform you of any suspension, discontinuation, or deletion of
                the Services and/or Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(v)</p>
              <p className="pp_ol_li">
                The provisions of these Terms that are, by their nature,
                intended to survive termination (including, without limitation
                Clause 7 (Ownership & Rights), Clause 10 (Confidentiality),
                Clause 11 (Data Protection and Privacy), Clause 12 (Indemnity),
                Clause 13 (Disclaimers), Clause 14 (Limitation of Liability),
                Clause 18 (Governing Law and Jurisdiction), Clause 19 (Dispute
                Resolution), and Clause 20 (Miscellaneous)) will continue to
                apply to you with full force and effect notwithstanding any
                termination of the Terms of Use.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.</p>
              <p className="pp_ol_li">
                <strong>USER CONDUCT</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.1</p>
              <p className="pp_ol_li">
                You understand and agree that you are solely responsible for
                compliance with Applicable Laws to your use of the Platform and
                our Services. In connection with your use of the Platform and
                our Services, you agree and undertake that you will not:
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(i)</p>
              <p className="pp_ol_li">violate any Applicable Laws;</p>
            </div>
            <div className="pp_ol_card">
              <p>(ii)</p>
              <p className="pp_ol_li">
                through any act or omission, threaten the unity, integrity,
                defense, security or sovereignty of any state, friendly
                relations with foreign states, or incite anyone to commit of any
                offence or crime or prevent the investigation of any offence or
                crime;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(iii)</p>
              <p className="pp_ol_li">
                use manual or automated software, devices, scripts robots, other
                means or processes to access, "scrape," "crawl" or "spider" any
                web pages or other services contained in the Platform or other
                Services;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(iv)</p>
              <p className="pp_ol_li">
                misuse the Platform and/or other Services for any purpose that
                is prohibited by these Terms and/or the Privacy Policy;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(v)</p>
              <p className="pp_ol_li">
                copy, store or otherwise access any information contained in the
                Platform or other Services for purposes not expressly permitted
                by these Terms and/or the Privacy Policy;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(vi)</p>
              <p className="pp_ol_li">
                infringe the rights of any person or entity, including without
                limitation, their IP, privacy, publicity or contractual rights;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(vii)</p>
              <p className="pp_ol_li">
                interfere with or cause damage to the Platform and/or other
                Services, including, without limitation, through the use of
                viruses, cancel bots, Trojan horses, harmful code, flood pings,
                denial-of-service attacks, packet or internet protocol spoofing,
                forged routing or electronic mail address information or similar
                methods or technology;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(viii)</p>
              <p className="pp_ol_li">
                attempt to interfere with the usage of the Platform and/or other
                Services provided to any User, host or network, including,
                without limitation, via means of submitting a virus to the
                Platform, overloading, "flooding," "spamming," "mailbombing" or
                "crashing";
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(ix)</p>
              <p className="pp_ol_li">
                register for more than one Snapd Account or register for a Snapd
                Account on behalf of an individual other than yourself without
                being authorized to do so;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(x)</p>
              <p className="pp_ol_li">
                impersonate any person or entity, or falsify or otherwise
                misrepresent yourself or your affiliation with any person or
                entity;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xi)</p>
              <p className="pp_ol_li">
                use automated scripts to collect information or otherwise
                interact with the Platform and/or other Services;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xii)</p>
              <p className="pp_ol_li">
                systematically retrieve, copy or download data or other content
                from the Platform and/or other Services to create or compile,
                directly or indirectly, in single or multiple downloads, a
                collection, compilation, database, directory or the like,
                whether by manual methods, through the use of buying/purchasing
                agents, bots, crawlers, or spiders, or otherwise, either for
                your benefit or for the benefit of any other persons;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xiii)</p>
              <p className="pp_ol_li">
                use, display, mirror or frame the Platform or any individual
                element within the Platform and/or other Services, Spectacom's
                name, any Spectacom Intellectual Property, or the layout and
                design of any page or form contained on a page, without
                Spectacom's express written consent;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xiv)</p>
              <p className="pp_ol_li">
                access, tamper with, or use non-public areas of the Platform,
                Spectacom's computer systems, or the technical delivery systems
                of Spectacom's providers;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xv)</p>
              <p className="pp_ol_li">
                attempt to probe, scan, or test the vulnerability of any
                Spectacom system or network or breach any security or
                authentication measures;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xvi)</p>
              <p className="pp_ol_li">
                avoid, bypass, remove, deactivate, impair, descramble, or
                otherwise circumvent any technological measure implemented by
                Spectacom or any of Spectacom's providers or any other Third
                Party (including another User) to protect the Platform and/or
                any Service;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xvii)</p>
              <p className="pp_ol_li">
                forge any TCP/IP packet header or any part of the header
                information in any email or newsgroup posting, or in any way use
                the Platform and/or any Service and/or content to send altered,
                deceptive or false source-identifying information;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xviii)</p>
              <p className="pp_ol_li">
                attempt to decipher, decompile, disassemble or reverse engineer
                any of the software used to operate and enable the Platform
                and/or any Service;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xix)</p>
              <p className="pp_ol_li">
                advocate, encourage, or assist any Third Party in doing any of
                the foregoing;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xx)</p>
              <p className="pp_ol_li">
                use the Platform to advertise or offer to sell goods and
                services;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xxi)</p>
              <p className="pp_ol_li">
                sell or otherwise transfer your Snapd Account;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xxii)</p>
              <p className="pp_ol_li">
                Delete the copyright or other Intellectual Property Rights
                notice from any Content; or
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(xxiii)</p>
              <p className="pp_ol_li">
                disable or circumvent, or attempt to disable or circumvent, any
                technological mechanisms or measures intended to prevent, limit
                or control use or copying of, or access to, Platform and/or any
                Service.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.2</p>
              <p className="pp_ol_li">
                Spectacom reserves the right to terminate a Snapd Account and/or
                prevent a User from availing Spectacom's Services if a User is
                non-compliant with any of the abovementioned obligations. In
                such case, Spectacom may also notify an appropriate government
                or legal authority or its agency and provide assistance for the
                purpose of investigation, prevention or detection of any
                offences under any Applicable Law.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.3</p>
              <p className="pp_ol_li">
                Spectacom will have the right to investigate and prosecute
                violations of any of the above and pursue any and all legal
                remedies available as per Applicable Law. Spectacom may involve
                and cooperate with the relevant law enforcement authorities in
                prosecuting Users who violate these Terms of Use or any
                Applicable Law. You acknowledge that Spectacom has no obligation
                to monitor your access to or use of the Platform or Services,
                but has the right to do so for the purpose of operating the
                Platform and Services: (i) to ensure your compliance with these
                Terms of Use, including investigation of potential violations
                hereof; (ii) to comply with Applicable Law or the order or
                requirement of a court, administrative agency or other
                governmental body; (iii) detect, prevent, or otherwise address
                fraud, security or technical issues; and (iv) respond to User
                support requests; or (v) protect our rights, property or safety
                of our Users and the public.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>7.</p>
              <p className="pp_ol_li">
                <strong>OWNERSHIP & RIGHTS</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>7.1</p>
              <p style={{ textDecoration: "underline" }} className="pp_ol_li">
                Data Usage & Retention.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(i)</p>
              <p className="pp_ol_li">
                The Personal Data that you provide to us or otherwise authorize
                us to have access to is your property.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(ii)</p>
              <p className="pp_ol_li">
                By using our Services, and/or accessing the Platform, you grant
                us a license to collect, use, copy, transmit, store and back-up
                your Personal Data for purposes of providing Services and/or for
                any other purpose(s) as contemplated by the Terms of Use and/or
                Privacy Policy.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(iii)</p>
              <p className="pp_ol_li">
                All Personal Data and Sensitive Personal Data provided to us by
                you shall be governed by the Privacy Policy.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>7.2</p>
              <p style={{ textDecoration: "underline" }} className="pp_ol_li">
                Intellectual Property.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(i)</p>
              <p className="pp_ol_li">
                You acknowledge and agree that Spectacom owns and reserves the
                right, title and interest in and to the Services, Platform, and
                Spectacom Content.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(ii)</p>
              <p className="pp_ol_li">
                As part of the Services, we grant you a non-exclusive, limited,
                royalty-free, revocable license, during the term of the Terms of
                Use, to use our Platform to facilitate your use of the Services.
                Any rights relating to our Platform and Spectacom Content that
                we do not expressly grant to you in writing are expressly
                reserved, and your access to and use of our Platform does not
                grant you an express or implied license in respect of any of the
                Intellectual Property Rights that are owned by, licensed to, or
                controlled by us and our licensees.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(iii)</p>
              <p className="pp_ol_li">
                By using our Services, and/or submitting any Content (
                <strong>"User Content"</strong>) on the Platform, you grant us a
                unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
                transferable, royalty- 8 free, fully-paid, worldwide
                rightlicense to collect, use, copy, transmit, store and back-up
                your User Content (including, without limitation, your image and
                voice) for purposes of providing Services and/or for any other
                purpose(s) as contemplated by the Terms of Use and/or Privacy
                Policy.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(iv)</p>
              <p className="pp_ol_li">
                You agree to indemnify and hold harmless Spectacom from any
                Losses that may arise on account of violation of this Clause 7.2
                by you or any person on whose behalf you may be acting.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(v)</p>
              <p className="pp_ol_li">
                You acknowledge and agree that the 'Snapd' logo is our trademark
                and may not be used by you without our prior written consent.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(vi)</p>
              <p className="pp_ol_li">
                Any distribution, reprint or electronic reproduction of any
                Content from the Platform and/or Services, in whole or in part,
                is strictly prohibited without our prior written consent.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(vii)</p>
              <p className="pp_ol_li">
                You acknowledge and agree that you shall not copy, modify,
                transmit, create any derivative works from, make use of or
                reproduce in any way any copyrighted material, trademarks, trade
                names, service marks or other Intellectual Property or
                proprietary information accessible through the Services and/or
                Platform, without first obtaining the prior written consent of
                Spectacom.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>8.</p>
              <p className="pp_ol_li">
                <strong>FEEDBACK</strong> <br /> <br />
                You agree that submission of any ideas, suggestions, documents,
                and/or proposals to Spectacom through its suggestion, feedback,
                wiki, forum or similar pages ("Feedback") is at your own risk
                and that Spectacom has no obligations (including without
                limitation obligations of confidentiality) with respect to such
                Feedback. You represent and warrant that you have all rights
                necessary to submit the Feedback. You hereby grant to us a fully
                paid, royalty-free, perpetual, irrevocable, worldwide,
                non-exclusive, and fully sublicensable right and license to use,
                reproduce, perform, display, distribute, adapt, modify,
                re-format, create derivative works of, and otherwise
                commercially or non-commercially exploit in any manner, any and
                all Feedback, and to sublicense the foregoing rights, in
                connection with the operation and maintenance of the Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>9.</p>
              <p className="pp_ol_li">
                <strong>NO OBLIGATION TO PRE-SCREEN CONTENT</strong> <br />{" "}
                <br />
                You acknowledge that the Spectacom has no obligation to
                pre-screen information, data, text, music, sound, photographs,
                videos and other materials available on and through the Platform
                by third parties (<strong>"Third Party Content"</strong>),
                although Spectacom reserves the right in its sole discretion to
                pre-screen, refuse or remove any Third Party Content. In the
                event that Spectacom pre-screens, refuses or removes any Third
                Party Content, you acknowledge that Spectacom will do so for
                Spectacom's benefit, not yours. Without limiting the foregoing,
                Spectacom shall have the right to remove any Third Party Content
                that violates the Terms or is otherwise objectionable.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>10.</p>
              <p className="pp_ol_li">
                <strong>CONFIDENTIALITY</strong> <br /> <br />
                During the course of your use of our Services and/or Platform
                you may receive information relating to us or to our Services,
                that is not known to the general public (
                <strong>"Confidential Information"</strong>). You agree that:
                (i) all Confidential Information will remain Spectacom's
                exclusive property; (ii) you will use Confidential Information
                only as is reasonably necessary for your participation in our
                Services and use of the Platform; (iii) you will not otherwise
                disclose Confidential Information to any other person or Third
                Party; and (iv) you will take all reasonable measures to protect
                the Confidential Information against any use or disclosure that
                is not expressly permitted in these Terms and/or Privacy Policy.
                You may not issue any press release or make any public statement
                related to our Services, or use our name, trademarks, or logo,
                in any way (including in promotional material) without our
                advance written permission or misrepresent or embellish the
                relationship between us in any way.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>11.</p>
              <p className="pp_ol_li">
                <strong>DATA PROTECTION AND PRIVACY</strong> <br /> <br />
                You agree that we shall have the right to collect and/or use or
                analyse your Personal Data or Sensitive Personal Data as set
                forth in the Privacy Policy. Use of the Services and Platform is
                also governed by the Privacy Policy. We only use your
                information as described in the Privacy Policy. We view
                protection of Users' privacy as a very important community
                principle. If you object to the Privacy Policy in any way,
                please do not use the Services and the Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>12.</p>
              <p className="pp_ol_li">
                <strong>INDEMNITY</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>12.1</p>
              <p className="pp_ol_li">
                You agree and undertake to fully indemnify and hold Spectacom,
                its affiliates, and their respective officers, directors,
                employees, successors, representatives, and agents harmless from
                and against all Losses howsoever arising from all claims,
                allegations, actions, proceedings, demands, or costs brought by
                a Third Party or other liability or expenses (including, without
                limitation, attorneys' fees) (each, a <strong>"Claim"</strong>)
                that we may sustain or incur, directly or indirectly, arising
                from or as a result of your: (i) actual or alleged breach of the
                Terms of Use and/or Privacy Policy; (ii) use of the Platform;
                (iii) access to, use, or misuse or abuse of any Service(s)
                offered through, our Platform; (iv) misconduct in any manner,
                including negligence and fraud, in connection with your use of
                our Platform or any of the Services; or (v) any actual or
                alleged infringement of any Intellectual Property Rights by you
                or any person on whose{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>12.2</p>
              <p className="pp_ol_li">
                We will notify you of any such Claim or proceeding and assist
                you, at your expense, in defending the same. We reserve the
                right to assume, at your expense, the exclusive control and
                defense of any matter that is or may be subject to
                indemnification under this section. Should we exercise this
                right, you nevertheless agree to cooperate with any reasonable
                requests we make of you to assist with our defense of such
                matter.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>13.</p>
              <p className="pp_ol_li">
                <strong>DISCLAIMERS</strong>{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>13.1</p>
              <p className="pp_ol_li">
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED
                BY APPLICABLE LAW, YOUR USE OF THE PLATFORM AND/OR SERVICES IS
                AT YOUR SOLE RISK, AND THE PLATFORM AND/OR SERVICES ARE PROVIDED
                ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITH ALL FAULTS.
                SPECTACOM EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS,
                AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR
                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
                AND NON- INFRINGEMENT. SPECTACOM MAKE NO WARRANTY,
                REPRESENTATION OR CONDITION THAT: (A) THE PLATFORM AND/OR
                SERVICES WILL MEET YOUR REQUIREMENTS; (B) YOUR USE OF THE
                PLATFORM AND/OR SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE
                OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM USE OF
                THE PLATFROM WILL BE ACCURATE OR RELIABLE; OR (D) ANY ERRORS IN
                THE CONTENT WILL BE CORRECTED. THE SERVICES MAY BE SUBJECT TO
                DELAYS, CANCELLATIONS AND OTHER DISRUPTIONS. NO ADVICE OR
                INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM SPECTACOM OR
                THROUGH THE PLATFORM AND/OR SERVICES WILL CREATE ANY WARRANTY
                NOT EXPRESSLY MADE HEREIN.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>13.1</p>
              <p className="pp_ol_li">
                These limitations, disclaimer of warranties and exclusions apply
                without regard to whether the damages arise from: (i) breach of
                contract; (ii) breach of warranty; (iii) negligence; or (iv) any
                other cause of action, to the extent such exclusion and
                limitations are not prohibited by Applicable Law.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>13.2</p>
              <p className="pp_ol_li">
                You are responsible for reviewing and verifying all such Content
                and/or analysis and promptly informing us of any errors noted in
                writing. Subject to that, we will take steps to investigate and
                rectify any confirmed errors as soon as reasonably practicable
                following receipt of your notification.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>14.</p>
              <p className="pp_ol_li">
                <strong>LIMITATION OF LIABILITY</strong>{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>14.1</p>
              <p className="pp_ol_li">
                To the maximum extent permitted by Applicable Law, we will not
                be liable to you for any Losses, (including loss of information,
                data, revenues, profits or savings) resulting, directly or
                indirectly, out of, or in any way connected with your access to,
                use of, or reliance on the Platform or Services including any
                indirect, special, consequential, punitive, incidental or other
                Losses, expenses or damages arising out of or in connection with
                the use of the Platform whether in negligence, contract, tort,
                strict liability, or any other legal theory. You assume full
                responsibility for the use of the Services and/or Platform.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>14.2</p>
              <p className="pp_ol_li">
                We may at our sole discretion and without assigning any reason
                whatsoever at any time, deactivate or/and suspend your Snapd
                Account and/or access to the Platform without giving any prior
                notice, to carry out system maintenance, upgrading, testing,
                repairs and/or other related work. We will attempt to inform you
                of the same. Without prejudice to any other provisions of these
                Terms of Use and/or the Privacy Policy, we shall not be liable
                to indemnify you for any Loss, damages, costs and/or expenses
                that you may suffer or incur, and no fees and/or charges payable
                by you to the Platform for any purchase shall be deducted or
                refunded or rebated, as a result of such deactivation and/or
                suspension.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>14.3</p>
              <p className="pp_ol_li">
                We may suspend performance of our Services or access to the
                Platform without liability where we have identified an actual or
                potential personal, financial or legal risk to the User (which
                may include the following circumstances: (i) if you breach these
                Terms of Use or the Privacy Policy; (ii) if you fail to
                reasonably cooperate with an investigation by us; or (iii) where
                we reasonably believe that the continued provision of any of our
                Services would expose you or us or our respective affiliates or
                users to a material security risk or a regulatory action.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>14.4</p>
              <p className="pp_ol_li">
                You agree to release and hold harmless Spectacom and its
                affiliates from all liabilities and claims that arise in any way
                from any injury, death, Loss or harm that occurs to yourself or
                the any other person, due to your usage of the Services.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>14.5</p>
              <p className="pp_ol_li">
                You expressly agree that in the event of any statute, rule,
                regulation, or amendment coming into force that would result in
                Spectacom incurring any form of liability whatsoever, these
                Terms and any agreement thereof will stand terminated 1 (one)
                day before the coming into effect of such statute, rule,
                regulation or amendment.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>15.</p>
              <p className="pp_ol_li">
                <strong>GRIEVANCE OFFICER AND CUSTOMER SUPPORT</strong>{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>15.1</p>
              <p className="pp_ol_li">
                In the event you have any grievances or questions about the
                Platform or the Services or if you wish to make a complaint
                regarding any violation of the provisions of the Privacy Policy
                and the way your Personal Data is processed, you may contact our
                Grievance Officer at the details as set out below, pursuant to
                which your grievance shall be redressed within 15 (fifteen) days
                from the date of receipt of grievance/complaint. <br /> <br />
                Name: <br />
                Email ID: info@snapd.me{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>15.2</p>
              <p className="pp_ol_li">
                If you have any questions regarding the Services or the Platform
                or in relation to these Terms or the Privacy Policy, or if you
                want to exercise any of your rights under these Terms, you can
                contact us at info@snapd.me.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>16.</p>
              <p className="pp_ol_li">THIRD PARTY WEBSITES </p>
            </div>
            <div className="pp_ol_card">
              <p>16.1</p>
              <p className="pp_ol_li">
                The Services and/or Platform may enable you to the links of
                websites and to access the content and/or services of Third
                Parties, including users, advertisers, affiliates and sponsors
                of such Third Parties. We have no control over and are not
                responsible for any such Third Party websites or content or
                services and you agree to bear all risks associated with your
                access to and/or use of any such Third Party websites, content
                and services.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>16.2</p>
              <p className="pp_ol_li">
                You are aware that all such Third Party content is the
                responsibility of the respective authors/owners thereof, and
                Spectacom does not make any warranties or guarantees with
                respect to the same. You are further aware that Spectacom does
                not: (i) guarantee the accuracy, completeness, or usefulness of
                any Third Party Content provided through the Services and/or
                Platform; or (ii) adopt, endorse, or accept responsibility for
                the accuracy or reliability of any opinion or statement made by
                any party that appears in the Services and/or Platform. You
                acknowledge and agree that under no circumstances will Spectacom
                or its affiliates be responsible or liable for any Losses
                resulting from your reliance on information or other Content
                posted on the Platform or transmitted to or by any Users or
                Third Parties.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>17.</p>
              <p className="pp_ol_li">
                <strong>FORCE MAJEURE</strong> <br /> <br />
                Neither Party shall be liable for any failure or delay on its
                part in performing its obligations under these Terms, if such
                failure or delay is due, in whole or in part, to{" "}
                <strong>"Force Majeure"</strong>
                conditions. Force Majeure for these Terms shall mean incidents
                which could not have been reasonably predicted and which have
                resulted from circumstances beyond the control of the affected
                Party, and which are limited to, acts of God, labor disputes or
                other industrial disturbances, electrical or power outage,
                utilities or other telecommunications failures, earthquakes,
                storms or other elements of nature, blockages, embargoes, riots,
                acts or orders of government, epidemic, pandemic, acts of
                terrorism, or war. The Party whose performance is prevented by
                Force Majeure shall take all reasonable actions within its power
                to comply as fully as possible herewith and to preserve and
                protect the respective interests of the other Party hereto. It
                is clarified that failure to make payments of the fees within
                the prescribed period for any reason whatsoever or insufficiency
                of funds shall not constitute a Force Majeure event.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>18.</p>
              <p className="pp_ol_li">
                <strong>GOVERNING LAW AND JURISDICTION</strong>{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>18.1</p>
              <p className="pp_ol_li">
                These Terms of Use will be governed by the laws of India.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>18.2</p>
              <p className="pp_ol_li">
                The courts of New Delhi shall have exclusive jurisdiction over
                any disputes between the Parties arising out of or in relation
                to these Terms of Use.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>19.</p>
              <p className="pp_ol_li">DISPUTE RESOLUTION </p>
            </div>
            <div className="pp_ol_card">
              <p>19.1</p>
              <p className="pp_ol_li">
                Any complaint or dispute can be raised in writing to us at
                info@snapd.me.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>19.2</p>
              <p className="pp_ol_li">
                If any disputes or claims arising under these Terms or the
                Privacy Policy or out of or in connection with the execution,
                interpretation, performance, or non-performance of these Terms
                or the Privacy Policy or in respect of the scope, validity or
                application of the Terms or the Privacy Policy, or the subject
                matter hereof (<strong>"Dispute"</strong>), representatives of
                the Parties shall cooperate, in good faith, to attempt to
                amicably resolve the Dispute.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>19.3</p>
              <p className="pp_ol_li">
                All disputes that cannot be resolved by the Parties by
                discussion shall be settled by arbitration in accordance with
                the Arbitration and Conciliation Act, 1996. The arbitral
                tribunal shall consist of 3 (three) arbitrators. Each Party
                shall appoint 1 (one) arbitrator each and the 2 (two)
                arbitrators so appointed shall appoint the 3rd (third)
                arbitrator. The venue of arbitration shall be [.]. The language
                of the arbitration shall be English. The decision of the
                arbitrators shall be final, binding and incontestable and may be
                used as a basis for judgment thereon anywhere. Judgment upon any
                arbitral award rendered hereunder may be entered in any court
                having jurisdiction, or application may be made to such court
                for a judicial acceptance of the award and an order of
                enforcement, as the case may be.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>19.4</p>
              <p className="pp_ol_li">
                Each Party to the arbitration shall cooperate with each other
                Party to the arbitration in making full disclosure of and
                providing complete access to all information and documents
                requested by such other Party in connection with such
                arbitration proceedings, subject only to any confidentiality
                obligations binding on such Party or any legal privilege
                applicable to any such information and/or documents. Each Party
                shall bear its own costs of the arbitration.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.</p>
              <p className="pp_ol_li">
                <strong>MISCELLANEOUS</strong>{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.1</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>
                  Amendments.
                </strong>{" "}
                <br /> <br />
                Spectacom may, from time to time, update these Terms. Such
                updates or alterations to the Terms and/or service policies will
                be stipulated in the 'Last Modified' date as is listed on the
                User Agreement and Terms webpages. A User, through the continued
                use Services, automatically confirms such Users' acceptance of
                Spectacom's latest and/or updated Terms which may supersede any
                and all prior terms as may have once been displayed. If any User
                does not agree with the Terms or wishes to not be bound thereby,
                then they must immediately stop using the Platform and/or
                accessing our Services.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.2</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>Waiver.</strong>{" "}
                <br /> <br />
                The failure of a Party at any time to insist on performance of
                any provision of the Terms is not a waiver of that Party's right
                at a later time to insist on performance of that or any other
                governing provision of the Terms.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.3</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>
                  Severability.
                </strong>{" "}
                <br /> <br />
                If any term or provision of the Terms is held by a court of
                competent jurisdiction to be invalid, void or unenforceable, the
                remainder of the terms and provisions of the Terms shall remain
                in full force and effect and shall in no way be affected,
                impaired or invalidated.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.4</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>
                  Notices.
                </strong>{" "}
                <br /> <br />
                Any notice given under the Terms by either Party to the other
                must be in writing, by email, or by electronic communication via
                the Platform and will be deemed to have been given on
                transmission, unless the recipient can satisfactorily establish
                that the email or electronic communication was not received by
                the recipient’s email or web server. Notices to Spectacom must
                be sent by email to info@snapd.me or to any other email address
                notified by email to the Users by Spectacom, or by electronic
                communication via the Platform from time to time for such
                purpose. Notices to the Users shall be sent to the email address
                provided when registering for setting up their Snapd Account or
                by such other electronic communication via the Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.5</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>
                  Assignment.
                </strong>{" "}
                <br /> <br />
                We may assign any of our responsibilities/obligations to any
                other person without notice to the User, at our sole discretion.
                However, you shall not assign, sub-license or otherwise transfer
                any of your rights or obligations under these Terms to any other
                party, unless we provide our written consent. Any assignment
                without such consent shall be void and have no effect. Subject
                to the foregoing, the Terms will be binding upon, and inure to
                the benefit of, the Parties and their respective permitted
                successors and assigns.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.6</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>
                  Rights of Third Parties.{" "}
                </strong>
                <br /> <br />
                No Third Party shall have any right or benefit under the Terms
                or entitlement to enforce any provision of the Terms.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.7</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>
                  Relationship of Parties.{" "}
                </strong>
                <br /> <br />
                We and you are independent contractors, and you acknowledge and
                agree that nothing in the Terms shall cause or constitute the
                Parties to be partners, agents or fiduciaries of each other. You
                will have no authority to make or accept any offers or
                representations on our behalf. The Terms will not create an
                exclusive relationship between you and Spectacom.
              </p>
            </div>
            <div className="pp_ol_card">
              <p className="pp_ol_li">
                <strong> Last Update: May 22, 2024</strong>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TermsConditions;
