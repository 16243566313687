import React, { useState, useRef, useEffect } from "react";
import "../Upload/index.css";
import axios from "axios";
import { getToken, dEV_API_URL } from "../../API";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "@mui/material";

const TOKEN = await getToken();

const Main = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageStatus, setImageStatus] = useState({});
  const [batchIndex, setBatchIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [radioValue, setRadioValue] = useState("keep_both");
  const [skippedImages, setSkippedImages] = useState([]);

  const [duplicateImgs, setDuplicateImgs] = useState([]);
  const [checkedImages, setCheckedImages] = useState({});

  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  let queryParams = new URLSearchParams(window.location.search);
  let albumId = queryParams.get("albumId");

  useEffect(() => {
    console.log(checkedImages);
  }, [checkedImages]);

  const handleImageChange = async (e) => {
    const filteredFiles = Array.from(e.target.files);
    setSelectedImages(filteredFiles);
    const initialStatus = filteredFiles.reduce((acc, file) => {
      acc[file.name] = "pending";
      return acc;
    }, {});
    setImageStatus(initialStatus);
  };

  function updateImageStatus(imageName, statusObj) {
    if (statusObj[imageName]) {
      statusObj[imageName] = "duplicate";
      setImageStatus(statusObj);
    }
  }

  // Call the function to update imageStatus

  const uploadImage = async (image, sendResolutionKey) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("album_id", albumId);
    formData.append("image", image);
    console.log("inside upload");
    const binaryString = await readFileAsBinaryString(image);
    const wordArray = CryptoJS.enc.Latin1.parse(binaryString);
    const md5Hash = CryptoJS.MD5(wordArray).toString();

    formData.append("md5_hash", md5Hash);
    if (sendResolutionKey) {
      formData.append("conflict_resolution_strategy", radioValue);
    }
    // Include MD5 hash in request headers
    const config = {
      headers: {
        "content-type": "application/octet-stream",
        Authorization: TOKEN,
        "X-Image-MD5": md5Hash,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };

    try {
      const response = await axios.post(
        `${dEV_API_URL}/events/images/`,
        formData,
        config
      );
      const imageSize = await fetchImageSize(response.data.image_link);

      if (response.data && response.data.message === "Image Uploaded!") {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [image.name]: "uploaded",
        }));
      }
      if (
        response.data &&
        (response.data.message ===
          "Image skipped. name_and_hash_matching_image_already_exist" ||
          response.data.message === "name_matching_image_already_exist")
      ) {
        setDuplicateImgs((prev) => [
          ...prev,
          {
            id: image.name,
            existImg: response.data.image_link,
            newImg: image,
            Size: imageSize,
          },
        ]);
        setSkippedImages((prevImages) => [...prevImages, image]);
        updateImageStatus(image.name, imageStatus);
      } else if (response.data && response.data.message === "Image skipped.") {
        setSelectedImages((prevImages) =>
          prevImages.filter((img) => img.name !== image.name)
        );
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [image.name]: "skipped",
        }));
      }
    } catch (error) {
      console.log(error.response.data.error);
      if (
        error.response.data.error == "Image size is above the limit of 15MB"
      ) {
        console.log("inside if error");
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [image.name]: "failed size",
        }));
      } else {
        console.log("inside else error");
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [image.name]: "failed",
        }));
      }
    } finally {
      setUploading(false);
    }
  };

  const readFileAsBinaryString = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  };

  useEffect(() => {
    if (batchIndex !== 0 && batchIndex % 5 === 0) {
      uploadImages();
    }
  }, [batchIndex]);

  const uploadImages = () => {
    const batchImages = selectedImages.slice(batchIndex, batchIndex + 5);
    setShowUploadButton(true);
    batchImages.forEach((image, index) => {
      setImageStatus((prevStatus) => ({
        ...prevStatus,
        [image.name]: "uploading",
      }));
      uploadImage(image, false).then(() => {
        if (index === batchImages.length - 1) {
          if (batchIndex + 5 < selectedImages.length) {
            setBatchIndex(batchIndex + 5);
          } else {
            setBatchIndex(0);
            const hasDuplicate = Object.values(imageStatus).some(
              (status) => status === "duplicate"
            );
            if (hasDuplicate) {
              setShowModal(true);
            }
          }
        }
      });
    });
  };

  const handleCancel = () => {
    setSelectedImages([]);
  };

  const retryUpload = () => {
    const failedImages = selectedImages.filter(
      (image) => imageStatus[image.name] === "failed"
    );
    const numBatches = Math.ceil(failedImages.length / 5);
    let index = 0;

    const retryNextBatch = () => {
      const batchImages = failedImages.slice(index, index + 5);
      batchImages.forEach((image, batchIndex) => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [image.name]: "uploading",
        }));
        uploadImage(image, false).then(() => {
          if (index >= failedImages.length - 1) {
            return;
          }
          const currentIndex = index;
          index++;
          if ((currentIndex + 1) % 5 === 0 || index === failedImages.length) {
            const hasDuplicate = batchImages.some(
              (image) => imageStatus[image.name] === "duplicate"
            );
            if (hasDuplicate) {
              setShowModal(true);
            }
            retryNextBatch();
          }
        });
      });
    };

    retryNextBatch();
  };

  const uploadDuplicateImages = () => {
    const duplicateImages = selectedImages.filter(
      (image) => imageStatus[image.name] === "duplicate"
    );
    console.log(duplicateImages.length, "________");
    const numBatches = Math.ceil(duplicateImages.length / 5);
    let index = 0;

    const uploadDuplicateNextBatch = () => {
      const batchImages = duplicateImages.slice(index, index + 4);
      console.log(batchImages, "inside_batch");
      batchImages.forEach((image) => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [image.name]: "uploading",
        }));
        uploadImage(image, true).then(() => {
          index++;
          if (index >= duplicateImages.length) {
            return;
          }
          if (index % 5 === 0 || index === duplicateImages.length - 1) {
            uploadDuplicateNextBatch();
          }
        });
      });
    };
    uploadDuplicateNextBatch();
  };

  const uploadedCountPercentage = Math.min(
    Math.round(
      (Object.values(imageStatus).filter((status) => status === "uploaded")
        .length /
        selectedImages.length) *
        100
    ),
    100
  );

  const showRetryButton =
    !uploading &&
    Object.values(imageStatus).some((status) => status === "failed");

  // Effect to handle "keep both" logic
  useEffect(() => {
    setCheckedImages((prev) => {
      const newCheckedImages = { ...prev };
      duplicateImgs.forEach((item) => {
        if (radioValue === "keep_both") {
          newCheckedImages[item.existImg] =
            prev[item.existImg] !== undefined ? prev[item.existImg] : true;
          newCheckedImages[item.newImg.name] =
            prev[item.newImg.name] !== undefined
              ? prev[item.newImg.name]
              : true;
        } else if (radioValue === "skip_new") {
          newCheckedImages[item.existImg] =
            prev[item.existImg] !== undefined ? prev[item.existImg] : true;
          newCheckedImages[item.newImg.name] =
            prev[item.newImg.name] !== undefined
              ? prev[item.newImg.name]
              : false;
        } else if (radioValue === "overwrite_old") {
          newCheckedImages[item.existImg] =
            prev[item.existImg] !== undefined ? prev[item.existImg] : false;
          newCheckedImages[item.newImg.name] =
            prev[item.newImg.name] !== undefined
              ? prev[item.newImg.name]
              : true;
        }
      });
      return newCheckedImages;
    });
  }, [radioValue, duplicateImgs]);

  // Handle accept modal
  const handleAcceptModal = async () => {
    setShowModal(false);

    // Filter duplicate images based on selection
    const selectedNewImagesForUpload = duplicateImgs.filter(
      (item) => checkedImages[item.newImg.name]
    );

    for (const item of selectedNewImagesForUpload) {
      await uploadImage(item.newImg, radioValue);
    }

    setSelectedImages([]);
    setDuplicateImgs([]);
    setRadioValue("keep_both");
  };

  useEffect(() => {
    if (selectedImages.length === 0) {
      setShowUploadButton(false);
    }
  }, [selectedImages, showUploadButton]);

  useEffect(() => {
    if (uploadedCountPercentage === 100) {
      setShowUploadButton(false);
      toast.success("Images Uploaded", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      navigate(-1);
    }
  }, [uploadedCountPercentage, navigate]);

  function calculateImgSize(file) {
    const fileSizeInBytes = file.size;

    const fileSizeInKB = fileSizeInBytes / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;

    if (fileSizeInMB < 1) {
      // If the file size is less than 1MB, return the size in KB
      return `${fileSizeInKB.toFixed(2)} KB`;
    } else {
      // If the file size is 1MB or more, return the size in MB
      return `${fileSizeInMB.toFixed(2)} MB`;
    }
  }

  const fetchImageSize = async (imageUrl) => {
    try {
      const response = await axios.head(imageUrl);
      const contentLength = response.headers["content-length"];
      if (contentLength) {
        const fileSizeInBytes = parseInt(contentLength, 10);
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;

        if (fileSizeInMB < 1) {
          return `${fileSizeInKB.toFixed(2)} KB`;
        } else {
          return `${fileSizeInMB.toFixed(2)} MB`;
        }
      } else {
        return "Size not available";
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error("403 Forbidden: Access denied to fetch image size.");
        return "Access Denied";
      } else {
        console.error("Error fetching image size:", error);
        return "Size not available";
      }
    }
  };

  return (
    <>
      <Header />
      <div className="upload_image_main_container">
        <SideBar />
        <div className="upload_image_main_div">
          <p className="heading_text">Albums/Upload Photos</p>
          {selectedImages.length === 0 && (
            <div className="drag_box">
              <div>
                <div className="drag_box_header">
                  <p>Upload Photos</p>
                </div>
                <div
                  className="drag_box_inside_text"
                  onClick={() => fileInputRef.current.click()}
                >
                  <input
                    type="file"
                    id="file-input"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    multiple
                    accept=".jpg, .jpeg, .png"
                  />
                  <div className="choose_file">Choose file to upload</div>
                  <p>or</p>
                  <p>Drag & drop images to upload</p>
                  <p>Supporting format: jpeg, jpg, png | Maximum size: 15MB</p>
                </div>
              </div>
            </div>
          )}

          {selectedImages.length !== 0 && (
            <div className="progressContainer">
              <div className="uploadAndCrossIcon">
                <div className="uploadedCount">
                  {
                    Object.values(imageStatus).filter(
                      (status) => status === "uploaded"
                    ).length
                  }{" "}
                  uploaded out of {selectedImages.length}
                </div>
                {/* <CloseIcon style={{ color: "#E5DAF2" }} />{" "} */}
              </div>
              <progress
                className="customProgress"
                value={uploadedCountPercentage / 100}
              />
              <div className="totalpercentage">{uploadedCountPercentage}%</div>
            </div>
          )}

          {selectedImages.length !== 0 && (
            <div
              className="imsgeListOuterContainer"
              style={{
                color: "white",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="photosHeadingAndSubheading">
                <div className="headingAndButton">
                  <div className="headingAndCount">
                    <div className="photoHeading">Photos</div>
                    <div className="photoSubHeading">
                      {selectedImages.length} photos
                    </div>
                  </div>
                  <div className="cancelAndUploadButton">
                    {!showUploadButton && (
                      <div className="cancelButton" onClick={handleCancel}>
                        Cancel
                      </div>
                    )}
                    {!showUploadButton && (
                      <div className="uploadButton" onClick={uploadImages}>
                        Upload
                      </div>
                    )}

                    {showRetryButton && (
                      <div className="retryButton" onClick={retryUpload}>
                        Retry all failed images
                      </div>
                    )}
                  </div>
                </div>

                <div className="imageNameList">
                  {selectedImages.map((image, index) => (
                    <div key={index}>
                      <div className="imagename_outer_div">{image.name}</div>
                      {imageStatus[image.name] === "pending" && (
                        <HourglassEmptyIcon color="white" />
                      )}
                      {imageStatus[image.name] === "uploading" && (
                        <HourglassEmptyIcon color="primary" />
                      )}
                      {imageStatus[image.name] === "uploaded" && (
                        <CheckCircleOutlineIcon color="success" />
                      )}
                      {imageStatus[image.name] === "failed" && (
                        <ErrorOutlineIcon color="error" />
                      )}
                      {imageStatus[image.name] === "failed size" && (
                        <div className="failedDiv">
                          <p className="errortext">Image exceeds 15MB</p>
                          <ErrorOutlineIcon
                            className="iconRight"
                            color="error"
                          />
                        </div>
                      )}
                      {imageStatus[image.name] === "duplicate" && (
                        // <HourglassEmptyIcon color="white" />
                        <p>duplicate</p>
                      )}
                      {imageStatus[image.name] === "skipped" && <p>skipped</p>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <Modal
          open={showModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal">
            <div className="modal-content">
              <h2>Duplicate Photos</h2>
              <p>
                It seems that{" "}
                {
                  skippedImages.filter(
                    (image) => imageStatus[image.name] === "duplicate"
                  ).length
                }{" "}
                images out of {selectedImages.length} you're trying to upload
                already exist in the
              </p>
              <p>system. Please select the image you want to keep.</p>
              <div className="radio-options">
                <label>
                  <input
                    type="radio"
                    value="keep_both"
                    checked={radioValue === "keep_both"}
                    onChange={(e) => setRadioValue(e.target.value)}
                  />
                  Keep Both{" "}
                </label>
                <label>
                  <input
                    type="radio"
                    value="skip_new"
                    checked={radioValue === "skip_new"}
                    onChange={(e) => setRadioValue(e.target.value)}
                  />
                  Existing image{" "}
                </label>
                <label>
                  <input
                    type="radio"
                    value="overwrite_old"
                    checked={radioValue === "overwrite_old"}
                    onChange={(e) => setRadioValue(e.target.value)}
                  />
                  New Image{" "}
                </label>

                {/* <label>
                  <input
                    type="radio"
                    value="custom"
                    checked={radioValue === "custom"}
                    onChange={(e) => setRadioValue(e.target.value)}
                  />
                  Custom{" "}
                </label> */}
              </div>
              <div className="duplicate_imgs_cards">
                <div className="duplicate_imgs_cards_header">
                  <p>Existing Image</p>
                  <p>New Image</p>
                </div>

                {duplicateImgs.map((item, index) => (
                  <div
                    className="duplicate_imgs_card"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <div className="duplicate_img_sub_card">
                      <input
                        type="checkbox"
                        checked={
                          (radioValue === "keep_both" && true) ||
                          (radioValue === "skip_new" && true) ||
                          (radioValue === "overwrite_old" && false)
                          // (radioValue === "custom" &&
                          //   checkedImages[item.existImg] !== false)
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setCheckedImages((prev) => ({
                            ...prev,
                            [item.existImg]: isChecked,
                          }));
                        }}
                      />
                      <div className="duplicate_sub_card_img">
                        <img src={item.existImg} alt="exist_img" />
                      </div>
                      <div className="duplicate_img_card_info">
                        <p>{item.existImg}</p>
                        <p>
                          <strong>Size:</strong> {item.Size}
                        </p>
                      </div>
                    </div>

                    <div className="duplicate_img_sub_card">
                      <input
                        type="checkbox"
                        checked={
                          (radioValue === "keep_both" && true) ||
                          (radioValue === "overwrite_old" && true) ||
                          (radioValue === "skip_new" && false) 
                          // (radioValue === "custom" &&
                          //   checkedImages[item.newImg.name] !== false)
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setCheckedImages((prev) => ({
                            ...prev,
                            [item.newImg.name]: isChecked,
                          }));
                        }}
                      />
                      <div className="duplicate_sub_card_img">
                        <img
                          src={URL.createObjectURL(item.newImg)}
                          alt="new_img"
                        />
                      </div>
                      <div className="duplicate_img_card_info">
                        <p>{item.newImg.name}</p>
                        <p>
                          <strong>Size:</strong> {item.Size}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="acceptBtn_div">
                <button className="acceptBtn" onClick={handleAcceptModal}>
                  Accept
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Main;

