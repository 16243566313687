import React from "react";
import "../../components/aboutus/aboutus.css";
import Header from "../header/Header";
import Footer from "../../components/footer/Footer";

const AboutUs = () => {
  return (
    <>
      <div className="aboutus_wrapper">
        <div className="aboutus_inner">
          <div className="aboutus_main_div">
            <div>
              <div className="about_header_div">
                <Header />
              </div>
              <div className="aboutus_content_div">
                <p className="aboutus_main_heading">About us</p>

                <p className="aboutus_content_para">
                  WELCOME TO SNAPD <br /> At Snapd,
                    we're dedicated to revolutionizing the way
                  people capture and share moments. Our mission is to empower
                  individuals to effortlessly create and share stunning visual
                  content, all with the help of cutting-edge Artificial
                  Intelligence.
                  <br /> <br />
                  Snapd leverages advanced AI technology to streamline the photo
                  and video creation process, from capture to sharing. Our
                  innovative platform utilizes machine vision and deep learning
                  algorithms to enhance images, automate editing tasks, and
                  optimize content for maximum impact.
                  <br /> <br />
                  Founded in 2024, Snapd has been on a mission to redefine
                  visual storytelling.
                  <br /> <br />
                  With Snapd, users can expect to save time, elevate their
                  content, and unlock new levels of creativity. Whether you're a
                  professional photographer, social media influencer, or casual
                  enthusiast, Snapd is your trusted partner in capturing and
                  sharing moments that matter.
                  <br /> <br />
                  Join us on our journey to empower creators worldwide and
                  transform the way we experience visual content. Welcome to
                  Snapd - where every moment is a masterpiece waiting to be
                  shared.
                </p>
              </div>
            </div>
            <div className="aboutus_footer_div">
              <Footer />
            </div>
          </div>
          <div className="aboutus_overlay"></div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
