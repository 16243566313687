import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { store } from "./app/store";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App/>
      <ToastContainer />
    </Provider>
);

