import React, { useState, useEffect } from "react";
import "../../components/homepage/moment2.css";
import Sport from "../../assets/homepage/sports.png";
import Party from "../../assets/homepage/party.png";

import { useInView } from "react-intersection-observer";

const Moment2 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: .5,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: .5,
  });

  const [screensize, setScreensize] = useState(window.screen.width);

  const actualSize = () => {
    setScreensize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", actualSize);
  });

  let infoMarginTop;
  let imgMarginRight;
  if (screensize >= 600) {
    infoMarginTop = "-100px";
    imgMarginRight = "-10px";
  } else {
    infoMarginTop = "-10px";
    imgMarginRight = "0px";
  }

  return (
    <>
      <div className="moment2_main">
        <div className="moment2_main_bg_div"></div>

        <div className="moment2_main_wrapper">
          <div className="moment2_header">
            <p className="moment2_main_heading">Captivate Every Moment</p>
            <p className="moment2_sub_heading">
              Immerse yourself in a world where every interaction sparks. With
              captivating design, our platform transforms moments into
              unforgettable experineces.
            </p>
          </div>

            <div className="moment2_upper_div" ref={ref}>
              <div
                className="moment2_upper_info_div"
                style={{
                  filter: inView ? "brightness(100%)" : "brightness(10%)",
                  transform: inView
                    ? `translateY(${infoMarginTop})`
                    : "translateY(0)",
                }}
              >
                <p className="moment2_upper_info_heading">Sports Event</p>
                <p className="moment2_upper_info_para">
                  Basketball, Football, Marathons, Ice Hocky, Gymnastics
                  Competitions, Tennis, Surfing etc.
                </p>
              </div>
              <div
                className="moment2_upper_img_div"
                style={{
                  filter: inView ? "brightness(100%)" : "brightness(10%)",
                  transform: inView
                    ? `rotate(0deg) translateX(${imgMarginRight})`
                    : "rotate(25deg) translate(50%)",
                }}
              >
                <img src={Sport} alt="img" />
              </div>
            </div>
          

          <div className="moment2_bottom_div" ref={ref2}>
            <div
              className="moment2_bottom_img_div"
              style={{
                filter: inView2 ? "brightness(100%)" : "brightness(10%)",
                transform: inView2
                  ? "rotate(0deg) translateX(0px)"
                  : "rotate(-25deg) translate(-50%)",
              }}
            >
              <img src={Party} alt="img" />
            </div>

            <div className="moment2_bottom_div">
              <div
                className="moment2_bottom_info_div"
                style={{
                  filter: inView2 ? "brightness(100%)" : "brightness(10%)",
                  transform: inView2
                    ? `translateY(${infoMarginTop})`
                    : "translateY(0)",
                }}
              >
                <p className="moment2_bottom_info_heading">Party</p>
                <p className="moment2_bottom_info_para">
                  Birthday, Wedding Receptions, Anniversary Celebrations, Baby
                  Showers, Retirement, Themed Costume Parties, Corporate Events
                  and Parties.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Moment2;
