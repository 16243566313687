import React, { useEffect, useState } from "react";
import "../brandWatermark/brandWatermark.css";

import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Logo from "../../../assets/eventSetting/brandLogo.svg";

import HorizontalDummyImg from "../../../assets/eventSetting/hor_preview.png";
import VerticalDummyImg from "../../../assets/eventSetting/ver_preview.png";
import { useParams } from "react-router-dom";
import { getToken, dEV_API_URL } from "../../../API";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import SyncLoader from "react-spinners/SyncLoader";

const TOKEN = await getToken();

const BrandWatermark = () => {
  const navigate = useNavigate();

  const [postStatus, setPoststatus] = useState(false);

  const [watermarkHorizontalImg, setWatermarkHorizontalImg] = useState(null);
  const [watermarkVerticalImg, setWatermarkVerticalImg] = useState(null);

  const [watermarkHorizontalImgErr, setWatermarkHorizontalImgErr] =
    useState("");
  const [watermarkVerticalImgErr, setWatermarkVerticalImgErr] = useState("");

  const [isHorizontalImgChange, setIsHorizontalImgChange] = useState(false);
  const [isVerticalImgChange, setIsVerticalImgChange] = useState(false);

  const [flag, setFlag] = useState(false);

  const [respData, setRespData] = useState([]);
  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");

  let validationSchema = Yup.object({
    brand_name: Yup.string()
      .min(3, "Brand name must be atleast 3 characters")
      .max(150, "Brand name must be at most 150 characters")
      .required("Brand name is required"),
    subtitle: Yup.string()
      .min(3, "Subtitle must be atleast 3 characters")
      .max(150, "Subtitle must be at most 150 characters")
      .required("Subtitle is required"),
    cta: Yup.string()
      .min(3, "CTA must be atleast 3 characters")
      .max(150, "CTA must be at most 150 characters")
      .required("CTA is required"),
  });

  useEffect(() => {
    getWatermarkDetail(id);
  }, [id]);

  const getWatermarkDetail = (id) => {
    axios({
      method: "GET",
      url: `${dEV_API_URL}/branding/brand-watermark/?event_id=${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
    })
      .then((resp) => {
        console.log(typeof resp.data.horizontal_watermark);
        setRespData(resp.data);
        setWatermarkHorizontalImg(resp.data.horizontal_watermark);
        setWatermarkVerticalImg(resp.data.vertical_watermark);
        setFlag(true);
      })
      .catch((err) => {
        console.log(err);
        setFlag(true);
      })
      .finally(() => {
        setFlag(true);
      });
  };

  const updateWatermarkDetail = (values) => {
    setPoststatus(true);
    var MethodType;

    var Id;
    if (flag && respData.id) {
      Id = respData.id;
      MethodType = "PATCH";
    } else {
      MethodType = "POST";
    }

    const formData = new FormData();
    if (respData.id) {
      formData.append("watermark_id", Id);
    } else {
      formData.append("event_id", id);
    }
    if (isHorizontalImgChange) {
      formData.append("horizontal_watermark", watermarkHorizontalImg);
    }
    if (isVerticalImgChange) {
      formData.append("vertical_watermark", watermarkVerticalImg);
    }

    axios({
      method: MethodType,
      url: `${dEV_API_URL}/branding/brand-watermark/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization: TOKEN,
      },
      data: formData,
    })
      .then((res) => {
        toast.success("Brand Watermark Detail Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((err) => {
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      setWatermarkHorizontalImgErr(
        "Invalid file type. Please select a PNG, JPG, or JPEG image."
      );
      return false;
    }
    if (file.size > maxSize) {
      toast.warning(
        "File size exceeds the 2MB limit. Please select a smaller file.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      return false;
    }
    return true;
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setWatermarkHorizontalImg(file);
          setWatermarkHorizontalImgErr("");
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const isFileValid2 = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      setWatermarkVerticalImgErr(
        "Invalid file type. Please select a PNG, JPG, or JPEG image."
      );
      return false;
    }
    if (file.size > maxSize) {
      toast.warning(
        "File size exceeds the 2MB limit. Please select a smaller file.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      return false;
    }
    return true;
  };

  const handleImageUpload2 = (file) => {
    if (file && isFileValid2(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setWatermarkVerticalImg(file);
          setWatermarkVerticalImgErr("");
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver2 = (event) => {
    event.preventDefault();
  };

  const handleDrop2 = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload2(file);
  };

  const showToastMsg = () => {
    // let msg;
    if (!watermarkHorizontalImg && !watermarkVerticalImg) {
      setWatermarkHorizontalImgErr("Required");
      setWatermarkVerticalImgErr("Required");
    } else if (!watermarkHorizontalImg) {
      setWatermarkHorizontalImgErr("Required");
    } else if (!watermarkVerticalImg) {
      setWatermarkVerticalImgErr("Required");
    }
    //  else {
    //   msg = "Something went wrong";
    // }
    // toast.warning(msg, {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  };

  const override = {
    width: "100%",
    display: "flex",
    gap: "6px",
    alignItem: "center",
    justifyContent: "center",
    margin: "200px 0 0 0",
  };

  return (
    <>
      <div className="watermark_main_container">
        <div className="watermark_header_div">
          <p>Watermark on photos</p>
          <p>Please upload branding if you want to add branding on photos</p>
        </div>

        {flag ? (
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({
              values,
              isValid,
              setFieldValue,
              errors,
              touched,
              resetForm,
            }) => (
              <Form>
                <div className="watermark_photos_div">
                  <div className="watermark_photos_main_div">
                    <div className="watermark_photos_horizontal_div">
                      <>
                        <div
                          className="watermark_logo_upload_box"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <p className="watermark_photos_custom_file_upload_label">
                            On Horizontal Photos *
                          </p>

                          <>
                            <label
                              htmlFor="watermarkHorizontalImg"
                              className="watermark_photos_custom_file_upload"
                            >
                              <Field
                                type="file"
                                id="watermarkHorizontalImg"
                                name="watermarkHorizontalImg"
                                className="watermark_photos_upload_input"
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: "none" }}
                                value={values.file}
                                onChange={(e) => {
                                  if (
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/jpg"
                                  ) {
                                    const file = e.target.files[0];
                                    handleImageUpload(file);
                                    setIsHorizontalImgChange(true);
                                  }
                                }}
                              />
                              <p className="watermark_photos_upload_box_p1">
                                <span>Choose</span> file to upload
                              </p>
                              <p className="watermark_photos_upload_box_p2">
                                or
                              </p>
                              <p className="watermark_photos_upload_box_p3">
                                Drag & drop image to upload
                              </p>
                              <p className="watermark_photos_upload_box_p4">
                                Recommended: 1084x768px | jpeg, jpg, png | max.
                                size: 2MB
                              </p>
                            </label>
                            {
                              <p className="watermark_photos_error">
                                {watermarkHorizontalImgErr}
                              </p>
                            }
                          </>
                        </div>
                      </>
                      <div>
                        <p className="watermark_photos_preview_heading">
                          Preview
                        </p>

                        <div className="watermark_photos_horizontal_preview_div">
                          <div className="watermark_photos_horizontal_preview_dummy_img">
                            {!watermarkHorizontalImg ? (
                              ""
                            ) : typeof watermarkHorizontalImg === "string" ? (
                              <img
                                className="watermark_photos_horizontal_preview_upload_img"
                                src={watermarkHorizontalImg}
                                alt="logo"
                              />
                            ) : (
                              <img
                                className="watermark_photos_horizontal_preview_upload_img"
                                src={URL.createObjectURL(
                                  watermarkHorizontalImg
                                )}
                                alt="logo"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {/* {watermarkHorizontalImg && (
                      <div className="watermark_photos_horizontal_remove_vertical_div">
                        <p onClick={() => setWatermarkHorizontalImg(null)}>
                          Remove horizontal watermark
                        </p>
                      </div>
                    )} */}
                    </div>
                    <div className="watermark_photos_vertical_div">
                      <>
                        <div
                          className="watermark_logo_upload_box"
                          onDragOver={handleDragOver2}
                          onDrop={handleDrop2}
                        >
                          <p className="watermark_photos_custom_file_upload_label">
                            On Vertical Photos *
                          </p>
                          <>
                            <label
                              htmlFor="watermarkVerticalImg"
                              className="watermark_photos_custom_file_upload"
                            >
                              <Field
                                type="file"
                                id="watermarkVerticalImg"
                                name="watermarkVerticalImg"
                                className="watermark_photos_upload_input"
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: "none" }}
                                value={values.file}
                                onChange={(e) => {
                                  if (
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/jpg"
                                  ) {
                                    const file = e.target.files[0];
                                    handleImageUpload2(file);
                                    setIsVerticalImgChange(true);
                                  }
                                }}
                              />
                              <p className="watermark_photos_upload_box_p1">
                                <span>Choose</span> file to upload
                              </p>
                              <p className="watermark_photos_upload_box_p2">
                                or
                              </p>
                              <p className="watermark_photos_upload_box_p3">
                                Drag & drop image to upload
                              </p>
                              <p className="watermark_photos_upload_box_p4">
                                Recommended: 1084x768px | jpeg, jpg, png | max.
                                size: 2MB
                              </p>
                            </label>

                            {
                              <p className="watermark_photos_error">
                                {watermarkVerticalImgErr}
                              </p>
                            }
                          </>
                        </div>
                      </>
                      <div className="watermark_photos_vertical_preview_div_container">
                        <div className="watermark_photos_vertical_preview_div">
                          <div className="watermark_photos_vertical_preview_dummy_img">
                            {!watermarkVerticalImg ? (
                              ""
                            ) : typeof watermarkVerticalImg === "string" ? (
                              <img
                                className="watermark_photos_vertical_preview_upload_img"
                                src={watermarkVerticalImg}
                                alt="logo"
                              />
                            ) : (
                              <img
                                className="watermark_photos_vertical_preview_upload_img"
                                src={URL.createObjectURL(watermarkVerticalImg)}
                                alt="logo"
                              />
                            )}
                          </div>

                          {/* <img
                        className="watermark_photos_vertical_preview_dummy_img"
                        src={VerticalDummyImg}
                        alt="vertical_img"
                      />
                      {isVerticalImgChange ? 
                        <img
                          className="watermark_photos_vertical_preview_upload_img"
                          src={URL.createObjectURL(watermarkVerticalImg)}
                          alt="vertical_img"
                        /> :
                        <img
                          className="watermark_photos_vertical_preview_upload_img"
                          src={watermarkVerticalImg}
                          alt="vertical_img"
                        />
                      } */}
                        </div>
                      </div>

                      {/* {watermarkVerticalImg && (
                      <div className="watermark_photos_vertical_remove_vertical_div">
                        <p onClick={() => setWatermarkVerticalImg(null)}>
                          Remove Vertical watermark
                        </p>
                      </div>
                    )} */}
                    </div>
                  </div>
                </div>

                <div className="watermark_form_btns">
                  {postStatus ? (
                    <p className="watermark_form_submit_btn">
                      <span>
                        <ClipLoader
                          color="white"
                          size={24}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </span>
                    </p>
                  ) : (
                    <>
                      <p className="watermark_form_cancel_btn">
                        <span
                          onClick={() => {
                            resetForm();
                            navigate(-1);
                          }}
                        >
                          Cancel
                        </span>
                      </p>
                      {!watermarkHorizontalImgErr &&
                      !watermarkVerticalImgErr &&
                      watermarkHorizontalImg &&
                      watermarkVerticalImg ? (
                        <button
                          onClick={() => updateWatermarkDetail()}
                          className="watermark_form_submit_btn"
                        >
                          <span>Save</span>
                        </button>
                      ) : (
                        <p
                          onClick={() => {
                            showToastMsg();
                          }}
                          className="watermark_form_submit_btn"
                        >
                          <span>Save</span>
                        </p>
                      )}
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="sync_loader">
            <SyncLoader
              color="#E5DAF2"
              loading="...loading"
              size={6}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BrandWatermark;
