// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swrgParent {
  height: 100vh;
  width: 100vw;
  background-color: #0c0715;
  justify-content: center;
  display: flex;
}

.swrgMaintContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.swrgMaintContainer > img {
  height: className= "main";
  width: 245px;
  height: 270px;
  align-self: center;
}
.swrgtitle {
  color: #e5daf2;
  font-size: 22px;
  font-family: Jost;
  font-weight: 600;
  word-wrap: "break-word";
}

.swrgSubtitle {
  color: #e5daf2;
  font-size: 18px;
  font-family: Jost;
  font-weight: 400;
  word-wrap: "break-word";
  text-align: center;
}

/* Responsive styles for screens below 400px (mobile devices) */
@media screen and (max-width: 400px) {
  .swrgMaintContainer > img {
    width: 80%;
    height: auto;
  }

  .swrgtitle {
    font-size: 18px;
  }
  .swrgSubtitle {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/SomethingWentWrong/404Page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA,+DAA+D;AAC/D;EACE;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".swrgParent {\n  height: 100vh;\n  width: 100vw;\n  background-color: #0c0715;\n  justify-content: center;\n  display: flex;\n}\n\n.swrgMaintContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.swrgMaintContainer > img {\n  height: className= \"main\";\n  width: 245px;\n  height: 270px;\n  align-self: center;\n}\n.swrgtitle {\n  color: #e5daf2;\n  font-size: 22px;\n  font-family: Jost;\n  font-weight: 600;\n  word-wrap: \"break-word\";\n}\n\n.swrgSubtitle {\n  color: #e5daf2;\n  font-size: 18px;\n  font-family: Jost;\n  font-weight: 400;\n  word-wrap: \"break-word\";\n  text-align: center;\n}\n\n/* Responsive styles for screens below 400px (mobile devices) */\n@media screen and (max-width: 400px) {\n  .swrgMaintContainer > img {\n    width: 80%;\n    height: auto;\n  }\n\n  .swrgtitle {\n    font-size: 18px;\n  }\n  .swrgSubtitle {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
